@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

:root {
    --main-bg-color: #0a192f;
    --dark-bg-color: #05101f;
    --main-title-color: #ccd6f6;
    --error-title-color: #f6cccc;
    --main-desc-color: #8892b0;
    --main-white-color: #e6f1ff;
    --main-highlight-color: #64ffda;
    --dark-btn-bg: rgba(100, 255, 218, .1);

    --light-main-bg-color: #FFFFFF;
    --light-dark-bg-color: #f2f6ff;
    --light-main-title-color: #ccd6f6;
    --light-error-title-color: #f6cccc;
    --light-main-desc-color: #8892b0;
    --light-main-white-color: #e6f1ff;
    --light-main-highlight-color: #225aea;
    --light-btn-bg: rgba(100, 113, 255, 0.1);

    --font-family: 'Inter', sans-serif;
}

.dark {
    background: var(--main-bg-color);
}

.light {
    background: var(--light-main-bg-color);
}

@media only screen and (min-width: 770px) {
    .css-fn_ri4a {
        text-align: center !important;
    }
}

@media only screen and (max-width: 622px) {
    .text-desc {
        padding: 0 !important;
    }
}

@media only screen and (max-width: 990px) {
    .css-vfE_f3va {
        display: none !important;
    }
}

.css-nfe_ajf {
    background-color: var(--dark-bg-color);
}

.css-nfe_ajf .text-highlight {
    color: var(--main-highlight-color) !important;
}

.css-nfe_ajf h6 {
    color: var(--main-highlight-color) !important;
}

.css-nfe_ajf .footer-link-github {
    color: var(--main-highlight-color) !important;
}

.navbar {
    overflow: hidden !important;
}

.theme-light .bg-main {
    background: var(--light-main-bg-color);
}

.theme-light {
    background: var(--light-main-bg-color);
}

.theme-dark {
    background: var(--main-bg-color);
}

.theme-light .bg-dark {
    background-color: var(--light-dark-bg-color);
}

.theme-light .navbar-nav>li>a {
    color: black !important;
}

.theme-light .background-image {
    background-color: var(--light-main-bg-color);
}

.theme-light .text-title {
    color: #151e3a;
}



.btn-main-header {
    white-space: nowrap !important;
} 


.theme-dark .text-highlight {
    color: var(--main-highlight-color);
    font-weight: bold;
}

.theme-dark .text-highlight-detail {
    color: var(--main-highlight-color);
    font-weight: bold;
}

.theme-light .text-highlight {
    color: var(--light-main-highlight-color);
    font-weight: bold;
}

.theme-light .text-highlight-detail {
    color: var(--light-main-highlight-color);
    font-weight: bold;
}


.theme-dark .bg-main {
    background: var(--main-bg-color)
}

.theme-dark .bg-dark {
    background: var(--dark-bg-color) !important
}

.theme-light .site-footer h6 {
    color: var(--light-main-highlight-color);
}

.theme-dark .site-footer h6 {
    color: var(--main-highlight-color);
}

.site-footer h6 {
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 5px;
    letter-spacing: 2px
}

.theme-light .btn-main,
.btn-main-header {
    color: var(--light-main-highlight-color) !important;
}

.theme-dark .btn-main,
.btn-main-header {
    color: var(--main-highlight-color) !important;
}


.theme-light .btn-main {
    border: 1px solid var(--light-main-highlight-color);
}

.theme-dark .btn-main {
    border: 1px solid var(--main-highlight-color);
}

.theme-light .btn-main-header:hover,
.btn-main:hover {
    background: var(--light-btn-bg) !important;
}

.theme-dark .btn-main-header:hover,
.btn-main:hover {
    background: var(--dark-btn-bg)
}

.theme-dark .btn-main-header {
    border: 1px solid var(--main-highlight-color);
}

.theme-light .btn-main-header {
    border: 1px solid var(--light-main-highlight-color);
}

.centered-footer-hosted {
    border-top: 1px solid #272727;
}


.theme-light .footer-link-github {
    color: var(--light-main-highlight-color);
}

.theme-light .footer-link-github:hover {
    color: var(--light-main-highlight-color);
}

.theme-dark .footer-link-github {
    color: var(--main-highlight-color);
}

.theme-dark .footer-link-github:hover {
    color: var(--main-highlight-color);
}

.footer-link-github {
    text-decoration: none !important;
    font-weight: 300
}

.centered-footer-hosted {
    display: flex;
    padding-top: 20px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    height: 100%
}

.btn-main-header {
    border-radius: 6px;
    padding: .8rem 1.75rem;
    height: auto !important;
    /* margin-top: 15px;
    margin-left: 10px */
}

.btn-main-header:hover,
.btn-main:hover {
    transition: background .4s;
}

.btn-main {
    border-radius: 4px;
    padding: 1.25rem 1.75rem;
    margin-top: 50px
}

.btn-main,
.btn-main-header {
    background-color: transparent;
    font-size: 14px;
    font-family: var(--font-family);
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    transition: .25s cubic-bezier(.645, .045, .35)
}

pre,
textarea {
    overflow: auto
}

.text-left,
caption,
th {
    text-align: left
}

.btn,
.dropdown-header,
.dropdown-menu>li>a,
.input-group-btn,
.text-nowrap {
    white-space: nowrap
}

.navbar-fixed-bottom .navbar-collapse,
.navbar-fixed-top .navbar-collapse,
.pre-scrollable {
    max-height: 340px
}

.mfp-arrow,
.owl-carousel,
.owl-carousel .owl-item,
html {
    -webkit-tap-highlight-color: transparent
}

h4,
h5,
h6 {
    letter-spacing: 0
}

html {
    font-family: var(--font-family);
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%
}

.carousel-inner>.active,
.carousel-inner>.next,
.carousel-inner>.prev,
.collapse.in,
.media-object,
.navbar-brand>img,
.no-js .owl-carousel,
.open>.dropdown-menu,
.owl-carousel.owl-loaded,
.tab-content>.active,
.tab-content>.tab-pane,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
input[type=file],
main,
menu,
nav,
section,
summary {
    display: block
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline
}

audio:not([controls]) {
    display: none;
    height: 0
}

#twitter-feed .interact,
#twitter-feed .timePosted,
#twitter-feed .user,
#twitter-feed-list .user span,
#twitter-feed-list img,
#twitter-feed-slider .user span,
#twitter-feed-slider img,
.background-image img,
.badge:empty,
.collapse,
.hero-slider .owl-item img,
.label:empty,
.mfp-align-top .mfp-container:before,
.mfp-loading.mfp-figure,
.mfp-s-error .mfp-content,
.mfp-s-ready .mfp-preloader,
.nav li.dropdown a i,
.owl-carousel .owl-refresh .owl-item,
.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn,
.progress-bars.thick .progress-bar-tooltip:after,
.progress-circle-icon .progress-circle:after,
.testimonials.quote-icons h4::after,
.testimonials.quote-icons h4::before,
.twitter-feed-slider.owl-carousel .owl-item img,
[hidden],
template {
    display: none
}

.dropdown-menu>li>a:hover,
.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover,
.nav>li>a:focus,
.navbar-nav>.open>a,
.navbar-nav>.open>a:focus,
.navbar-nav>.open>a:hover,
a {
    background-color: transparent
}

.alert .alert-link,
b,
dt,
optgroup,
strong {
    font-weight: 700
}

dfn {
    font-style: italic
}

mark {
    color: #000;
    background: #ff0
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

.panel>.table-bordered,
.panel>.table-responsive>.table-bordered,
img {
    border: 0
}

hr {
    height: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}

*,
:after,
:before,
input[type=checkbox],
input[type=radio] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

code,
kbd,
pre,
samp {
    font-size: 1em
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font: inherit;
    color: inherit
}

button {
    overflow: visible
}

button,
select {
    text-transform: none
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer;
    appearance: button;
}

.hero-content-slider.owl-carousel .owl-item,
.hero-content-slider.owl-carousel .owl-item:active,
.quote-slider.owl-carousel .owl-item,
.quote-slider.owl-carousel .owl-item:active,
button[disabled],
html input[disabled] {
    cursor: default
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    height: auto
}

input[type=search] {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-appearance: textfield;
    appearance: textfield;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

textarea {
    height: 180px
}

.navbar-brand,
.p0,
.portfolio-wide [data-gap="0"],
.quote-slider .owl-controls,
.slider-fullwidth,
[data-gap="0"] .vossen-portfolio>div,
td,
th {
    padding: 0
}

@media print {

    blockquote,
    img,
    pre,
    tr {
        page-break-inside: avoid
    }

    *,
    :after,
    :before {
        color: #000 !important;
        text-shadow: none !important;
        background: 0 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important
    }

    a,
    a:visited {
        text-decoration: underline
    }

    a[href]:after {
        content: " (" attr(href) ")"
    }

    abbr[title]:after {
        content: " (" attr(title) ")"
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: ""
    }

    blockquote,
    pre {
        border: 1px solid #999
    }

    thead {
        display: table-header-group
    }

    img {
        max-width: 100% !important
    }

    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }

    h2,
    h3 {
        page-break-after: avoid
    }

    .navbar {
        display: none
    }

    .btn>.caret,
    .dropup>.btn>.caret {
        border-top-color: #000 !important
    }

    .label {
        border: 1px solid #000
    }

    .table {
        border-collapse: collapse !important
    }

    .table td,
    .table th {
        background-color: #fff !important
    }

    .table-bordered td,
    .table-bordered th {
        border: 1px solid #ddd !important
    }
}

.img-thumbnail,
body {
    line-height: 1.42857143;
    background-color: #fff
}

.dropdown-menu,
.modal-content {
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

.btn,
.btn-danger.active,
.btn-danger:active,
.btn-default.active,
.btn-default:active,
.btn-info.active,
.btn-info:active,
.btn-primary.active,
.btn-primary:active,
.btn-success.active,
.btn-success:active,
.btn-warning.active,
.btn-warning:active,
.btn.active,
.btn:active,
.form-control,
.navbar-toggle,
.open>.dropdown-toggle.btn-danger,
.open>.dropdown-toggle.btn-default,
.open>.dropdown-toggle.btn-info,
.open>.dropdown-toggle.btn-primary,
.open>.dropdown-toggle.btn-success,
.open>.dropdown-toggle.btn-warning {
    background-image: none
}

*,
:after,
:before {
    box-sizing: border-box
}

html {
    font-size: 10px
}

body {
    margin: 0;
    font-size: 14px
}

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

a:focus,
a:hover {
    color: #23527c
}

a:focus {
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: -2px
}

.demo-picks>div h5,
.mfp-figure figure,
.panel-body p,
.post-header h5,
figure {
    margin: 0
}

img {
    vertical-align: middle;
    max-width: 100%
}

.carousel-inner>.item>a>img,
.carousel-inner>.item>img,
.img-responsive,
.thumbnail a>img,
.thumbnail>img {
    display: block;
    max-width: 100%;
    height: auto
}

.img-thumbnail,
.list-inline>li,
label {
    display: inline-block
}

.img-rounded {
    border-radius: 6px
}

.img-thumbnail {
    max-width: 100%;
    height: auto;
    padding: 4px;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    transition: .2s ease-in-out
}

.img-circle {
    border-radius: 50%
}

hr {
    border: 0;
    border-top: 1px solid #eee
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}

.mfp-gallery .mfp-image-holder .mfp-figure,
.nav-tabs>li>a:hover,
.panel-title:hover,
[role=button],
a:hover,
nav a {
    cursor: pointer
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit
}

legend,
pre {
    color: #333
}

.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
    font-weight: 400;
    line-height: 1;
    color: #777
}

kbd kbd,
label {
    font-weight: 700
}

address,
blockquote .small,
blockquote footer,
blockquote small,
dd,
dt,
pre {
    line-height: 1.42857143
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
    margin-top: 20px;
    margin-bottom: 10px
}

.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small {
    font-size: 65%
}

.feature-center i,
.feature-left-stack i,
.h4,
.h5,
.h6,
.navbar-btn.btn-sm,
h4,
h5,
h6 {
    margin-top: 10px;
    margin-bottom: 10px
}

dl,
ol,
ul {
    margin-top: 0
}

.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
    font-size: 75%
}

.h1,
h1 {
    font-size: 36px
}

.h2,
h2 {
    font-size: 30px
}

.h3,
h3 {
    font-size: 24px
}

.h4,
h4 {
    font-size: 18px
}

.h5,
h5 {
    font-size: 14px
}

#twitter-feed a:last-child,
.h6,
h6 {
    font-size: 12px
}

.hero-content-slider h1,
.lead,
.mb20 {
    margin-bottom: 20px
}

.small,
small {
    font-size: 85%
}

.mark,
mark {
    padding: .2em;
    background-color: #fcf8e3
}

.list-inline,
.list-unstyled,
.media-list,
ol,
ul {
    padding-left: 0;
    list-style: none
}

code,
kbd {
    padding: 2px 4px;
    font-size: 90%
}

pre code,
table {
    background-color: transparent
}

.text-right {
    text-align: right
}

#success_page,
.contact.box-style,
.owl-dots,
.progress-circles,
.testimonials,
.text-center {
    text-align: center
}

.text-justify {
    text-align: justify
}

.text-lowercase {
    text-transform: lowercase
}

.text-uppercase {
    text-transform: uppercase
}

.text-capitalize {
    text-transform: capitalize
}

.text-primary {
    color: #337ab7
}

a.text-primary:focus,
a.text-primary:hover {
    color: #286090
}

.alert-success .alert-link,
a.text-success:focus,
a.text-success:hover {
    color: #2b542c
}

.text-info,
a.list-group-item-info,
button.list-group-item-info {
    color: #31708f
}

.alert-info .alert-link,
a.text-info:focus,
a.text-info:hover {
    color: #245269
}

.alert-warning .alert-link,
a.text-warning:focus,
a.text-warning:hover {
    color: #66512c
}

.alert-danger .alert-link,
a.text-danger:focus,
a.text-danger:hover {
    color: #843534
}

.bg-primary,
.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
    color: #fff;
    background-color: #337ab7
}

.label-primary[href]:focus,
.label-primary[href]:hover,
a.bg-primary:focus,
a.bg-primary:hover {
    background-color: #286090
}

a.bg-success:focus,
a.bg-success:hover {
    background-color: #c1e2b3
}

.bg-info,
.table>tbody>tr.info>td,
.table>tbody>tr.info>th,
.table>tbody>tr>td.info,
.table>tbody>tr>th.info,
.table>tfoot>tr.info>td,
.table>tfoot>tr.info>th,
.table>tfoot>tr>td.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>thead>tr.info>th,
.table>thead>tr>td.info,
.table>thead>tr>th.info {
    background-color: #d9edf7
}

a.bg-info:focus,
a.bg-info:hover {
    background-color: #afd9ee
}

.bg-warning,
.table>tbody>tr.warning>td,
.table>tbody>tr.warning>th,
.table>tbody>tr>td.warning,
.table>tbody>tr>th.warning,
.table>tfoot>tr.warning>td,
.table>tfoot>tr.warning>th,
.table>tfoot>tr>td.warning,
.table>tfoot>tr>th.warning,
.table>thead>tr.warning>td,
.table>thead>tr.warning>th,
.table>thead>tr>td.warning,
.table>thead>tr>th.warning {
    background-color: #fcf8e3
}

a.bg-warning:focus,
a.bg-warning:hover {
    background-color: #f7ecb5
}

.bg-danger,
.table>tbody>tr.danger>td,
.table>tbody>tr.danger>th,
.table>tbody>tr>td.danger,
.table>tbody>tr>th.danger,
.table>tfoot>tr.danger>td,
.table>tfoot>tr.danger>th,
.table>tfoot>tr>td.danger,
.table>tfoot>tr>th.danger,
.table>thead>tr.danger>td,
.table>thead>tr.danger>th,
.table>thead>tr>td.danger,
.table>thead>tr>th.danger {
    background-color: #f2dede
}

a.bg-danger:focus,
a.bg-danger:hover {
    background-color: #e4b9b9
}

.bg-grey .progress,
.btn-default,
.form-control,
.navbar-inverse .navbar-toggle .icon-bar,
.subscription input.bg-white,
.table .table {
    background-color: #fff
}

.page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #eee
}

#twitter-feed p,
.alert>p,
.alert>ul,
.mb0,
.panel>.list-group,
.panel>.panel-collapse>.list-group,
.panel>.panel-collapse>.table,
.panel>.table,
.panel>.table-responsive>.table,
.price-features p:last-child,
[data-gap="0"] .vossen-portfolio .portfolio-item,
[data-gap="0"] .vossen-portfolio-filters,
[data-gap="10"] .vossen-portfolio .portfolio-item,
[data-gap="15"] .vossen-portfolio .portfolio-item,
[data-gap="20"] .vossen-portfolio .portfolio-item,
[data-gap="25"] .vossen-portfolio .portfolio-item,
[data-gap="30"] .vossen-portfolio .portfolio-item,
[data-gap="35"] .vossen-portfolio .portfolio-item,
blockquote ol:last-child,
blockquote p:last-child,
blockquote ul:last-child,
ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

.table,
address,
dl,
legend {
    margin-bottom: 20px
}

.list-inline {
    margin-left: -5px
}

.list-inline>li {
    padding-right: 5px;
    padding-left: 5px
}

legend,
pre {
    display: block
}

.btn .caret,
.btn-group>.btn:first-child,
.col-xs-offset-0,
.ml0,
.modal-footer .btn-block+.btn-block,
.owl-theme:hover .owl-prev,
dd {
    margin-left: 0
}

p,
pre {
    margin: 0 0 10px
}

abbr[data-original-title],
abbr[title] {
    cursor: help;
    border-bottom: 1px dotted #777
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

blockquote {
    margin: 0 0 20px
}

blockquote .small,
blockquote footer,
blockquote small {
    display: block;
    font-size: 80%;
    color: #777
}

blockquote .small:before,
blockquote footer:before,
blockquote small:before {
    content: '\2014 \00A0'
}

.blockquote-reverse,
blockquote.pull-right {
    padding-right: 15px;
    padding-left: 0;
    text-align: right;
    border-right: 5px solid #eee;
    border-left: 0
}

.blockquote-reverse .small:before,
.blockquote-reverse footer:before,
.blockquote-reverse small:before,
blockquote.pull-right .small:before,
blockquote.pull-right footer:before,
blockquote.pull-right small:before {
    content: ''
}

.blockquote-reverse .small:after,
.blockquote-reverse footer:after,
.blockquote-reverse small:after,
blockquote.pull-right .small:after,
blockquote.pull-right footer:after,
blockquote.pull-right small:after {
    content: '\00A0 \2014'
}

address {
    font-style: normal
}

code,
kbd,
pre,
samp {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace
}

code {
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px
}

kbd {
    color: #fff;
    background-color: #333;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .25);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .25)
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    -webkit-box-shadow: none;
    box-shadow: none
}

pre {
    padding: 9.5px;
    font-size: 13px;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px
}

pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    border-radius: 0
}

.pre-scrollable {
    overflow-y: scroll
}

.container,
.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:768px) {
    .lead {
        font-size: 21px
    }

    .dl-horizontal dt {
        float: left;
        width: 160px;
        overflow: hidden;
        clear: left;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    .dl-horizontal dd {
        margin-left: 180px
    }

    .container {
        width: 750px
    }

    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9 {
        float: left
    }

    .col-sm-12 {
        width: 100%
    }

    .col-sm-11 {
        width: 91.66666667%
    }

    .col-sm-10 {
        width: 83.33333333%
    }

    .col-sm-9 {
        width: 75%
    }

    .col-sm-8 {
        width: 66.66666667%
    }

    .col-sm-7 {
        width: 58.33333333%
    }

    .col-sm-6 {
        width: 50%
    }

    .col-sm-5 {
        width: 41.66666667%
    }

    .col-sm-4 {
        width: 33.33333333%
    }

    .col-sm-3 {
        width: 25%
    }

    .col-sm-2 {
        width: 16.66666667%
    }

    .col-sm-1 {
        width: 8.33333333%
    }

    .col-sm-pull-12 {
        right: 100%
    }

    .col-sm-pull-11 {
        right: 91.66666667%
    }

    .col-sm-pull-10 {
        right: 83.33333333%
    }

    .col-sm-pull-9 {
        right: 75%
    }

    .col-sm-pull-8 {
        right: 66.66666667%
    }

    .col-sm-pull-7 {
        right: 58.33333333%
    }

    .col-sm-pull-6 {
        right: 50%
    }

    .col-sm-pull-5 {
        right: 41.66666667%
    }

    .col-sm-pull-4 {
        right: 33.33333333%
    }

    .col-sm-pull-3 {
        right: 25%
    }

    .col-sm-pull-2 {
        right: 16.66666667%
    }

    .col-sm-pull-1 {
        right: 8.33333333%
    }

    .col-sm-pull-0 {
        right: auto
    }

    .col-sm-push-12 {
        left: 100%
    }

    .col-sm-push-11 {
        left: 91.66666667%
    }

    .col-sm-push-10 {
        left: 83.33333333%
    }

    .col-sm-push-9 {
        left: 75%
    }

    .col-sm-push-8 {
        left: 66.66666667%
    }

    .col-sm-push-7 {
        left: 58.33333333%
    }

    .col-sm-push-6 {
        left: 50%
    }

    .col-sm-push-5 {
        left: 41.66666667%
    }

    .col-sm-push-4 {
        left: 33.33333333%
    }

    .col-sm-push-3 {
        left: 25%
    }

    .col-sm-push-2 {
        left: 16.66666667%
    }

    .col-sm-push-1 {
        left: 8.33333333%
    }

    .col-sm-push-0 {
        left: auto
    }

    .col-sm-offset-12 {
        margin-left: 100%
    }

    .col-sm-offset-11 {
        margin-left: 91.66666667%
    }

    .col-sm-offset-10 {
        margin-left: 83.33333333%
    }

    .col-sm-offset-9 {
        margin-left: 75%
    }

    .col-sm-offset-8 {
        margin-left: 66.66666667%
    }

    .col-sm-offset-7 {
        margin-left: 58.33333333%
    }

    .col-sm-offset-6 {
        margin-left: 50%
    }

    .col-sm-offset-5 {
        margin-left: 41.66666667%
    }

    .col-sm-offset-4 {
        margin-left: 33.33333333%
    }

    .col-sm-offset-3 {
        margin-left: 25%
    }

    .col-sm-offset-2 {
        margin-left: 16.66666667%
    }

    .col-sm-offset-1 {
        margin-left: 8.33333333%
    }

    .col-sm-offset-0 {
        margin-left: 0
    }
}

@media (min-width:992px) {
    .container {
        width: 970px
    }

    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9 {
        float: left
    }

    .col-md-12 {
        width: 100%
    }

    .col-md-11 {
        width: 91.66666667%
    }

    .col-md-10 {
        width: 83.33333333%
    }

    .col-md-9 {
        width: 75%
    }

    .col-md-8 {
        width: 66.66666667%
    }

    .col-md-7 {
        width: 58.33333333%
    }

    .col-md-6 {
        width: 50%
    }

    .col-md-5 {
        width: 41.66666667%
    }

    .col-md-4 {
        width: 33.33333333%
    }

    .col-md-3 {
        width: 25%
    }

    .col-md-2 {
        width: 16.66666667%
    }

    .col-md-1 {
        width: 8.33333333%
    }

    .col-md-pull-12 {
        right: 100%
    }

    .col-md-pull-11 {
        right: 91.66666667%
    }

    .col-md-pull-10 {
        right: 83.33333333%
    }

    .col-md-pull-9 {
        right: 75%
    }

    .col-md-pull-8 {
        right: 66.66666667%
    }

    .col-md-pull-7 {
        right: 58.33333333%
    }

    .col-md-pull-6 {
        right: 50%
    }

    .col-md-pull-5 {
        right: 41.66666667%
    }

    .col-md-pull-4 {
        right: 33.33333333%
    }

    .col-md-pull-3 {
        right: 25%
    }

    .col-md-pull-2 {
        right: 16.66666667%
    }

    .col-md-pull-1 {
        right: 8.33333333%
    }

    .col-md-pull-0 {
        right: auto
    }

    .col-md-push-12 {
        left: 100%
    }

    .col-md-push-11 {
        left: 91.66666667%
    }

    .col-md-push-10 {
        left: 83.33333333%
    }

    .col-md-push-9 {
        left: 75%
    }

    .col-md-push-8 {
        left: 66.66666667%
    }

    .col-md-push-7 {
        left: 58.33333333%
    }

    .col-md-push-6 {
        left: 50%
    }

    .col-md-push-5 {
        left: 41.66666667%
    }

    .col-md-push-4 {
        left: 33.33333333%
    }

    .col-md-push-3 {
        left: 25%
    }

    .col-md-push-2 {
        left: 16.66666667%
    }

    .col-md-push-1 {
        left: 8.33333333%
    }

    .col-md-push-0 {
        left: auto
    }

    .col-md-offset-12 {
        margin-left: 100%
    }

    .col-md-offset-11 {
        margin-left: 91.66666667%
    }

    .col-md-offset-10 {
        margin-left: 83.33333333%
    }

    .col-md-offset-9 {
        margin-left: 75%
    }

    .col-md-offset-8 {
        margin-left: 66.66666667%
    }

    .col-md-offset-7 {
        margin-left: 58.33333333%
    }

    .col-md-offset-6 {
        margin-left: 50%
    }

    .col-md-offset-5 {
        margin-left: 41.66666667%
    }

    .col-md-offset-4 {
        margin-left: 33.33333333%
    }

    .col-md-offset-3 {
        margin-left: 25%
    }

    .col-md-offset-2 {
        margin-left: 16.66666667%
    }

    .col-md-offset-1 {
        margin-left: 8.33333333%
    }

    .col-md-offset-0 {
        margin-left: 0
    }
}

.container-fluid>.navbar-collapse,
.container-fluid>.navbar-header,
.container>.navbar-collapse,
.container>.navbar-header,
.form-horizontal .form-group,
.row {
    margin-right: -15px;
    margin-left: -15px
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}

.btn-group>.btn-group,
.btn-toolbar .btn,
.btn-toolbar .btn-group,
.btn-toolbar .input-group,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.nav-pills>li,
.pager .previous>a,
.pager .previous>span {
    float: left
}

.btn-group-justified>.btn-group .btn,
.col-xs-12,
.dropdown-submenu a,
.nav-justified,
.portfolio-wide .container,
.subscribe-form-submit,
.team-image img,
.width100,
input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}

.col-xs-11 {
    width: 91.66666667%
}

.col-xs-10 {
    width: 83.33333333%
}

.col-xs-9 {
    width: 75%
}

.col-xs-8 {
    width: 66.66666667%
}

.col-xs-7 {
    width: 58.33333333%
}

.col-xs-6 {
    width: 50%
}

.col-xs-5 {
    width: 41.66666667%
}

.col-xs-4 {
    width: 33.33333333%
}

.col-xs-3 {
    width: 25%
}

.col-xs-2 {
    width: 16.66666667%
}

.col-xs-1 {
    width: 8.33333333%
}

.col-xs-pull-12 {
    right: 100%
}

.col-xs-pull-11 {
    right: 91.66666667%
}

.col-xs-pull-10 {
    right: 83.33333333%
}

.col-xs-pull-9 {
    right: 75%
}

.col-xs-pull-8 {
    right: 66.66666667%
}

.col-xs-pull-7 {
    right: 58.33333333%
}

.col-xs-pull-6 {
    right: 50%
}

.col-xs-pull-5 {
    right: 41.66666667%
}

.col-xs-pull-4 {
    right: 33.33333333%
}

.col-xs-pull-3 {
    right: 25%
}

.col-xs-pull-2 {
    right: 16.66666667%
}

.col-xs-pull-1 {
    right: 8.33333333%
}

.col-xs-pull-0 {
    right: auto
}

.carousel-inner>.active.right,
.carousel-inner>.next,
.col-xs-push-12 {
    left: 100%
}

.col-xs-push-11 {
    left: 91.66666667%
}

.col-xs-push-10 {
    left: 83.33333333%
}

.col-xs-push-9 {
    left: 75%
}

.col-xs-push-8 {
    left: 66.66666667%
}

.col-xs-push-7 {
    left: 58.33333333%
}

.col-xs-push-6 {
    left: 50%
}

.col-xs-push-5 {
    left: 41.66666667%
}

.col-xs-push-4 {
    left: 33.33333333%
}

.col-xs-push-3 {
    left: 25%
}

.col-xs-push-2 {
    left: 16.66666667%
}

.col-xs-push-1 {
    left: 8.33333333%
}

.btn-group-justified>.btn-group .dropdown-menu,
.col-xs-push-0 {
    left: auto
}

.col-xs-offset-12 {
    margin-left: 100%
}

.col-xs-offset-11 {
    margin-left: 91.66666667%
}

.col-xs-offset-10 {
    margin-left: 83.33333333%
}

.col-xs-offset-9 {
    margin-left: 75%
}

.col-xs-offset-8 {
    margin-left: 66.66666667%
}

.col-xs-offset-7 {
    margin-left: 58.33333333%
}

.col-xs-offset-6 {
    margin-left: 50%
}

.col-xs-offset-5 {
    margin-left: 41.66666667%
}

.col-xs-offset-4 {
    margin-left: 33.33333333%
}

.col-xs-offset-3 {
    margin-left: 25%
}

.col-xs-offset-2 {
    margin-left: 16.66666667%
}

.col-xs-offset-1 {
    margin-left: 8.33333333%
}

.caret,
.nav-pills>li+li {
    margin-left: 2px
}

table {
    border-spacing: 0;
    border-collapse: collapse
}

caption {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #777
}

.table {
    width: 100%;
    max-width: 100%
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd
}

.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd
}

.panel-group .panel-footer,
.panel>.table>tbody:first-child>tr:first-child td,
.panel>.table>tbody:first-child>tr:first-child th,
.table>caption+thead>tr:first-child>td,
.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>td,
.table>thead:first-child>tr:first-child>th {
    border-top: 0
}

.table>tbody+tbody {
    border-top: 2px solid #ddd
}

.table-condensed>tbody>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>thead>tr>th {
    padding: 5px
}

.nav-tabs-justified>.active>a,
.nav-tabs-justified>.active>a:focus,
.nav-tabs-justified>.active>a:hover,
.nav-tabs.nav-justified>.active>a,
.nav-tabs.nav-justified>.active>a:focus,
.nav-tabs.nav-justified>.active>a:hover,
.table-bordered,
.table-bordered>tbody>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
    border: 1px solid #ddd
}

.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
    border-bottom-width: 2px
}

fieldset,
legend {
    padding: 0;
    border: 0
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #f9f9f9
}

.table-hover>tbody>tr:hover,
.table>tbody>tr.active>td,
.table>tbody>tr.active>th,
.table>tbody>tr>td.active,
.table>tbody>tr>th.active,
.table>tfoot>tr.active>td,
.table>tfoot>tr.active>th,
.table>tfoot>tr>td.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>thead>tr.active>th,
.table>thead>tr>td.active,
.table>thead>tr>th.active {
    background-color: #f5f5f5
}

table col[class*=col-] {
    position: static;
    display: table-column;
    float: none
}

table td[class*=col-],
table th[class*=col-] {
    position: static;
    display: table-cell;
    float: none
}

.table-hover>tbody>tr.active:hover>td,
.table-hover>tbody>tr.active:hover>th,
.table-hover>tbody>tr:hover>.active,
.table-hover>tbody>tr>td.active:hover,
.table-hover>tbody>tr>th.active:hover {
    background-color: #e8e8e8
}

.table-hover>tbody>tr.success:hover>td,
.table-hover>tbody>tr.success:hover>th,
.table-hover>tbody>tr:hover>.success,
.table-hover>tbody>tr>td.success:hover,
.table-hover>tbody>tr>th.success:hover {
    background-color: #d0e9c6
}

.table-hover>tbody>tr.info:hover>td,
.table-hover>tbody>tr.info:hover>th,
.table-hover>tbody>tr:hover>.info,
.table-hover>tbody>tr>td.info:hover,
.table-hover>tbody>tr>th.info:hover {
    background-color: #c4e3f3
}

.table-hover>tbody>tr.warning:hover>td,
.table-hover>tbody>tr.warning:hover>th,
.table-hover>tbody>tr:hover>.warning,
.table-hover>tbody>tr>td.warning:hover,
.table-hover>tbody>tr>th.warning:hover {
    background-color: #faf2cc
}

.table-hover>tbody>tr.danger:hover>td,
.table-hover>tbody>tr.danger:hover>th,
.table-hover>tbody>tr:hover>.danger,
.table-hover>tbody>tr>td.danger:hover,
.table-hover>tbody>tr>th.danger:hover {
    background-color: #ebcccc
}

.table-responsive {
    min-height: .01%;
    overflow-x: auto
}

@media screen and (max-width:767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #ddd
    }

    .table-responsive>.table {
        margin-bottom: 0
    }

    .table-responsive>.table>tbody>tr>td,
    .table-responsive>.table>tbody>tr>th,
    .table-responsive>.table>tfoot>tr>td,
    .table-responsive>.table>tfoot>tr>th,
    .table-responsive>.table>thead>tr>td,
    .table-responsive>.table>thead>tr>th {
        white-space: nowrap
    }

    .table-responsive>.table-bordered {
        border: 0
    }

    .table-responsive>.table-bordered>tbody>tr>td:first-child,
    .table-responsive>.table-bordered>tbody>tr>th:first-child,
    .table-responsive>.table-bordered>tfoot>tr>td:first-child,
    .table-responsive>.table-bordered>tfoot>tr>th:first-child,
    .table-responsive>.table-bordered>thead>tr>td:first-child,
    .table-responsive>.table-bordered>thead>tr>th:first-child {
        border-left: 0
    }

    .table-responsive>.table-bordered>tbody>tr>td:last-child,
    .table-responsive>.table-bordered>tbody>tr>th:last-child,
    .table-responsive>.table-bordered>tfoot>tr>td:last-child,
    .table-responsive>.table-bordered>tfoot>tr>th:last-child,
    .table-responsive>.table-bordered>thead>tr>td:last-child,
    .table-responsive>.table-bordered>thead>tr>th:last-child {
        border-right: 0
    }

    .table-responsive>.table-bordered>tbody>tr:last-child>td,
    .table-responsive>.table-bordered>tbody>tr:last-child>th,
    .table-responsive>.table-bordered>tfoot>tr:last-child>td,
    .table-responsive>.table-bordered>tfoot>tr:last-child>th {
        border-bottom: 0
    }
}

fieldset {
    min-width: 0;
    margin: 0
}

legend {
    width: 100%;
    font-size: 21px;
    line-height: inherit;
    border-bottom: 1px solid #e5e5e5
}

.form-control,
output {
    font-size: 14px;
    line-height: 1.42857143;
    color: #555
}

label {
    max-width: 100%;
    margin-bottom: 5px
}

input[type=search] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none
}

input[type=checkbox],
input[type=radio] {
    margin: 4px 0 0;
    line-height: normal
}

.btn-block,
input[type=range] {
    display: block;
    width: 100%
}

.form-group-lg select[multiple].form-control,
.form-group-lg textarea.form-control,
.form-group-sm select[multiple].form-control,
.form-group-sm textarea.form-control,
select[multiple],
select[multiple].input-group-lg>.form-control,
select[multiple].input-group-lg>.input-group-addon,
select[multiple].input-group-lg>.input-group-btn>.btn,
select[multiple].input-group-sm>.form-control,
select[multiple].input-group-sm>.input-group-addon,
select[multiple].input-group-sm>.input-group-btn>.btn,
select[multiple].input-lg,
select[multiple].input-sm,
select[size],
textarea.form-control,
textarea.input-group-lg>.form-control,
textarea.input-group-lg>.input-group-addon,
textarea.input-group-lg>.input-group-btn>.btn,
textarea.input-group-sm>.form-control,
textarea.input-group-sm>.input-group-addon,
textarea.input-group-sm>.input-group-btn>.btn,
textarea.input-lg,
textarea.input-sm {
    height: auto
}

input[type=checkbox]:focus,
input[type=file]:focus,
input[type=radio]:focus {
    outline: dotted thin;
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: -2px
}

output {
    display: block;
    padding-top: 7px
}

#iconTabs li a .icon-tab,
h1 {
    font-size: 64px
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control:focus {
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6)
}

.form-control::-moz-placeholder {
    color: #999;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #999
}

.form-control::-webkit-input-placeholder {
    color: #999
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #eee;
    opacity: 1
}

.checkbox-inline.disabled,
.checkbox.disabled label,
.form-control[disabled],
.radio-inline.disabled,
.radio.disabled label,
fieldset[disabled] .checkbox label,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .form-control,
fieldset[disabled] .radio label,
fieldset[disabled] .radio-inline,
fieldset[disabled] input[type=checkbox],
fieldset[disabled] input[type=radio],
input[type=checkbox].disabled,
input[type=checkbox][disabled],
input[type=radio].disabled,
input[type=radio][disabled] {
    cursor: not-allowed
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

    input[type=date].form-control,
    input[type=datetime-local].form-control,
    input[type=month].form-control,
    input[type=time].form-control {
        line-height: 34px
    }

    .input-group-sm input[type=date],
    .input-group-sm input[type=datetime-local],
    .input-group-sm input[type=month],
    .input-group-sm input[type=time],
    input[type=date].input-sm,
    input[type=datetime-local].input-sm,
    input[type=month].input-sm,
    input[type=time].input-sm {
        line-height: 30px
    }

    .input-group-lg input[type=date],
    .input-group-lg input[type=datetime-local],
    .input-group-lg input[type=month],
    .input-group-lg input[type=time],
    input[type=date].input-lg,
    input[type=datetime-local].input-lg,
    input[type=month].input-lg,
    input[type=time].input-lg {
        line-height: 46px
    }
}

.form-group,
.mb15 {
    margin-bottom: 15px
}

.checkbox,
.radio {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px
}

.checkbox label,
.radio label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer
}

.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox],
.radio input[type=radio],
.radio-inline input[type=radio] {
    position: absolute;
    margin-left: -20px
}

.checkbox+.checkbox,
.radio+.radio {
    margin-top: -5px
}

.checkbox-inline,
.radio-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer
}

.checkbox-inline+.checkbox-inline,
.radio-inline+.radio-inline {
    margin-top: 0;
    margin-left: 10px
}

.form-control-static {
    min-height: 34px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0
}

.form-control-static.input-lg,
.form-control-static.input-sm {
    padding-right: 0;
    padding-left: 0
}

.form-group-sm .form-control,
.input-group-sm>.form-control,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.btn,
.input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

.form-group-sm select.form-control,
select.input-group-sm>.form-control,
select.input-group-sm>.input-group-addon,
select.input-group-sm>.input-group-btn>.btn,
select.input-sm {
    height: 30px;
    line-height: 30px
}

.form-group-sm .form-control-static {
    height: 30px;
    min-height: 32px;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 1.5
}

.form-group-lg .form-control,
.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn,
.input-lg {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px
}

.form-group-lg select.form-control,
select.input-group-lg>.form-control,
select.input-group-lg>.input-group-addon,
select.input-group-lg>.input-group-btn>.btn,
select.input-lg {
    height: 46px;
    line-height: 46px
}

.form-group-lg .form-control-static {
    height: 46px;
    min-height: 38px;
    padding: 11px 16px;
    font-size: 18px;
    line-height: 1.3333333
}

.carousel,
.dropdown,
.dropdown-submenu,
.dropup,
.footer,
.has-feedback,
.input-group-btn>.btn,
.price-box .price-price,
.tab-content,
[data-overlay-color],
[data-overlay-dark],
[data-overlay-light] {
    position: relative
}

.has-feedback .form-control {
    padding-right: 42.5px
}

.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none
}

.form-group-lg .form-control+.form-control-feedback,
.input-group-lg+.form-control-feedback,
.input-lg+.form-control-feedback {
    width: 46px;
    height: 46px;
    line-height: 46px
}

.form-group-sm .form-control+.form-control-feedback,
.input-group-sm+.form-control-feedback,
.input-sm+.form-control-feedback {
    width: 30px;
    height: 30px;
    line-height: 30px
}

.has-success .form-control {
    border-color: #3c763d;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-success .form-control:focus {
    border-color: #2b542c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #67b168;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #67b168
}

.has-success .input-group-addon {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #3c763d
}

.has-warning .form-control {
    border-color: #8a6d3b;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-warning .form-control:focus {
    border-color: #66512c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #c0a16b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #c0a16b
}

.has-warning .input-group-addon {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #8a6d3b
}

.has-error .form-control {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-error .form-control:focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483
}

.has-error .input-group-addon {
    color: #a94442;
    background-color: #f2dede;
    border-color: #a94442
}

.has-feedback label~.form-control-feedback {
    top: 25px
}

#footer-1 .subscription .form-validation,
.has-feedback label.sr-only~.form-control-feedback,
.hero-fullscreen .background-image {
    top: 0
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373
}

.btn,
.caret {
    display: inline-block;
    vertical-align: middle
}

.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
    padding-top: 7px;
    margin-top: 0;
    margin-bottom: 0
}

.form-horizontal .checkbox,
.form-horizontal .radio {
    min-height: 27px
}

.form-horizontal .has-feedback .form-control-feedback {
    right: 15px
}

.btn {
    text-align: center;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: -2px;
    outline: 0
}

.btn.focus,
.btn:focus,
.btn:hover {
    color: #333;
    text-decoration: none
}

.btn.active,
.btn:active {
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    cursor: not-allowed;
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65
}

a.btn.disabled,
fieldset[disabled] a.btn {
    pointer-events: none
}

.btn-default {
    color: #333;
    border-color: #ccc
}

.btn-default.focus,
.btn-default:focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c
}

.btn-default.active,
.btn-default:active,
.btn-default:hover,
.open>.dropdown-toggle.btn-default {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open>.dropdown-toggle.btn-default.focus,
.open>.dropdown-toggle.btn-default:focus,
.open>.dropdown-toggle.btn-default:hover {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c
}

.btn-default.disabled.focus,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled].focus,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
    background-color: #fff;
    border-color: #ccc
}

.btn-default .badge {
    color: #fff;
    background-color: #333
}

.btn-primary,
.btn-primary.disabled.focus,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled].focus,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
    background-color: #337ab7;
    border-color: #2e6da4
}

.btn-primary.focus,
.btn-primary:focus {
    color: #fff;
    background-color: #286090;
    border-color: #122b40
}

.btn-primary.active,
.btn-primary:active,
.btn-primary:hover,
.open>.dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #286090;
    border-color: #204d74
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
    color: #fff;
    background-color: #204d74;
    border-color: #122b40
}

.btn-primary .badge,
.list-group-item.active>.badge,
.nav-pills>.active>a>.badge,
.panel-primary>.panel-heading .badge {
    color: #337ab7;
    background-color: #fff
}

.btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c
}

.btn-success.focus,
.btn-success:focus {
    color: #fff;
    background-color: #449d44;
    border-color: #255625
}

.btn-success.active,
.btn-success:active,
.btn-success:hover,
.open>.dropdown-toggle.btn-success {
    color: #fff;
    background-color: #449d44;
    border-color: #398439
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
    color: #fff;
    background-color: #398439;
    border-color: #255625
}

.btn-success.disabled.focus,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success[disabled].focus,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:hover {
    background-color: #5cb85c;
    border-color: #4cae4c
}

.btn-success .badge {
    color: #5cb85c;
    background-color: #fff
}

.btn-info {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da
}

.btn-info.focus,
.btn-info:focus {
    color: #fff;
    background-color: #31b0d5;
    border-color: #1b6d85
}

.btn-info.active,
.btn-info:active,
.btn-info:hover,
.open>.dropdown-toggle.btn-info {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info:active.focus,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover {
    color: #fff;
    background-color: #269abc;
    border-color: #1b6d85
}

.btn-info.disabled.focus,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info[disabled].focus,
.btn-info[disabled]:focus,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:hover {
    background-color: #5bc0de;
    border-color: #46b8da
}

.btn-info .badge {
    color: #5bc0de;
    background-color: #fff
}

.btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236
}

.btn-warning.focus,
.btn-warning:focus {
    color: #fff;
    background-color: #ec971f;
    border-color: #985f0d
}

.btn-warning.active,
.btn-warning:active,
.btn-warning:hover,
.open>.dropdown-toggle.btn-warning {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning:active.focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover {
    color: #fff;
    background-color: #d58512;
    border-color: #985f0d
}

.btn-warning.disabled.focus,
.btn-warning.disabled:focus,
.btn-warning.disabled:hover,
.btn-warning[disabled].focus,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:hover {
    background-color: #f0ad4e;
    border-color: #eea236
}

.btn-warning .badge {
    color: #f0ad4e;
    background-color: #fff
}

.btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a
}

.btn-danger.focus,
.btn-danger:focus {
    color: #fff;
    background-color: #c9302c;
    border-color: #761c19
}

.btn-danger.active,
.btn-danger:active,
.btn-danger:hover,
.open>.dropdown-toggle.btn-danger {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active.focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover {
    color: #fff;
    background-color: #ac2925;
    border-color: #761c19
}

.btn-danger.disabled.focus,
.btn-danger.disabled:focus,
.btn-danger.disabled:hover,
.btn-danger[disabled].focus,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:hover {
    background-color: #d9534f;
    border-color: #d43f3a
}

.btn-danger .badge {
    color: #d9534f;
    background-color: #fff
}

.btn-link {
    font-weight: 400;
    color: #337ab7;
    border-radius: 0
}

.btn-link,
.btn-link.active,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-link,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
    border-color: transparent
}

.btn-link:focus,
.btn-link:hover {
    color: #23527c;
    text-decoration: underline;
    background-color: transparent
}

.btn-link[disabled]:focus,
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:hover {
    color: #777;
    text-decoration: none
}

.btn-group-lg>.btn,
.btn-lg {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px
}

.btn-group-sm>.btn,
.btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

.btn-group-xs>.btn,
.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

.alert>p+p,
.btn-block+.btn-block,
.mt5 {
    margin-top: 5px
}

.fade {
    opacity: 0;
    -webkit-transition: opacity .15s linear;
    -o-transition: opacity .15s linear;
    transition: opacity .15s linear
}

.fade.in,
.mfp-arrow:focus,
.mfp-arrow:hover,
.mfp-close:focus,
.mfp-close:hover,
.owl-theme:hover .owl-controls .owl-nav div,
.team-container:hover .team-caption,
.vossen-blog-grid>div.reveal,
.vossen-portfolio>div.reveal,
[data-overlay-color="10"] .hero-slider .slide:before,
[data-overlay-color="10"]:before,
[data-overlay-dark="10"] .hero-slider .slide:before,
[data-overlay-dark="10"]:before,
[data-overlay-light="10"] .hero-slider .slide:before,
[data-overlay-light="10"]:before {
    opacity: 1
}

tr.collapse.in {
    display: table-row
}

tbody.collapse.in {
    display: table-row-group
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: .35s;
    -o-transition-duration: .35s;
    transition-duration: .35s;
    -webkit-transition-property: height, visibility;
    -o-transition-property: height, visibility;
    transition-property: height, visibility
}

.caret {
    width: 0;
    height: 0;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle,
.dropdown-toggle:focus,
.navbar-toggle:focus,
.open>a {
    outline: 0
}

.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175)
}

.dropdown-menu-right,
.dropdown-menu.pull-right,
.pull-right>.dropdown-menu {
    right: 0;
    left: auto
}

.dropdown-menu .divider,
.nav .nav-divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5
}

.dropdown-menu>li>a {
    display: block;
    clear: both;
    line-height: 1.42857143
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover,
.nav>li>a:focus,
.nav>li>a:hover,
.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
    color: #fff;
    text-decoration: none;
    background-color: #337ab7;
    outline: 0
}

.dropdown-menu>.disabled>a:focus,
.dropdown-menu>.disabled>a:hover {
    text-decoration: none;
    cursor: not-allowed;
    background-color: transparent;
    background-image: none
}

.dropdown-menu-left {
    right: auto;
    left: 0
}

.dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #777
}

.dropdown-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 990
}

.btn-group,
.btn-group-vertical,
.input-group,
.input-group .form-control,
.input-group-btn,
.nav>li,
.nav>li>a,
.navbar {
    position: relative
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px
}

.btn-group,
.btn-group-vertical {
    display: inline-block;
    vertical-align: middle
}

.btn-group-justified,
.input-group {
    display: table;
    border-collapse: separate
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative;
    float: left
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover,
.input-group-btn>.btn:active,
.input-group-btn>.btn:focus,
.input-group-btn>.btn:hover {
    z-index: 2
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group,
.input-group-btn>.btn+.btn,
.modal-footer .btn-group .btn+.btn {
    margin-left: -1px
}

.btn-toolbar {
    margin-left: -5px
}

.btn-toolbar>.btn,
.btn-toolbar>.btn-group,
.btn-toolbar>.input-group,
.form-leave-comment h5,
.ml15,
.mr5 {
    margin-left: 5px
}

.btn-group-vertical>.btn-group:not(:first-child):not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:first-child):not(:last-child),
.btn-group>.btn-group:not(:first-child):not(:last-child)>.btn,
.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle),
.btn-square,
.container-fluid .portfolio-item,
.input-group .form-control:not(:first-child):not(:last-child),
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.portfolio-wide .portfolio-item {
    border-radius: 0
}

.btn-group>.btn-group:first-child:not(:last-child)>.btn:last-child,
.btn-group>.btn-group:first-child:not(:last-child)>.dropdown-toggle,
.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle),
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group>.btn,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn-group:not(:last-child)>.btn,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child,
.btn-group>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child),
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:first-child>.btn-group:not(:first-child)>.btn,
.input-group-btn:first-child>.btn:not(:first-child),
.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group>.btn,
.input-group-btn:last-child>.dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group>.btn+.dropdown-toggle {
    padding-right: 8px;
    padding-left: 8px
}

.btn-group>.btn-lg+.dropdown-toggle {
    padding-right: 12px;
    padding-left: 12px
}

.btn-group.open .dropdown-toggle {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}

.btn-group.open .dropdown-toggle.btn-link {
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-lg .caret {
    border-width: 5px 5px 0
}

.dropup .btn-lg .caret {
    border-width: 0 5px 5px
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group,
.btn-group-vertical>.btn-group>.btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%
}

.btn-group-vertical>.btn-group>.btn,
.dropdown-submenu.pull-left,
.nav-justified>li,
.nav-stacked>li,
.nav-tabs.nav-justified>li {
    float: none
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
    margin-top: -1px;
    margin-left: 0
}

.alert h4,
.input-group-addon input[type=checkbox],
.input-group-addon input[type=radio],
.media:first-child,
.mt0,
.owl-controls,
.panel-group .panel+.panel {
    margin-top: 0
}

.btn-group-vertical>.btn:first-child:not(:last-child) {
    border-radius: 4px 4px 0 0
}

.btn-group-vertical>.btn:last-child:not(:first-child) {
    border-radius: 0 0 4px 4px
}

.btn-group-vertical>.btn-group:first-child:not(:last-child)>.btn:last-child,
.btn-group-vertical>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:last-child:not(:first-child)>.btn:first-child,
.panel>.panel-heading+.panel-collapse>.list-group .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.btn-group-justified {
    width: 100%;
    table-layout: fixed
}

.btn-group-justified>.btn,
.btn-group-justified>.btn-group {
    display: table-cell;
    float: none;
    width: 1%
}

[data-toggle=buttons]>.btn input[type=checkbox],
[data-toggle=buttons]>.btn input[type=radio],
[data-toggle=buttons]>.btn-group>.btn input[type=checkbox],
[data-toggle=buttons]>.btn-group>.btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.input-group[class*=col-] {
    float: none;
    padding-right: 0;
    padding-left: 0
}

.input-group .form-control {
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0
}

.input-group .form-control:focus,
nav {
    z-index: 3
}

.input-group .form-control,
.input-group-addon,
.input-group-btn,
.sign-btn {
    display: table-cell
}

.input-group-addon,
.input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px
}

.input-group-addon.input-sm {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px
}

.input-group-addon.input-lg {
    padding: 10px 16px;
    font-size: 18px;
    border-radius: 6px
}

.input-group-addon:first-child,
.panel>.table-bordered>tbody>tr>td:last-child,
.panel>.table-bordered>tbody>tr>th:last-child,
.panel>.table-bordered>tfoot>tr>td:last-child,
.panel>.table-bordered>tfoot>tr>th:last-child,
.panel>.table-bordered>thead>tr>td:last-child,
.panel>.table-bordered>thead>tr>th:last-child,
.panel>.table-responsive>.table-bordered>tbody>tr>td:last-child,
.panel>.table-responsive>.table-bordered>tbody>tr>th:last-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>td:last-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>th:last-child,
.panel>.table-responsive>.table-bordered>thead>tr>td:last-child,
.panel>.table-responsive>.table-bordered>thead>tr>th:last-child {
    border-right: 0
}

.input-group-addon:last-child,
.panel>.table-bordered>tbody>tr>td:first-child,
.panel>.table-bordered>tbody>tr>th:first-child,
.panel>.table-bordered>tfoot>tr>td:first-child,
.panel>.table-bordered>tfoot>tr>th:first-child,
.panel>.table-bordered>thead>tr>td:first-child,
.panel>.table-bordered>thead>tr>th:first-child,
.panel>.table-responsive>.table-bordered>tbody>tr>td:first-child,
.panel>.table-responsive>.table-bordered>tbody>tr>th:first-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>td:first-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>th:first-child,
.panel>.table-responsive>.table-bordered>thead>tr>td:first-child,
.panel>.table-responsive>.table-bordered>thead>tr>th:first-child {
    border-left: 0
}

.input-group-btn {
    font-size: 0
}

.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group {
    margin-right: -1px
}

.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group {
    z-index: 2;
    margin-left: -1px
}

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav>li {
    display: block
}

.nav>li>a {
    display: block;
    padding: 10px 15px
}

.nav>li.disabled>a:focus,
.nav>li.disabled>a:hover {
    color: #777;
    text-decoration: none;
    cursor: not-allowed;
    background-color: transparent
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover,
.panel-primary,
a.thumbnail.active,
a.thumbnail:focus,
a.thumbnail:hover {
    border-color: #337ab7
}

.media-object.img-thumbnail,
.nav>li>a>img {
    max-width: none
}

.nav-tabs,
.panel-group .panel-footer+.panel-collapse .panel-body {
    border-bottom: 1px solid #ddd
}

.nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0
}

.nav-tabs-justified>li>a,
.nav-tabs.nav-justified>li>a {
    margin-right: 0;
    border-radius: 4px
}

.navbar-brand,
.navbar-nav>li>a {
    line-height: 20px
}

.nav-tabs>li>a:hover {
    border-color: #eee #eee #ddd
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent
}

.nav-tabs.nav-justified {
    width: 100%;
    border-bottom: 0
}

.nav-tabs.nav-justified>li>a {
    margin-bottom: 5px;
    text-align: center
}

.nav-justified>.dropdown .dropdown-menu,
.nav-tabs.nav-justified>.dropdown .dropdown-menu {
    top: auto;
    left: auto
}

.btn-round,
.nav-pills>li>a,
.progress-round .progress,
.progress-round .progress-bar {
    border-radius: 4px
}

.nav-stacked>li+li {
    margin-top: 2px;
    margin-left: 0
}

.nav-justified>li>a {
    margin-bottom: 5px;
    text-align: center
}

.nav-tabs-justified,
.panel-group .panel-heading,
.panel>.table-bordered>tbody>tr:first-child>td,
.panel>.table-bordered>tbody>tr:first-child>th,
.panel>.table-bordered>tbody>tr:last-child>td,
.panel>.table-bordered>tbody>tr:last-child>th,
.panel>.table-bordered>tfoot>tr:last-child>td,
.panel>.table-bordered>tfoot>tr:last-child>th,
.panel>.table-bordered>thead>tr:first-child>td,
.panel>.table-bordered>thead>tr:first-child>th,
.panel>.table-responsive>.table-bordered>tbody>tr:first-child>td,
.panel>.table-responsive>.table-bordered>tbody>tr:first-child>th,
.panel>.table-responsive>.table-bordered>tbody>tr:last-child>td,
.panel>.table-responsive>.table-bordered>tbody>tr:last-child>th,
.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>td,
.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>th,
.panel>.table-responsive>.table-bordered>thead>tr:first-child>td,
.panel>.table-responsive>.table-bordered>thead>tr:first-child>th {
    border-bottom: 0
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.navbar {
    min-height: 50px;
    margin-bottom: 20px;
    border: 1px solid transparent
}

.navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1)
}

.navbar-collapse.in {
    overflow-y: auto
}

@media (max-device-width:480px) and (orientation:landscape) {

    .navbar-fixed-bottom .navbar-collapse,
    .navbar-fixed-top .navbar-collapse {
        max-height: 200px
    }
}

.navbar-static-top {
    z-index: 1000;
    border-width: 0 0 1px
}

.navbar-fixed-bottom,
.navbar-fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030
}

.navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px
}

.navbar-fixed-bottom {
    bottom: 0;
    margin-bottom: 0;
    border-width: 1px 0 0
}

.navbar-brand {
    float: left;
    height: 50px;
    font-size: 18px
}

.badge,
.label {
    line-height: 1;
    white-space: nowrap;
    font-weight: 700
}

.navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 4px
}

.navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px
}

.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px
}

#twitter-feed a,
.blog-pagination li,
.breadcrumb>li,
.footer.style-1>a,
.inline-block,
.nav-tabs-center,
.pagination {
    display: inline-block
}

.navbar-nav {
    margin: 7.5px -15px
}

.navbar-form {
    padding: 10px 15px;
    margin: 8px -15px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1)
}

@media (min-width:768px) {

    .form-inline .form-control-static,
    .form-inline .form-group,
    .navbar-form .form-control-static,
    .navbar-form .form-group {
        display: inline-block
    }

    .form-inline .control-label,
    .form-inline .form-group,
    .navbar-form .control-label,
    .navbar-form .form-group {
        margin-bottom: 0;
        vertical-align: middle
    }

    .form-inline .form-control,
    .navbar-form .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .input-group,
    .navbar-form .input-group {
        display: inline-table;
        vertical-align: middle
    }

    .form-inline .input-group .form-control,
    .form-inline .input-group .input-group-addon,
    .form-inline .input-group .input-group-btn,
    .navbar-form .input-group .form-control,
    .navbar-form .input-group .input-group-addon,
    .navbar-form .input-group .input-group-btn {
        width: auto
    }

    .form-inline .input-group>.form-control,
    .navbar-form .input-group>.form-control {
        width: 100%
    }

    .form-inline .checkbox,
    .form-inline .radio,
    .navbar-form .checkbox,
    .navbar-form .radio {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle
    }

    .form-inline .checkbox label,
    .form-inline .radio label,
    .navbar-form .checkbox label,
    .navbar-form .radio label {
        padding-left: 0
    }

    .form-inline .checkbox input[type=checkbox],
    .form-inline .radio input[type=radio],
    .navbar-form .checkbox input[type=checkbox],
    .navbar-form .radio input[type=radio] {
        position: relative;
        margin-left: 0
    }

    .form-inline .has-feedback .form-control-feedback,
    .navbar-form .has-feedback .form-control-feedback {
        top: 0
    }

    .form-horizontal .control-label {
        padding-top: 7px;
        margin-bottom: 0;
        text-align: right
    }

    .form-horizontal .form-group-lg .control-label {
        padding-top: 11px;
        font-size: 18px
    }

    .form-horizontal .form-group-sm .control-label {
        padding-top: 6px;
        font-size: 12px
    }

    .navbar-right .dropdown-menu {
        right: 0;
        left: auto
    }

    .navbar-right .dropdown-menu-left {
        right: auto;
        left: 0
    }

    .nav-justified>li,
    .nav-tabs.nav-justified>li {
        display: table-cell;
        width: 1%
    }

    .nav-tabs.nav-justified>li>a {
        margin-bottom: 0;
        border-bottom: 1px solid #ddd;
        border-radius: 4px 4px 0 0
    }

    .nav-tabs-justified>.active>a,
    .nav-tabs-justified>.active>a:focus,
    .nav-tabs-justified>.active>a:hover,
    .nav-tabs.nav-justified>.active>a,
    .nav-tabs.nav-justified>.active>a:focus,
    .nav-tabs.nav-justified>.active>a:hover {
        border-bottom-color: #fff
    }

    .nav-justified>li>a {
        margin-bottom: 0
    }

    .nav-tabs-justified>li>a {
        border-bottom: 1px solid #ddd;
        border-radius: 4px 4px 0 0
    }

    .navbar {
        border-radius: 4px
    }

    .navbar-header,
    .navbar-nav>li {
        float: left
    }

    .navbar-collapse {
        width: auto;
        border-top: 0;
        -webkit-box-shadow: none;
        box-shadow: none
    }

    .navbar-collapse.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important
    }

    .navbar-collapse.in {
        overflow-y: visible
    }

    .navbar-fixed-bottom .navbar-collapse,
    .navbar-fixed-top .navbar-collapse,
    .navbar-static-top .navbar-collapse {
        padding-right: 0;
        padding-left: 0
    }

    .container-fluid>.navbar-collapse,
    .container-fluid>.navbar-header,
    .container>.navbar-collapse,
    .container>.navbar-header {
        margin-right: 0;
        margin-left: 0
    }

    .navbar-fixed-bottom,
    .navbar-fixed-top,
    .navbar-static-top {
        border-radius: 0
    }

    .navbar>.container .navbar-brand,
    .navbar>.container-fluid .navbar-brand {
        margin-left: -15px
    }

    .navbar-toggle {
        display: none
    }

    .navbar-nav {
        float: left;
        margin: 0
    }

    .navbar-nav>li>a {
        padding-top: 15px;
        padding-bottom: 15px
    }

    .navbar-form {
        width: auto;
        padding-top: 0;
        padding-bottom: 0;
        margin-right: 0;
        margin-left: 0;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none
    }
}

.navbar-nav>li>.dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.navbar-fixed-bottom .navbar-nav>li>.dropdown-menu {
    margin-bottom: 0;
    border-radius: 4px 4px 0 0
}

.navbar-btn {
    margin-top: 8px;
    margin-bottom: 8px
}

.navbar-btn.btn-xs {
    margin-top: 14px;
    margin-bottom: 14px
}

.navbar-text {
    margin-top: 15px;
    margin-bottom: 15px
}

.navbar-default {
    background-color: #f8f8f8;
    border-color: #e7e7e7
}

.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:hover {
    color: #5e5e5e;
    background-color: transparent
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
    color: #333;
    background-color: transparent
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
    color: #555;
    background-color: #e7e7e7
}

.navbar-default .navbar-nav>.disabled>a,
.navbar-default .navbar-nav>.disabled>a:focus,
.navbar-default .navbar-nav>.disabled>a:hover {
    color: #ccc;
    background-color: transparent
}

.navbar-default .navbar-toggle,
.panel-default,
.panel-default>.panel-heading {
    border-color: #ddd
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
    background-color: #ddd
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #888
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border-color: #e7e7e7
}

#buttonTabs li a:hover,
.mfp-close-btn-in .mfp-close,
.navbar-default .btn-link:focus,
.navbar-default .btn-link:hover,
.navbar-default .navbar-link:hover,
a.list-group-item .list-group-item-heading,
button.list-group-item .list-group-item-heading {
    color: #333
}

.mfp-preloader a,
.navbar-default .btn-link[disabled]:focus,
.navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .navbar-default .btn-link:focus,
fieldset[disabled] .navbar-default .btn-link:hover {
    color: #ccc
}

.navbar-inverse {
    background-color: #222;
    border-color: #080808
}

.navbar-inverse .btn-link,
.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-link,
.navbar-inverse .navbar-nav>li>a,
.navbar-inverse .navbar-text {
    color: #9d9d9d
}

.navbar-inverse .navbar-brand:focus,
.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-nav>li>a:focus,
.navbar-inverse .navbar-nav>li>a:hover {
    color: #fff;
    background-color: transparent
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:focus,
.navbar-inverse .navbar-nav>.active>a:hover,
.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:focus,
.navbar-inverse .navbar-nav>.open>a:hover {
    color: #fff;
    background-color: #080808
}

.navbar-inverse .navbar-nav>.disabled>a,
.navbar-inverse .navbar-nav>.disabled>a:focus,
.navbar-inverse .navbar-nav>.disabled>a:hover {
    color: #444;
    background-color: transparent
}

.navbar-inverse .navbar-toggle {
    border-color: #333
}

.navbar-inverse .navbar-toggle:focus,
.navbar-inverse .navbar-toggle:hover {
    background-color: #333
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
    border-color: #101010
}

#buttonTabs li.active a,
.btn-ghost:hover,
.btn-light:hover,
.btn-primary,
.contact input.submit:hover,
.contact.box-style input.submit:hover,
.dropdown-menu>li>a:hover,
.feature-center.white h5,
.feature-center.white i,
.feature-center.white p,
.mfp-preloader a:hover,
.mg-col a:hover,
.navbar-inverse .btn-link:focus,
.navbar-inverse .btn-link:hover,
.navbar-inverse .navbar-link:hover,
.navbar-nav .open .dropdown-menu>li>a:hover,
.navbar-nav>.open>a:hover,
.navbar-nav>li>ul>li>a:hover,
.testimonials.white h4,
.testimonials.white h5,
[data-overlay-color] h1,
[data-overlay-color] h2,
[data-overlay-color] h3,
[data-overlay-color] h4,
[data-overlay-color] h5,
[data-overlay-color] h6,
[data-overlay-dark] .progress-circle-icon i,
[data-overlay-dark] h1,
[data-overlay-dark] h2,
[data-overlay-dark] h3,
[data-overlay-dark] h4,
[data-overlay-dark] h5,
[data-overlay-dark] h6,
input.submit:hover {
    color: #fff
}

.navbar-inverse .btn-link[disabled]:focus,
.navbar-inverse .btn-link[disabled]:hover,
fieldset[disabled] .navbar-inverse .btn-link:focus,
fieldset[disabled] .navbar-inverse .btn-link:hover {
    color: #444
}

.breadcrumb {
    padding: 8px 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: #f5f5f5;
    border-radius: 4px
}

.pager,
.pagination {
    padding-left: 0;
    margin: 20px 0
}

.breadcrumb>li+li:before {
    padding: 0 5px;
    color: #ccc;
    content: "/\00a0"
}

.pagination {
    border-radius: 4px
}

.label,
.pager li,
.pagination>li {
    display: inline
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

.nav-pills>li>a>.badge,
.tooltip.right {
    margin-left: 3px
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
    z-index: 2;
    color: #23527c;
    background-color: #eee;
    border-color: #ddd
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #337ab7;
    border-color: #337ab7
}

.pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd
}

.pagination-lg>li>a,
.pagination-lg>li>span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
}

.pagination-sm>li>a,
.pagination-sm>li>span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5
}

.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px
}

.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px
}

.pager {
    text-align: center;
    list-style: none
}

.pager li>a,
.pager li>span {
    display: inline-block;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 15px
}

.pager li>a:focus,
.pager li>a:hover {
    text-decoration: none;
    background-color: #eee
}

.dropdown-submenu i,
.list-group-item>.badge,
.owl-carousel.owl-rtl .owl-item,
.pager .next>a,
.pager .next>span {
    float: right
}

.pager .disabled>a,
.pager .disabled>a:focus,
.pager .disabled>a:hover,
.pager .disabled>span {
    color: #777;
    cursor: not-allowed;
    background-color: #fff
}

.label {
    padding: .2em .6em .3em;
    font-size: 75%;
    color: #fff;
    text-align: center;
    vertical-align: baseline;
    border-radius: .25em
}

.badge,
.progress-bar {
    font-size: 12px;
    text-align: center
}

a.badge:focus,
a.badge:hover,
a.label:focus,
a.label:hover {
    color: #fff;
    text-decoration: none;
    cursor: pointer
}

.btn .badge,
.btn .label {
    position: relative;
    top: -1px
}

.label-default {
    background-color: #777
}

.label-default[href]:focus,
.label-default[href]:hover {
    background-color: #5e5e5e
}

.label-primary {
    background-color: #337ab7
}

.label-success,
.progress-bar-success {
    background-color: #5cb85c
}

.label-success[href]:focus,
.label-success[href]:hover {
    background-color: #449d44
}

.label-info,
.progress-bar-info {
    background-color: #5bc0de
}

.label-info[href]:focus,
.label-info[href]:hover {
    background-color: #31b0d5
}

.label-warning,
.progress-bar-warning {
    background-color: #f0ad4e
}

.label-warning[href]:focus,
.label-warning[href]:hover {
    background-color: #ec971f
}

.label-danger,
.progress-bar-danger {
    background-color: #d9534f
}

.label-danger[href]:focus,
.label-danger[href]:hover {
    background-color: #c9302c
}

.badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    color: #fff;
    vertical-align: middle;
    background-color: #777;
    border-radius: 10px
}

.jumbotron,
.jumbotron .h1,
.jumbotron h1,
.list-group-item.active .list-group-item-heading,
.list-group-item.active .list-group-item-heading>.small,
.list-group-item.active .list-group-item-heading>small,
.list-group-item.active:focus .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading>.small,
.list-group-item.active:focus .list-group-item-heading>small,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading>.small,
.list-group-item.active:hover .list-group-item-heading>small,
.list-group-item.disabled .list-group-item-heading,
.list-group-item.disabled:focus .list-group-item-heading,
.list-group-item.disabled:hover .list-group-item-heading,
.panel-heading>.dropdown .dropdown-toggle,
.panel-title>.small,
.panel-title>.small>a,
.panel-title>a,
.panel-title>small,
.panel-title>small>a,
a.list-group-item-danger .list-group-item-heading,
a.list-group-item-info .list-group-item-heading,
a.list-group-item-success .list-group-item-heading,
a.list-group-item-warning .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading,
button.list-group-item-info .list-group-item-heading,
button.list-group-item-success .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
    color: inherit
}

.btn-group-xs>.btn .badge,
.btn-xs .badge {
    top: 0;
    padding: 1px 5px
}

.list-group-item>.badge+.badge {
    margin-right: 5px
}

.jumbotron {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    background-color: #eee
}

.alert,
.thumbnail {
    margin-bottom: 20px
}

.jumbotron p {
    margin-bottom: 15px;
    font-size: 21px;
    font-weight: 200
}

.jumbotron>hr {
    border-top-color: #d5d5d5
}

.container .jumbotron,
.container-fluid .jumbotron {
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 6px
}

.img-responsive,
.jumbotron .container,
.mfp-image-holder .mfp-content {
    max-width: 100%
}

.thumbnail {
    display: block;
    padding: 4px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out
}

.thumbnail a>img,
.thumbnail>img {
    margin-right: auto;
    margin-left: auto
}

.thumbnail .caption {
    padding: 9px;
    color: #333
}

.alert {
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 4px
}

.alert h4 {
    color: inherit
}

.alert-dismissable,
.alert-dismissible {
    padding-right: 35px
}

.alert-dismissable .close,
.alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit
}

.alert-success,
.panel-success>.panel-heading {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6
}

.alert-success hr {
    border-top-color: #c9e2b3
}

.alert-info,
.panel-info>.panel-heading {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1
}

.alert-info hr {
    border-top-color: #a6e1ec
}

.alert-warning,
.panel-warning>.panel-heading {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc
}

.alert-warning hr {
    border-top-color: #f7e1b5
}

.alert-danger,
.panel-danger>.panel-heading {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1
}

.alert-danger hr {
    border-top-color: #e4b9c0
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 40px 0
    }

    to {
        background-position: 0 0
    }
}

@-o-keyframes progress-bar-stripes {
    from {
        background-position: 40px 0
    }

    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 40px 0
    }

    to {
        background-position: 0 0
    }
}

.progress {
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1)
}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    line-height: 20px;
    color: #fff;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    -webkit-transition: width .6s;
    -o-transition: width .6s;
    transition: width .6s
}

.progress-bar-striped,
.progress-striped .progress-bar {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    -webkit-background-size: 40px 40px;
    background-size: 40px 40px
}

.progress-bar.active,
.progress.active .progress-bar {
    -webkit-animation: 2s linear infinite progress-bar-stripes;
    -o-animation: 2s linear infinite progress-bar-stripes;
    animation: 2s linear infinite progress-bar-stripes
}

.progress-striped .progress-bar-danger,
.progress-striped .progress-bar-info,
.progress-striped .progress-bar-success,
.progress-striped .progress-bar-warning {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent)
}

.media {
    margin-top: 15px
}

.media,
.media-body {
    overflow: hidden;
    zoom: 1
}

.media-body {
    width: 10000px
}

.media-right,
.media>.pull-right,
.pl10 {
    padding-left: 10px
}

.media-left,
.media>.pull-left,
.pr10 {
    padding-right: 10px
}

.media-body,
.media-left,
.media-right {
    display: table-cell;
    vertical-align: top
}

.media-middle {
    vertical-align: middle
}

.media-bottom {
    vertical-align: bottom
}

.mfp-container:before,
.mfp-content {
    vertical-align: middle;
    display: inline-block
}

.list-group-item-heading,
.media-heading {
    margin-top: 0;
    margin-bottom: 5px
}

.list-group {
    padding-left: 0;
    margin-bottom: 20px
}

.list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd
}

.list-group-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

a.list-group-item,
button.list-group-item {
    color: #555
}

a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
    color: #555;
    text-decoration: none;
    background-color: #f5f5f5
}

button.list-group-item {
    width: 100%;
    text-align: left
}

.list-group-item.disabled,
.list-group-item.disabled:focus,
.list-group-item.disabled:hover {
    color: #777;
    cursor: not-allowed;
    background-color: #eee
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
    z-index: 2;
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
    color: #c7ddef
}

.list-group-item-success {
    color: #3c763d;
    background-color: #dff0d8
}

a.list-group-item-success:focus,
a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
    color: #3c763d;
    background-color: #d0e9c6
}

a.list-group-item-success.active,
a.list-group-item-success.active:focus,
a.list-group-item-success.active:hover,
button.list-group-item-success.active,
button.list-group-item-success.active:focus,
button.list-group-item-success.active:hover {
    color: #fff;
    background-color: #3c763d;
    border-color: #3c763d
}

.list-group-item-info {
    color: #31708f;
    background-color: #d9edf7
}

a.list-group-item-info:focus,
a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
    color: #31708f;
    background-color: #c4e3f3
}

a.list-group-item-info.active,
a.list-group-item-info.active:focus,
a.list-group-item-info.active:hover,
button.list-group-item-info.active,
button.list-group-item-info.active:focus,
button.list-group-item-info.active:hover {
    color: #fff;
    background-color: #31708f;
    border-color: #31708f
}

.list-group-item-warning {
    color: #8a6d3b;
    background-color: #fcf8e3
}

a.list-group-item-warning:focus,
a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
    color: #8a6d3b;
    background-color: #faf2cc
}

a.list-group-item-warning.active,
a.list-group-item-warning.active:focus,
a.list-group-item-warning.active:hover,
button.list-group-item-warning.active,
button.list-group-item-warning.active:focus,
button.list-group-item-warning.active:hover {
    color: #fff;
    background-color: #8a6d3b;
    border-color: #8a6d3b
}

.list-group-item-danger {
    color: #a94442;
    background-color: #f2dede
}

a.list-group-item-danger:focus,
a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
    color: #a94442;
    background-color: #ebcccc
}

a.list-group-item-danger.active,
a.list-group-item-danger.active:focus,
a.list-group-item-danger.active:hover,
button.list-group-item-danger.active,
button.list-group-item-danger.active:focus,
button.list-group-item-danger.active:hover {
    color: #fff;
    background-color: #a94442;
    border-color: #a94442
}

.list-group-item-text {
    margin-bottom: 0;
    line-height: 1.3
}

.carousel-inner>.item>a>img,
.carousel-inner>.item>img,
.close {
    line-height: 1
}

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05)
}

.panel-footer,
.well {
    background-color: #f5f5f5
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0
}

.panel-footer {
    padding: 10px 15px;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
}

.panel>.list-group .list-group-item,
.panel>.panel-collapse>.list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0
}

.panel>.list-group:first-child .list-group-item:first-child,
.panel>.panel-collapse>.list-group:first-child .list-group-item:first-child {
    border-top: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

.panel>.list-group:last-child .list-group-item:last-child,
.panel>.panel-collapse>.list-group:last-child .list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
}

.list-group+.panel-footer,
.panel-heading+.list-group .list-group-item:first-child {
    border-top-width: 0
}

.panel>.panel-collapse>.table caption,
.panel>.table caption,
.panel>.table-responsive>.table caption {
    padding-right: 15px;
    padding-left: 15px
}

.panel>.table-responsive:first-child>.table:first-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child,
.panel>.table:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child th:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child td:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child th:first-child {
    border-top-left-radius: 3px
}

.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:last-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:last-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:last-child,
.panel>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.panel>.table:first-child>tbody:first-child>tr:first-child th:last-child,
.panel>.table:first-child>thead:first-child>tr:first-child td:last-child,
.panel>.table:first-child>thead:first-child>tr:first-child th:last-child {
    border-top-right-radius: 3px
}

.panel>.table-responsive:last-child>.table:last-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child,
.panel>.table:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
}

.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:first-child,
.panel>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.panel>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child th:first-child {
    border-bottom-left-radius: 3px
}

.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child th:last-child {
    border-bottom-right-radius: 3px
}

.panel-group .panel-heading+.panel-collapse>.list-group,
.panel-group .panel-heading+.panel-collapse>.panel-body,
.panel>.panel-body+.table,
.panel>.panel-body+.table-responsive,
.panel>.table+.panel-body,
.panel>.table-responsive+.panel-body {
    border-top: 1px solid #ddd
}

.panel>.table-responsive {
    margin-bottom: 0;
    border: 0
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #ddd
}

.panel-default>.panel-heading .badge {
    color: #f5f5f5;
    background-color: #333
}

.panel-default>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #ddd
}

.panel-primary>.panel-heading {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7
}

.panel-primary>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #337ab7
}

.panel-primary>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #337ab7
}

.panel-success {
    border-color: #d6e9c6
}

.panel-success>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #d6e9c6
}

.panel-success>.panel-heading .badge {
    color: #dff0d8;
    background-color: #3c763d
}

.panel-success>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #d6e9c6
}

.panel-info {
    border-color: #bce8f1
}

.panel-info>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #bce8f1
}

.panel-info>.panel-heading .badge {
    color: #d9edf7;
    background-color: #31708f
}

.panel-info>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #bce8f1
}

.panel-warning {
    border-color: #faebcc
}

.panel-warning>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #faebcc
}

.panel-warning>.panel-heading .badge {
    color: #fcf8e3;
    background-color: #8a6d3b
}

.panel-warning>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #faebcc
}

.panel-danger {
    border-color: #ebccd1
}

.panel-danger>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #ebccd1
}

.panel-danger>.panel-heading .badge {
    color: #f2dede;
    background-color: #a94442
}

.panel-danger>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #ebccd1
}

.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-16by9 {
    padding-bottom: 56.25%
}

.embed-responsive-4by3 {
    padding-bottom: 75%
}

.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05)
}

.well blockquote {
    border-color: rgba(0, 0, 0, .15)
}

.well-lg {
    padding: 24px;
    border-radius: 6px
}

.well-sm {
    padding: 9px;
    border-radius: 3px
}

.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2
}

.popover,
.tooltip {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    line-height: 1.42857143;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    line-break: auto;
    font-weight: 400;
    text-decoration: none
}

.close:focus,
.close:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: .5
}

.mfp-arrow:after,
.mfp-arrow:before,
.mfp-removing .mfp-figure:after,
.mfp-zoom-in.mfp-removing.mfp-bg,
.mfp-zoom-out.mfp-removing.mfp-bg,
.modal-backdrop.fade,
.owl-carousel.owl-hidden,
.tooltip,
[data-overlay-color="0"] .hero-slider .slide:before,
[data-overlay-color="0"]:before,
[data-overlay-dark="0"] .hero-slider .slide:before,
[data-overlay-dark="0"]:before,
[data-overlay-light="0"] .hero-slider .slide:before,
[data-overlay-light="0"]:before {
    opacity: 0
}

button.close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    -o-transition: -o-transform .3s ease-out;
    transition: transform .3s ease-out;
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%)
}

.modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px
}

.modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5)
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000
}

.modal-backdrop.in,
[data-overlay-color="5"] .hero-slider .slide:before,
[data-overlay-color="5"]:before,
[data-overlay-dark="5"] .hero-slider .slide:before,
[data-overlay-dark="5"]:before,
[data-overlay-light="5"] .hero-slider .slide:before,
[data-overlay-light="5"]:before {
    opacity: .5
}

.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5
}

.modal-header .close {
    margin-top: -2px
}

.modal-title {
    margin: 0;
    line-height: 1.42857143
}

.modal-body {
    position: relative;
    padding: 15px
}

.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5
}

.modal-footer .btn+.btn {
    margin-bottom: 0;
    margin-left: 5px
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width:768px) {
    .navbar-text {
        float: left;
        margin-right: 15px;
        margin-left: 15px
    }

    .navbar-left {
        float: left !important
    }

    .navbar-right {
        float: right !important;
        margin-right: -15px
    }

    .navbar-right~.navbar-right {
        margin-right: 0
    }

    .modal-dialog {
        width: 600px;
        margin: 30px auto
    }

    .modal-content {
        -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
        box-shadow: 0 5px 15px rgba(0, 0, 0, .5)
    }

    .modal-sm {
        width: 300px
    }

    .subscribe-form-input {
        position: relative
    }

    .subscribe-form-email {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .subscribe-form-submit {
        position: absolute;
        top: 0;
        right: 0;
        width: 170px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-size: 12px;
    text-align: left;
    text-align: start
}

.connected-icons li a:hover i:after,
.phone-number:hover h1:after,
.tooltip.in,
[data-overlay-color="9"] .hero-slider .slide:before,
[data-overlay-color="9"]:before,
[data-overlay-dark="9"] .hero-slider .slide:before,
[data-overlay-dark="9"]:before,
[data-overlay-light="9"] .hero-slider .slide:before,
[data-overlay-light="9"]:before {
    opacity: .9
}

.tooltip.top {
    padding: 5px 0;
    margin-top: -3px
}

.tooltip.right {
    padding: 0 5px
}

.tooltip.bottom {
    padding: 5px 0;
    margin-top: 3px
}

.tooltip.left {
    padding: 0 5px;
    margin-left: -3px
}

.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 4px
}

.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid
}

.tooltip.top .tooltip-arrow,
.tooltip.top-left .tooltip-arrow,
.tooltip.top-right .tooltip-arrow {
    border-width: 5px 5px 0;
    border-top-color: #000;
    bottom: 0
}

.tooltip.top .tooltip-arrow {
    left: 50%;
    margin-left: -5px
}

.tooltip.top-left .tooltip-arrow {
    right: 5px;
    margin-bottom: -5px
}

.tooltip.top-right .tooltip-arrow {
    left: 5px;
    margin-bottom: -5px
}

.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000
}

.tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: #000
}

.tooltip.bottom .tooltip-arrow,
.tooltip.bottom-left .tooltip-arrow,
.tooltip.bottom-right .tooltip-arrow {
    border-width: 0 5px 5px;
    border-bottom-color: #000;
    top: 0
}

.tooltip.bottom .tooltip-arrow {
    left: 50%;
    margin-left: -5px
}

.tooltip.bottom-left .tooltip-arrow {
    right: 5px;
    margin-top: -5px
}

.tooltip.bottom-right .tooltip-arrow {
    left: 5px;
    margin-top: -5px
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    max-width: 276px;
    padding: 1px;
    font-size: 14px;
    text-align: left;
    text-align: start;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2)
}

.carousel-caption,
.carousel-control {
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
    text-align: center
}

.popover.top {
    margin-top: -10px
}

.ml10,
.popover.right {
    margin-left: 10px
}

.mt10,
.popover.bottom,
.quote-card .vossen-quote-2 {
    margin-top: 10px
}

.popover.left {
    margin-left: -10px
}

.popover-title {
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0
}

.popover-content {
    padding: 9px 14px
}

.popover>.arrow,
.popover>.arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid
}

.popover>.arrow {
    border-width: 11px
}

.popover>.arrow:after {
    content: "";
    border-width: 10px
}

.popover.top>.arrow {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: rgba(0, 0, 0, .25);
    border-bottom-width: 0
}

.popover.top>.arrow:after {
    bottom: 1px;
    margin-left: -10px;
    content: " ";
    border-top-color: #fff;
    border-bottom-width: 0
}

.popover.right>.arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-right-color: rgba(0, 0, 0, .25);
    border-left-width: 0
}

.popover.right>.arrow:after {
    bottom: -10px;
    left: 1px;
    content: " ";
    border-right-color: #fff;
    border-left-width: 0
}

.popover.bottom>.arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: rgba(0, 0, 0, .25)
}

.popover.bottom>.arrow:after {
    top: 1px;
    margin-left: -10px;
    content: " ";
    border-top-width: 0;
    border-bottom-color: #fff
}

.popover.left>.arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: rgba(0, 0, 0, .25)
}

.popover.left>.arrow:after {
    right: 1px;
    bottom: -10px;
    content: " ";
    border-right-width: 0;
    border-left-color: #fff
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner>.item {
    position: relative;
    display: none;
    -webkit-transition: left .6s ease-in-out;
    -o-transition: left .6s ease-in-out;
    transition: left .6s ease-in-out
}

@media all and (transform-3d),
(-webkit-transform-3d) {
    .carousel-inner>.item {
        -webkit-transition: -webkit-transform .6s ease-in-out;
        -o-transition: -o-transform .6s ease-in-out;
        transition: transform .6s ease-in-out;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000px;
        perspective: 1000px
    }

    .carousel-inner>.item.active.right,
    .carousel-inner>.item.next {
        left: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }

    .carousel-inner>.item.active.left,
    .carousel-inner>.item.prev {
        left: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }

    .carousel-inner>.item.active,
    .carousel-inner>.item.next.left,
    .carousel-inner>.item.prev.right {
        left: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.carousel-inner>.active,
.carousel-inner>.next.left,
.carousel-inner>.prev.right,
.mfp-arrow-left {
    left: 0
}

.carousel-inner>.next,
.carousel-inner>.prev {
    position: absolute;
    top: 0;
    width: 100%
}

.carousel-inner>.active.left,
.carousel-inner>.prev {
    left: -100%
}

.carousel-control {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 15%;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0);
    opacity: .5
}

.carousel-control.left {
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .5) 0, rgba(0, 0, 0, .0001) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, .5) 0, rgba(0, 0, 0, .0001) 100%);
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, .0001)));
    background-image: linear-gradient(to right, rgba(0, 0, 0, .5) 0, rgba(0, 0, 0, .0001) 100%);
    background-repeat: repeat-x
}

.carousel-control.right {
    right: 0;
    left: auto;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .0001) 0, rgba(0, 0, 0, .5) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, .0001) 0, rgba(0, 0, 0, .5) 100%);
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .0001)), to(rgba(0, 0, 0, .5)));
    background-image: linear-gradient(to right, rgba(0, 0, 0, .0001) 0, rgba(0, 0, 0, .5) 100%);
    background-repeat: repeat-x
}

.carousel-control:focus,
.carousel-control:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.mfp-wrap,
.panel-title:focus {
    outline: 0 !important
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next,
.carousel-control .icon-prev {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    margin-top: -10px
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .icon-prev {
    left: 50%;
    margin-left: -10px
}

.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next {
    right: 50%;
    margin-right: -10px
}

.carousel-control .icon-next,
.carousel-control .icon-prev {
    width: 20px;
    height: 20px;
    font-family: serif;
    line-height: 1
}

.carousel-control .icon-prev:before {
    content: '\2039'
}

.carousel-control .icon-next:before {
    content: '\203a'
}

.carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    padding-left: 0;
    margin-left: -30%;
    text-align: center;
    list-style: none
}

.carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #fff;
    border-radius: 10px
}

.carousel-indicators .active {
    width: 12px;
    height: 12px;
    margin: 0;
    background-color: #fff
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px
}

.carousel-caption .btn,
.text-hide {
    text-shadow: none
}

@media screen and (min-width:768px) {
    .jumbotron {
        padding-top: 48px;
        padding-bottom: 48px
    }

    .container .jumbotron,
    .container-fluid .jumbotron {
        padding-right: 60px;
        padding-left: 60px
    }

    .jumbotron .h1,
    .jumbotron h1 {
        font-size: 63px
    }

    .carousel-control .glyphicon-chevron-left,
    .carousel-control .glyphicon-chevron-right,
    .carousel-control .icon-next,
    .carousel-control .icon-prev {
        width: 30px;
        height: 30px;
        margin-top: -10px;
        font-size: 30px
    }

    .carousel-control .glyphicon-chevron-left,
    .carousel-control .icon-prev {
        margin-left: -10px
    }

    .carousel-control .glyphicon-chevron-right,
    .carousel-control .icon-next {
        margin-right: -10px
    }

    .carousel-caption {
        right: 20%;
        left: 20%;
        padding-bottom: 30px
    }

    .carousel-indicators {
        bottom: 20px
    }
}

/* .btn-group-vertical>.btn-group:after,
.btn-group-vertical>.btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
    display: table;
    content: " "
} */

/* .btn-group-vertical>.btn-group:after,
.btn-toolbar:after,
.clearfix:after,
.container-fluid:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.modal-footer:after,
.modal-header:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.pager:after,
.panel-body:after,
.row:after {
    clear: both
} */

.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto
}

.pull-right {
    float: right !important
}

.pull-left {
    float: left !important
}

.hidden,
.hide,
.mfp-hide,
.visible-lg,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-md,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-print,
.visible-print-block,
.visible-print-inline,
.visible-print-inline-block,
.visible-sm,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-xs,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block {
    display: none !important
}

.show {
    display: block !important
}

.invisible {
    visibility: hidden
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    background-color: transparent;
    border: 0
}

.affix {
    position: fixed
}

@-ms-viewport {
    width: device-width
}

@media (max-width:767px) {
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none
    }

    .navbar-nav .open .dropdown-menu .dropdown-header,
    .navbar-nav .open .dropdown-menu>li>a {
        padding: 5px 15px 5px 25px
    }

    .navbar-nav .open .dropdown-menu>li>a {
        line-height: 20px
    }

    .navbar-nav .open .dropdown-menu>li>a:focus,
    .navbar-nav .open .dropdown-menu>li>a:hover {
        background-image: none
    }

    .navbar-form .form-group {
        margin-bottom: 5px
    }

    .navbar-form .form-group:last-child {
        margin-bottom: 0
    }

    .navbar-default .navbar-nav .open .dropdown-menu>li>a {
        color: #777
    }

    .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus,
    .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover {
        color: #333;
        background-color: transparent
    }

    .navbar-default .navbar-nav .open .dropdown-menu>.active>a,
    .navbar-default .navbar-nav .open .dropdown-menu>.active>a:focus,
    .navbar-default .navbar-nav .open .dropdown-menu>.active>a:hover {
        color: #555;
        background-color: #e7e7e7
    }

    .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a,
    .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:focus,
    .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:hover {
        color: #ccc;
        background-color: transparent
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu>.dropdown-header {
        border-color: #080808
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
        background-color: #080808
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
        color: #9d9d9d
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:focus,
    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover {
        color: #fff;
        background-color: transparent
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:focus,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:hover {
        color: #fff;
        background-color: #080808
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:focus,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:hover {
        color: #444;
        background-color: transparent
    }

    .visible-xs,
    .visible-xs-block {
        display: block !important
    }

    table.visible-xs {
        display: table !important
    }

    tr.visible-xs {
        display: table-row !important
    }

    td.visible-xs,
    th.visible-xs {
        display: table-cell !important
    }

    .visible-xs-inline {
        display: inline !important
    }

    .visible-xs-inline-block {
        display: inline-block !important
    }

    .hidden-xs {
        display: none !important
    }
}

@media (min-width:768px) and (max-width:991px) {

    .visible-sm,
    .visible-sm-block {
        display: block !important
    }

    table.visible-sm {
        display: table !important
    }

    tr.visible-sm {
        display: table-row !important
    }

    td.visible-sm,
    th.visible-sm {
        display: table-cell !important
    }

    .visible-sm-inline {
        display: inline !important
    }

    .visible-sm-inline-block {
        display: inline-block !important
    }

    .hidden-sm {
        display: none !important
    }
}

@media (min-width:992px) and (max-width:1199px) {

    .visible-md,
    .visible-md-block {
        display: block !important
    }

    table.visible-md {
        display: table !important
    }

    tr.visible-md {
        display: table-row !important
    }

    td.visible-md,
    th.visible-md {
        display: table-cell !important
    }

    .visible-md-inline {
        display: inline !important
    }

    .visible-md-inline-block {
        display: inline-block !important
    }

    .hidden-md {
        display: none !important
    }
}

@media (min-width:1200px) {
    .container {
        width: 1170px
    }

    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9 {
        float: left
    }

    .col-lg-12 {
        width: 100%
    }

    .col-lg-11 {
        width: 91.66666667%
    }

    .col-lg-10 {
        width: 83.33333333%
    }

    .col-lg-9 {
        width: 75%
    }

    .col-lg-8 {
        width: 66.66666667%
    }

    .col-lg-7 {
        width: 58.33333333%
    }

    .col-lg-6 {
        width: 50%
    }

    .col-lg-5 {
        width: 41.66666667%
    }

    .col-lg-4 {
        width: 33.33333333%
    }

    .col-lg-3 {
        width: 25%
    }

    .col-lg-2 {
        width: 16.66666667%
    }

    .col-lg-1 {
        width: 8.33333333%
    }

    .col-lg-pull-12 {
        right: 100%
    }

    .col-lg-pull-11 {
        right: 91.66666667%
    }

    .col-lg-pull-10 {
        right: 83.33333333%
    }

    .col-lg-pull-9 {
        right: 75%
    }

    .col-lg-pull-8 {
        right: 66.66666667%
    }

    .col-lg-pull-7 {
        right: 58.33333333%
    }

    .col-lg-pull-6 {
        right: 50%
    }

    .col-lg-pull-5 {
        right: 41.66666667%
    }

    .col-lg-pull-4 {
        right: 33.33333333%
    }

    .col-lg-pull-3 {
        right: 25%
    }

    .col-lg-pull-2 {
        right: 16.66666667%
    }

    .col-lg-pull-1 {
        right: 8.33333333%
    }

    .col-lg-pull-0 {
        right: auto
    }

    .col-lg-push-12 {
        left: 100%
    }

    .col-lg-push-11 {
        left: 91.66666667%
    }

    .col-lg-push-10 {
        left: 83.33333333%
    }

    .col-lg-push-9 {
        left: 75%
    }

    .col-lg-push-8 {
        left: 66.66666667%
    }

    .col-lg-push-7 {
        left: 58.33333333%
    }

    .col-lg-push-6 {
        left: 50%
    }

    .col-lg-push-5 {
        left: 41.66666667%
    }

    .col-lg-push-4 {
        left: 33.33333333%
    }

    .col-lg-push-3 {
        left: 25%
    }

    .col-lg-push-2 {
        left: 16.66666667%
    }

    .col-lg-push-1 {
        left: 8.33333333%
    }

    .col-lg-push-0 {
        left: auto
    }

    .col-lg-offset-12 {
        margin-left: 100%
    }

    .col-lg-offset-11 {
        margin-left: 91.66666667%
    }

    .col-lg-offset-10 {
        margin-left: 83.33333333%
    }

    .col-lg-offset-9 {
        margin-left: 75%
    }

    .col-lg-offset-8 {
        margin-left: 66.66666667%
    }

    .col-lg-offset-7 {
        margin-left: 58.33333333%
    }

    .col-lg-offset-6 {
        margin-left: 50%
    }

    .col-lg-offset-5 {
        margin-left: 41.66666667%
    }

    .col-lg-offset-4 {
        margin-left: 33.33333333%
    }

    .col-lg-offset-3 {
        margin-left: 25%
    }

    .col-lg-offset-2 {
        margin-left: 16.66666667%
    }

    .col-lg-offset-1 {
        margin-left: 8.33333333%
    }

    .col-lg-offset-0 {
        margin-left: 0
    }

    .visible-lg,
    .visible-lg-block {
        display: block !important
    }

    table.visible-lg {
        display: table !important
    }

    tr.visible-lg {
        display: table-row !important
    }

    td.visible-lg,
    th.visible-lg {
        display: table-cell !important
    }

    .visible-lg-inline {
        display: inline !important
    }

    .visible-lg-inline-block {
        display: inline-block !important
    }

    .hidden-lg {
        display: none !important
    }
}

@media print {

    .visible-print,
    .visible-print-block {
        display: block !important
    }

    table.visible-print {
        display: table !important
    }

    tr.visible-print {
        display: table-row !important
    }

    td.visible-print,
    th.visible-print {
        display: table-cell !important
    }

    .visible-print-inline {
        display: inline !important
    }

    .visible-print-inline-block {
        display: inline-block !important
    }

    .hidden-print {
        display: none !important
    }
}


.owl-height {
    -webkit-transition: height .5s ease-in-out;
    -moz-transition: height .5s ease-in-out;
    -ms-transition: height .5s ease-in-out;
    -o-transition: height .5s ease-in-out;
    transition: height .5s ease-in-out
}

.owl-carousel {
    display: none;
    width: 100%;
    position: relative;
    z-index: 1
}

.mfp-bg,
.mfp-wrap {
    position: fixed;
    left: 0;
    top: 0
}

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0)
}

.owl-carousel .owl-controls .owl-dot,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-nav .owl-prev {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block
}

.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.owl-carousel.owl-text-select-on .owl-item {
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto
}

.owl-carousel .owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab
}

.owl-carousel.owl-rtl {
    direction: rtl
}

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    -webkit-transition: opacity .4s;
    -moz-transition: opacity .4s;
    -ms-transition: opacity .4s;
    -o-transition: opacity .4s;
    transition: opacity .4s
}

.owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000
}

.owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -webkit-transition: scale .1s;
    -moz-transition: scale .1s;
    -ms-transition: scale .1s;
    -o-transition: scale .1s;
    transition: scale .1s
}

.owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    -webkit-transition: opacity .4s;
    -moz-transition: opacity .4s;
    -ms-transition: opacity .4s;
    -o-transition: opacity .4s;
    transition: opacity .4s
}

.owl-carousel .owl-video-frame,
.parallax .slide {
    position: relative;
    z-index: 1
}


.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

.animated.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s
}

@-webkit-keyframes bounce {

    20%,
    53%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0)
    }

    70% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0)
    }
}

@keyframes bounce {

    20%,
    53%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0)
    }

    70% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0)
    }
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom
}

@-webkit-keyframes flash {

    50%,
    from,
    to {
        opacity: 1
    }

    25%,
    75% {
        opacity: 0
    }
}

@keyframes flash {

    50%,
    from,
    to {
        opacity: 1
    }

    25%,
    75% {
        opacity: 0
    }
}

.flash {
    -webkit-animation-name: flash;
    animation-name: flash
}

@-webkit-keyframes pulse {

    from,
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05)
    }
}

@keyframes pulse {

    from,
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05)
    }
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse
}

@-webkit-keyframes rubberBand {

    from,
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    30% {
        -webkit-transform: scale3d(1.25, .75, 1);
        transform: scale3d(1.25, .75, 1)
    }

    40% {
        -webkit-transform: scale3d(.75, 1.25, 1);
        transform: scale3d(.75, 1.25, 1)
    }

    50% {
        -webkit-transform: scale3d(1.15, .85, 1);
        transform: scale3d(1.15, .85, 1)
    }

    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1)
    }

    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1)
    }
}

@keyframes rubberBand {

    from,
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    30% {
        -webkit-transform: scale3d(1.25, .75, 1);
        transform: scale3d(1.25, .75, 1)
    }

    40% {
        -webkit-transform: scale3d(.75, 1.25, 1);
        transform: scale3d(.75, 1.25, 1)
    }

    50% {
        -webkit-transform: scale3d(1.15, .85, 1);
        transform: scale3d(1.15, .85, 1)
    }

    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1)
    }

    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1)
    }
}

.rubberBand {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand
}

@-webkit-keyframes shake {

    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }
}

@keyframes shake {

    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }
}

.shake {
    -webkit-animation-name: shake;
    animation-name: shake
}

@-webkit-keyframes headShake {

    0%,
    50% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    6.5% {
        -webkit-transform: translateX(-6px) rotateY(-9deg);
        transform: translateX(-6px) rotateY(-9deg)
    }

    18.5% {
        -webkit-transform: translateX(5px) rotateY(7deg);
        transform: translateX(5px) rotateY(7deg)
    }

    31.5% {
        -webkit-transform: translateX(-3px) rotateY(-5deg);
        transform: translateX(-3px) rotateY(-5deg)
    }

    43.5% {
        -webkit-transform: translateX(2px) rotateY(3deg);
        transform: translateX(2px) rotateY(3deg)
    }
}

@keyframes headShake {

    0%,
    50% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    6.5% {
        -webkit-transform: translateX(-6px) rotateY(-9deg);
        transform: translateX(-6px) rotateY(-9deg)
    }

    18.5% {
        -webkit-transform: translateX(5px) rotateY(7deg);
        transform: translateX(5px) rotateY(7deg)
    }

    31.5% {
        -webkit-transform: translateX(-3px) rotateY(-5deg);
        transform: translateX(-3px) rotateY(-5deg)
    }

    43.5% {
        -webkit-transform: translateX(2px) rotateY(3deg);
        transform: translateX(2px) rotateY(3deg)
    }
}

.headShake {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-name: headShake;
    animation-name: headShake
}

@-webkit-keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform: rotate3d(0, 0, 1, 15deg)
    }

    40% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg)
    }

    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg)
    }

    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }
}

@keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform: rotate3d(0, 0, 1, 15deg)
    }

    40% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg)
    }

    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg)
    }

    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }
}

.swing {
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing
}

@-webkit-keyframes tada {

    from,
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    10%,
    20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
    }
}

@keyframes tada {

    from,
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    10%,
    20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
    }
}

.tada {
    -webkit-animation-name: tada;
    animation-name: tada
}

@-webkit-keyframes wobble {

    from,
    to {
        -webkit-transform: none;
        transform: none
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)
    }

    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)
    }

    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)
    }

    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)
    }
}

@keyframes wobble {

    from,
    to {
        -webkit-transform: none;
        transform: none
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)
    }

    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)
    }

    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)
    }

    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)
    }
}

.wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble
}

@-webkit-keyframes jello {

    11.1%,
    from,
    to {
        -webkit-transform: none;
        transform: none
    }

    22.2% {
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
        transform: skewX(-12.5deg) skewY(-12.5deg)
    }

    33.3% {
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
        transform: skewX(6.25deg) skewY(6.25deg)
    }

    44.4% {
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
        transform: skewX(-3.125deg) skewY(-3.125deg)
    }

    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg)
    }

    66.6% {
        -webkit-transform: skewX(-.78125deg) skewY(-.78125deg);
        transform: skewX(-.78125deg) skewY(-.78125deg)
    }

    77.7% {
        -webkit-transform: skewX(.390625deg) skewY(.390625deg);
        transform: skewX(.390625deg) skewY(.390625deg)
    }

    88.8% {
        -webkit-transform: skewX(-.1953125deg) skewY(-.1953125deg);
        transform: skewX(-.1953125deg) skewY(-.1953125deg)
    }
}

@keyframes jello {

    11.1%,
    from,
    to {
        -webkit-transform: none;
        transform: none
    }

    22.2% {
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
        transform: skewX(-12.5deg) skewY(-12.5deg)
    }

    33.3% {
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
        transform: skewX(6.25deg) skewY(6.25deg)
    }

    44.4% {
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
        transform: skewX(-3.125deg) skewY(-3.125deg)
    }

    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg)
    }

    66.6% {
        -webkit-transform: skewX(-.78125deg) skewY(-.78125deg);
        transform: skewX(-.78125deg) skewY(-.78125deg)
    }

    77.7% {
        -webkit-transform: skewX(.390625deg) skewY(.390625deg);
        transform: skewX(.390625deg) skewY(.390625deg)
    }

    88.8% {
        -webkit-transform: skewX(-.1953125deg) skewY(-.1953125deg);
        transform: skewX(-.1953125deg) skewY(-.1953125deg)
    }
}

.jello {
    -webkit-animation-name: jello;
    animation-name: jello;
    -webkit-transform-origin: center;
    transform-origin: center
}

@-webkit-keyframes bounceIn {

    20%,
    40%,
    60%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000)
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    20% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    40% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }

    60% {
        opacity: .5;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    80% {
        -webkit-transform: scale3d(.97, .97, .97);
        transform: scale3d(.97, .97, .97)
    }

    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

@keyframes bounceIn {

    20%,
    40%,
    60%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000)
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    20%,
    60% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    40% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }

    80% {
        -webkit-transform: scale3d(.97, .97, .97);
        transform: scale3d(.97, .97, .97)
    }

    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

.bounceIn {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn
}

@-webkit-keyframes bounceInDown {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000)
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0)
    }

    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes bounceInDown {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000)
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0)
    }

    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

.bounceInDown {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown
}

@-webkit-keyframes bounceInLeft {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000)
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
        transform: translate3d(-3000px, 0, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
        transform: translate3d(25px, 0, 0)
    }

    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }

    90% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes bounceInLeft {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000)
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
        transform: translate3d(-3000px, 0, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
        transform: translate3d(25px, 0, 0)
    }

    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }

    90% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

.bounceInLeft {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft
}

@-webkit-keyframes bounceInRight {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000)
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
        transform: translate3d(3000px, 0, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0)
    }

    75% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }

    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes bounceInRight {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000)
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
        transform: translate3d(3000px, 0, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0)
    }

    75% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }

    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

.bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight
}

@-webkit-keyframes bounceInUp {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000)
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0);
        transform: translate3d(0, 3000px, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }

    75% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0)
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes bounceInUp {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000)
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0);
        transform: translate3d(0, 3000px, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }

    75% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0)
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.bounceInUp {
    -webkit-animation-name: bounceInUp;
    animation-name: bounceInUp
}

@-webkit-keyframes bounceOut {
    20% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }

    50%,
    55% {
        opacity: 1;
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
}

@keyframes bounceOut {
    20% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }

    50%,
    55% {
        opacity: 1;
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
}

.bounceOut {
    -webkit-animation-name: bounceOut;
    animation-name: bounceOut
}

@-webkit-keyframes bounceOutDown {
    20% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0)
    }

    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
}

@keyframes bounceOutDown {
    20% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0)
    }

    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
}

.bounceOutDown {
    -webkit-animation-name: bounceOutDown;
    animation-name: bounceOutDown
}

@-webkit-keyframes bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
}

@keyframes bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
}

.bounceOutLeft {
    -webkit-animation-name: bounceOutLeft;
    animation-name: bounceOutLeft
}

@-webkit-keyframes bounceOutRight {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0);
        transform: translate3d(-20px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
}

@keyframes bounceOutRight {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0);
        transform: translate3d(-20px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
}

.bounceOutRight {
    -webkit-animation-name: bounceOutRight;
    animation-name: bounceOutRight
}

@-webkit-keyframes bounceOutUp {
    20% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0)
    }

    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
}

@keyframes bounceOutUp {
    20% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0)
    }

    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
}

.bounceOutUp {
    -webkit-animation-name: bounceOutUp;
    animation-name: bounceOutUp
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown
}

@-webkit-keyframes fadeInDownBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInDownBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInDownBig {
    -webkit-animation-name: fadeInDownBig;
    animation-name: fadeInDownBig
}

@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft
}

@-webkit-keyframes fadeInLeftBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInLeftBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInLeftBig {
    -webkit-animation-name: fadeInLeftBig;
    animation-name: fadeInLeftBig
}

@-webkit-keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight
}

@-webkit-keyframes fadeInRightBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInRightBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInRightBig {
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
}

@-webkit-keyframes fadeInUpBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInUpBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInUpBig {
    -webkit-animation-name: fadeInUpBig;
    animation-name: fadeInUpBig
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes fadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut
}

@-webkit-keyframes fadeOutDown {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

@keyframes fadeOutDown {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown
}

@-webkit-keyframes fadeOutDownBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
}

@keyframes fadeOutDownBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
}

.fadeOutDownBig {
    -webkit-animation-name: fadeOutDownBig;
    animation-name: fadeOutDownBig
}

@-webkit-keyframes fadeOutLeft {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

@keyframes fadeOutLeft {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

.fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft
}

@-webkit-keyframes fadeOutLeftBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
}

@keyframes fadeOutLeftBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
}

.fadeOutLeftBig {
    -webkit-animation-name: fadeOutLeftBig;
    animation-name: fadeOutLeftBig
}

@-webkit-keyframes fadeOutRight {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

@keyframes fadeOutRight {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

.fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight
}

@-webkit-keyframes fadeOutRightBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
}

@keyframes fadeOutRightBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
}

.fadeOutRightBig {
    -webkit-animation-name: fadeOutRightBig;
    animation-name: fadeOutRightBig
}

@-webkit-keyframes fadeOutUp {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp
}

@-webkit-keyframes fadeOutUpBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
}

@keyframes fadeOutUpBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
}

.fadeOutUpBig {
    -webkit-animation-name: fadeOutUpBig;
    animation-name: fadeOutUpBig
}

@-webkit-keyframes flip {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    40% {
        -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    50% {
        -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    80% {
        -webkit-transform: perspective(400px) scale3d(.95, .95, .95);
        transform: perspective(400px) scale3d(.95, .95, .95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@keyframes flip {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    40% {
        -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    50% {
        -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    80% {
        -webkit-transform: perspective(400px) scale3d(.95, .95, .95);
        transform: perspective(400px) scale3d(.95, .95, .95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

.animated.flip {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-animation-name: flip;
    animation-name: flip
}

@-webkit-keyframes flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

@keyframes flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

.flipInX {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInX;
    animation-name: flipInX
}

.flipInY,
.flipOutX {
    -webkit-backface-visibility: visible !important
}

@-webkit-keyframes flipInY {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

@keyframes flipInY {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

.flipInY {
    backface-visibility: visible !important;
    -webkit-animation-name: flipInY;
    animation-name: flipInY
}

@-webkit-keyframes flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0
    }
}

@keyframes flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0
    }
}

.flipOutX {
    -webkit-animation-name: flipOutX;
    animation-name: flipOutX;
    backface-visibility: visible !important
}

@-webkit-keyframes flipOutY {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        opacity: 1
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        opacity: 0
    }
}

@keyframes flipOutY {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        opacity: 1
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        opacity: 0
    }
}

.flipOutY {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipOutY;
    animation-name: flipOutY
}

@-webkit-keyframes lightSpeedIn {
    from {
        -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
        transform: translate3d(100%, 0, 0) skewX(-30deg);
        opacity: 0
    }

    60% {
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg);
        opacity: 1
    }

    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg);
        opacity: 1
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes lightSpeedIn {
    from {
        -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
        transform: translate3d(100%, 0, 0) skewX(-30deg);
        opacity: 0
    }

    60% {
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg);
        opacity: 1
    }

    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg);
        opacity: 1
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.lightSpeedIn {
    -webkit-animation-name: lightSpeedIn;
    animation-name: lightSpeedIn;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
}

@-webkit-keyframes lightSpeedOut {
    from {
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
        transform: translate3d(100%, 0, 0) skewX(30deg);
        opacity: 0
    }
}

@keyframes lightSpeedOut {
    from {
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
        transform: translate3d(100%, 0, 0) skewX(30deg);
        opacity: 0
    }
}

.lightSpeedOut {
    -webkit-animation-name: lightSpeedOut;
    animation-name: lightSpeedOut;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
}

@-webkit-keyframes rotateIn {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, -200deg);
        transform: rotate3d(0, 0, 1, -200deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateIn {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, -200deg);
        transform: rotate3d(0, 0, 1, -200deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateIn {
    -webkit-animation-name: rotateIn;
    animation-name: rotateIn
}

@-webkit-keyframes rotateInDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateInDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateInDownLeft {
    -webkit-animation-name: rotateInDownLeft;
    animation-name: rotateInDownLeft
}

@-webkit-keyframes rotateInDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateInDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateInDownRight {
    -webkit-animation-name: rotateInDownRight;
    animation-name: rotateInDownRight
}

@-webkit-keyframes rotateInUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateInUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateInUpLeft {
    -webkit-animation-name: rotateInUpLeft;
    animation-name: rotateInUpLeft
}

@-webkit-keyframes rotateInUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateInUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateInUpRight {
    -webkit-animation-name: rotateInUpRight;
    animation-name: rotateInUpRight
}

@-webkit-keyframes rotateOut {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        opacity: 1
    }

    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, 200deg);
        transform: rotate3d(0, 0, 1, 200deg);
        opacity: 0
    }
}

@keyframes rotateOut {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        opacity: 1
    }

    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, 200deg);
        transform: rotate3d(0, 0, 1, 200deg);
        opacity: 0
    }
}

.rotateOut {
    -webkit-animation-name: rotateOut;
    animation-name: rotateOut
}

@-webkit-keyframes rotateOutDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }
}

@keyframes rotateOutDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }
}

.rotateOutDownLeft {
    -webkit-animation-name: rotateOutDownLeft;
    animation-name: rotateOutDownLeft
}

@-webkit-keyframes rotateOutDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
}

@keyframes rotateOutDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
}

.rotateOutDownRight {
    -webkit-animation-name: rotateOutDownRight;
    animation-name: rotateOutDownRight
}

@-webkit-keyframes rotateOutUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
}

@keyframes rotateOutUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
}

.rotateOutUpLeft {
    -webkit-animation-name: rotateOutUpLeft;
    animation-name: rotateOutUpLeft
}

@-webkit-keyframes rotateOutUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg);
        opacity: 0
    }
}

@keyframes rotateOutUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg);
        opacity: 0
    }
}

.rotateOutUpRight {
    -webkit-animation-name: rotateOutUpRight;
    animation-name: rotateOutUpRight
}

@-webkit-keyframes hinge {
    0% {
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    20%,
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 80deg);
        transform: rotate3d(0, 0, 1, 80deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    40%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 60deg);
        transform: rotate3d(0, 0, 1, 60deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(0, 700px, 0);
        transform: translate3d(0, 700px, 0);
        opacity: 0
    }
}

@keyframes hinge {
    0% {
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    20%,
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 80deg);
        transform: rotate3d(0, 0, 1, 80deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    40%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 60deg);
        transform: rotate3d(0, 0, 1, 60deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(0, 700px, 0);
        transform: translate3d(0, 700px, 0);
        opacity: 0
    }
}

.hinge {
    -webkit-animation-name: hinge;
    animation-name: hinge
}

@-webkit-keyframes rollIn {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
        transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes rollIn {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
        transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.rollIn {
    -webkit-animation-name: rollIn;
    animation-name: rollIn
}

@-webkit-keyframes rollOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
        transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg)
    }
}

@keyframes rollOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
        transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg)
    }
}

.rollOut {
    -webkit-animation-name: rollOut;
    animation-name: rollOut
}

@-webkit-keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    50% {
        opacity: 1
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    50% {
        opacity: 1
    }
}

.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn
}

@-webkit-keyframes zoomInDown {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
        animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1)
    }
}

@keyframes zoomInDown {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
        animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1)
    }
}

.zoomInDown {
    -webkit-animation-name: zoomInDown;
    animation-name: zoomInDown
}

@-webkit-keyframes zoomInLeft {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
        transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
        animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1)
    }
}

@keyframes zoomInLeft {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
        transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
        animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1)
    }
}

.zoomInLeft {
    -webkit-animation-name: zoomInLeft;
    animation-name: zoomInLeft
}

@-webkit-keyframes zoomInRight {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
        transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
        animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1)
    }
}

@keyframes zoomInRight {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
        transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
        animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1)
    }
}

.zoomInRight {
    -webkit-animation-name: zoomInRight;
    animation-name: zoomInRight
}

@-webkit-keyframes zoomInUp {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
        animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1)
    }
}

@keyframes zoomInUp {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
        animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1)
    }
}

.zoomInUp {
    -webkit-animation-name: zoomInUp;
    animation-name: zoomInUp
}

@-webkit-keyframes zoomOut {
    from {
        opacity: 1
    }

    50% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    to {
        opacity: 0
    }
}

@keyframes zoomOut {
    from {
        opacity: 1
    }

    50% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    to {
        opacity: 0
    }
}

.zoomOut {
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut
}

@-webkit-keyframes zoomOutDown {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
        animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1)
    }
}

@keyframes zoomOutDown {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
        animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1)
    }
}

.zoomOutDown {
    -webkit-animation-name: zoomOutDown;
    animation-name: zoomOutDown
}

@-webkit-keyframes zoomOutLeft {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: scale(.1) translate3d(-2000px, 0, 0);
        transform: scale(.1) translate3d(-2000px, 0, 0);
        -webkit-transform-origin: left center;
        transform-origin: left center
    }
}

@keyframes zoomOutLeft {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: scale(.1) translate3d(-2000px, 0, 0);
        transform: scale(.1) translate3d(-2000px, 0, 0);
        -webkit-transform-origin: left center;
        transform-origin: left center
    }
}

.zoomOutLeft {
    -webkit-animation-name: zoomOutLeft;
    animation-name: zoomOutLeft
}

@-webkit-keyframes zoomOutRight {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: scale(.1) translate3d(2000px, 0, 0);
        transform: scale(.1) translate3d(2000px, 0, 0);
        -webkit-transform-origin: right center;
        transform-origin: right center
    }
}

@keyframes zoomOutRight {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: scale(.1) translate3d(2000px, 0, 0);
        transform: scale(.1) translate3d(2000px, 0, 0);
        -webkit-transform-origin: right center;
        transform-origin: right center
    }
}

.zoomOutRight {
    -webkit-animation-name: zoomOutRight;
    animation-name: zoomOutRight
}

@-webkit-keyframes zoomOutUp {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
        animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1)
    }
}

@keyframes zoomOutUp {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
        animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1)
    }
}

.zoomOutUp {
    -webkit-animation-name: zoomOutUp;
    animation-name: zoomOutUp
}

@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown
}

@-webkit-keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft
}

@-webkit-keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight
}

@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp
}

@-webkit-keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

@keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

.slideOutDown {
    -webkit-animation-name: slideOutDown;
    animation-name: slideOutDown
}

@-webkit-keyframes slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

@keyframes slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

.slideOutLeft {
    -webkit-animation-name: slideOutLeft;
    animation-name: slideOutLeft
}

@-webkit-keyframes slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

@keyframes slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

.slideOutRight {
    -webkit-animation-name: slideOutRight;
    animation-name: slideOutRight
}

@-webkit-keyframes slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

@keyframes slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

.slideOutUp {
    -webkit-animation-name: slideOutUp;
    animation-name: slideOutUp
}

/*!
 * Magnific Popup v1.1.0
 */
.mfp-bg {
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    background: #0b0b0b;
    opacity: .8
}

.mfp-wrap {
    width: 100%;
    height: 100%;
    z-index: 1043;
    -webkit-backface-visibility: hidden
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box
}

.mfp-container:before {
    content: '';
    height: 100%
}

.mfp-content {
    position: relative;
    margin: 0 auto;
    text-align: left;
    z-index: 1045
}

.mfp-ajax-holder .mfp-content,
.mfp-inline-holder .mfp-content {
    width: 100%;
    cursor: auto
}

.mfp-ajax-cur {
    cursor: progress
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in
}

.mfp-auto-cursor .mfp-content {
    cursor: auto
}

.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.mfp-preloader {
    color: #ccc;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -.8em;
    left: 8px;
    right: 8px;
    z-index: 1044
}

button.mfp-arrow,
button.mfp-close {
    overflow: visible;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: 0;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation
}

.mfp-figure:after,
.mfp-iframe-scaler iframe {
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    position: absolute;
    left: 0
}

button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: .65;
    padding: 0 0 18px 10px;
    color: #fff;
    font-style: normal;
    font-family: Arial, Baskerville, monospace
}

.btn,
.progress-circle:before {
    font-family: Montserrat, sans-serif
}

.mfp-close:active {
    top: 1px
}

.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
    color: #fff;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #ccc;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap
}

.mfp-figure,
img.mfp-img {
    line-height: 0
}

.mfp-arrow {
    position: absolute;
    opacity: .65;
    margin: -55px 0 0;
    top: 50%;
    padding: 0;
    width: 90px;
    height: 110px
}

.mfp-arrow:active {
    margin-top: -54px
}

.mfp-arrow:after,
.mfp-arrow:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: inset transparent
}

.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px
}

.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: .7
}

.mfp-arrow-left:after {
    border-right: 17px solid #fff;
    margin-left: 31px
}

.mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3f3f3f
}

.mfp-arrow-right {
    right: 0
}

.mfp-arrow-right:after {
    border-left: 17px solid #fff;
    margin-left: 39px
}

.mfp-arrow-right:before {
    border-left: 27px solid #3f3f3f
}

.blog-post,
.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px
}

.mfp-iframe-holder .mfp-close {
    top: -40px
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%
}

.mfp-iframe-scaler iframe {
    display: block;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000
}

.mfp-figure:after,
img.mfp-img {
    width: auto;
    height: auto;
    display: block
}

img.mfp-img {
    max-width: 100%;
    box-sizing: border-box;
    padding: 40px 0;
    margin: 0 auto
}

.mfp-figure:after {
    content: '';
    top: 40px;
    bottom: 40px;
    right: 0;
    z-index: -1;
    background: #444;
    box-shadow: 0 0 80px rgba(0, 0, 0, .9)
}

.mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #f3f3f3;
    word-wrap: break-word;
    padding-right: 36px
}

@media screen and (max-width:800px) and (orientation:landscape),
screen and (max-height:300px) {
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0
    }

    .mfp-img-mobile .mfp-bottom-bar:empty,
    .mfp-img-mobile img.mfp-img {
        padding: 0
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, .6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        box-sizing: border-box
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px
    }

    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, .6);
        position: fixed;
        text-align: center;
        padding: 0
    }
}

@media all and (max-width:900px) {
    .mfp-arrow {
        -webkit-transform: scale(.75);
        transform: scale(.75)
    }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0
    }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px
    }
}

.mfp-zoom-in .mfp-with-anim {
    opacity: 0;
    -moz-transition: transform .3s, opacity .3s;
    -webkit-transition: transform .3s, opacity .3s;
    transition: transform .3s, opacity .3s;
    transform: scale(.8);
    -webkit-transform: scale(.8);
    -moz-transform: scale(.8)
}

.mfp-zoom-in.mfp-bg,
.mfp-zoom-out.mfp-bg {
    opacity: 0;
    -moz-transition: transform .3s;
    -webkit-transition: transform .3s;
    transition: transform .3s
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1)
}

.mfp-zoom-in.mfp-ready.mfp-bg,
.mfp-zoom-out.mfp-ready.mfp-bg,
.portfolio-item:hover .item-image:after,
[data-overlay-color="8"] .hero-slider .slide:before,
[data-overlay-color="8"]:before,
[data-overlay-dark="8"] .hero-slider .slide:before,
[data-overlay-dark="8"]:before,
[data-overlay-light="8"] .hero-slider .slide:before,
[data-overlay-light="8"]:before {
    opacity: .8
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
    transform: scale(.9);
    -webkit-transform: scale(.9);
    -moz-transform: scale(.9);
    opacity: 0
}

.mfp-zoom-out .mfp-with-anim {
    opacity: 0;
    -moz-transition: transform .3s;
    -webkit-transition: transform .3s;
    transition: transform .3s;
    transform: scale(1.3);
    -moz-transform: scale(1.3);
    -webkit-transform: scale(1.3)
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1)
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0
}

@media (min-width:1100px) {
    .mfp-container {
        padding-left: 26px;
        padding-right: 26px
    }

    .mfp-iframe-holder .mfp-close,
    .mfp-image-holder .mfp-close {
        right: -26px
    }
}

button.mfp-arrow,
button.mfp-close {
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    transition: opacity .3s
}

.mfp-arrow-right img {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg)
}

.mfp-close {
    font-size: 22px
}

.mfp-close:after {
    content: "\f2d7";
    font-family: Ionicons;
    font-size: 22px;
    cursor: pointer
}

::selection {
    background: rgba(0, 0, 0, .16)
}

::-moz-selection {
    background: rgba(0, 0, 0, .16)
}

a {
    text-decoration: none;
    color: #828282;
    -webkit-transition: color .3s;
    -moz-transition: color .3s;
    transition: color .3s, opacity .5s
}

[data-fade-in=true] {
    -moz-animation: 1s fadein;
    -webkit-animation: 1s fadein;
    -o-animation: 1s fadein;
    animation: 1s fadein
}

@keyframes fadein {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-moz-keyframes fadein {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes fadein {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-o-keyframes fadein {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.pre-loader {
    background-color: #fff;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    opacity: 1;
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    transition: opacity .3s
}

.pre-loader div {
    margin: -25px auto 0;
    text-indent: -9999em;
    top: 50%;
    position: relative;
    display: block;
    border-top: 2px solid rgba(0, 0, 0, .2);
    border-right: 2px solid rgba(0, 0, 0, .2);
    border-bottom: 2px solid rgba(0, 0, 0, .2);
    border-left: 2px solid #111;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateY(50%);
    -webkit-animation: 1.1s linear infinite load9;
    animation: 1.1s linear infinite load9
}

.pre-loader div,
.pre-loader div:after {
    border-radius: 50%;
    width: 40px;
    height: 40px
}

@-webkit-keyframes load9 {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes load9 {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-family);
    color: #191a1c;
    font-weight: 200
}

.bold,
.connected-icons li a h5,
a strong,
h1 strong,
h2 strong,
h3 strong,
h4 strong,
h5 strong,
h6 strong {
    font-family: var(--font-family);
    font-weight: 700
}

h1 {
    font-weight: 100;
    letter-spacing: -2px;
    margin: 10px 0
}

h2,
h3 {
    font-weight: 200
}

h1 strong {
    font-size: 72px
}

h2 {
    font-size: 50px;
    line-height: 1;
    letter-spacing: -.05em
}

.team>h5,
h3,
h4 strong,
h5 strong {
    letter-spacing: -1px
}

h3 {
    font-size: 30px
}

.lead,
h4 {
    font-size: 20px;
    font-weight: 300
}

h4 strong {
    font-size: 22px
}

h5 {
    font-size: 16px;
    line-height: 1.2
}

h5 strong {
    font-size: 18px
}

h6 {
    font-size: 13px
}

p {
    font-size: 15px;
    line-height: 1.75;
    font-weight: 300;
    color: #888
}

[data-overlay-dark] p {
    font-weight: 400
}

.lead {
    line-height: 1.4
}

.testimonials.white h4,
[data-overlay-dark] p.lead {
    font-weight: 300
}

@media all and (max-width:992px) {
    h1 {
        font-size: 50px
    }

    h1 strong {
        font-size: 56px
    }

    h2 {
        font-size: 40px
    }

    h2 strong {
        font-size: 46px
    }
}

@media all and (max-width:768px) {

    h1,
    h2 strong {
        font-size: 36px
    }

    h1 strong {
        font-size: 42px
    }

    h2 {
        font-size: 30px
    }
}

#elements-buttons .btn,
.mb10,
[data-gap="10"] .vossen-portfolio-filters,
blockquote,
ol,
p,
pre,
table,
ul {
    margin-bottom: 10px
}

ul.bullets {
    list-style: inside
}

#iconTabs,
.blog-pagination,
.connected-icons,
.team-caption ul,
ul {
    list-style: none
}

blockquote {
    font-size: 18px;
    line-height: 1.7;
    font-style: italic;
    padding: 10px 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    color: #999;
    border-left: 2px solid
}

strong {
    font-weight: 600
}

hr {
    margin: 0;
    border-color: #f4f4f4
}

a:active,
a:focus,
a:hover {
    text-decoration: none;
    outline: 0
}

.size-12x {
    font-size: 12em !important
}

.size-8x {
    font-size: 8em !important
}

.size-7x {
    font-size: 7em !important
}

.size-6x {
    font-size: 6em !important
}

.size-5x {
    font-size: 5em !important
}

.size-4x {
    font-size: 4em !important
}

.size-3x {
    font-size: 3em !important
}

.size-2x {
    font-size: 2.5em !important
}

.size-1x {
    font-size: 1.5em !important
}

.hero-fullscreen {
    position: relative;
    height: 100vh;
    padding: 114px 0;
    overflow: hidden
}

.hero-fullscreen .container {
    z-index: 2;
    position: relative;
    margin-top: 100px;
    /* top: 50%; */
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%)
}

.hero-fullwidth {
    position: relative;
    height: auto;
    padding: 100px 0;
    overflow: hidden
}

.hero-fullwidth .container {
    padding-top: 60px;
    z-index: 2
}

.hero-fullwidth.parallax>.background-image {
    height: 100%;
    top: 0
}

.hero-slider {
    position: relative;
    top: 0;
    background-color: #191a1c;
    z-index: 1
}

.hero-slider .slide {
    position: relative;
    height: 100%
}

.owl-item .appear {
    opacity: 0;
    padding: 114px 40px;
    -webkit-transform: translateY(60px);
    -moz-transform: translateY(60px);
    transform: translateY(60px);
    -webkit-transition: transform .5s 0.5s, opacity .5s 0.5s;
    -moz-transition: transform .5s 0.5s, opacity .5s 0.5s;
    -o-transition: transform .5s, opacity .5s;
    -ms-transition: transform .5s, opacity .5s;
    transition: transform .5s 0.5s, opacity .5s 0.5s
}

.owl-item.active .appear {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0)
}

.slider-fullwidth .owl-next,
.slider-fullwidth .owl-prev,
.vossen-gallery .item-caption i:before {
    margin-top: 22px
}

#vossen-youtube {
    position: absolute;
    overflow: hidden;
    background-color: #0f1112;
    top: 50%;
    left: 50% !important;
    z-index: 0;
    width: 100%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translate3d(-50%, -50%, 0);
    -moz-transform: translate3d(-50%, -50%, 0);
    -o-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0)
}

.owl-dot {
    display: inline-block;
    padding: 6px 2px;
    position: relative
}

.owl-theme .owl-controls .owl-dot span {
    width: 4px;
    height: 4px;
    margin: 2px;
    display: block;
    background: rgba(255, 255, 255, .8);
    position: relative;
    z-index: 1000 !important;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-shadow: none;
    -webkit-transition: background .27s;
    -moz-transition: background .27s;
    -o-transition: background .27s;
    -ms-transition: background .27s;
    transition: background .27s
}

[data-overlay-color] .owl-theme .owl-controls .owl-dot span,
[data-overlay-dark] .owl-theme .owl-controls .owl-dot span {
    background: rgba(255, 255, 255, .3)
}

.owl-theme .owl-controls .owl-dot.active span,
[data-overlay-color] .owl-theme .owl-controls .owl-dot.active span,
[data-overlay-dark] .owl-theme .owl-controls .owl-dot.active span,
[data-overlay-light] .hero-slider .slide:before,
[data-overlay-light]:before {
    background: #fff
}

.dark-pagination.owl-theme .owl-controls .owl-dot span,
[data-overlay-light] .owl-theme .owl-controls .owl-dot span {
    background: rgba(0, 0, 0, .3)
}

.dark-pagination.owl-theme .owl-controls .owl-dot.active span,
[data-overlay-light] .owl-theme .owl-controls .owl-dot.active span {
    background: rgba(0, 0, 0, .8)
}

.owl-theme .owl-controls .owl-nav div {
    position: absolute;
    color: #fff;
    display: inline-block;
    padding: 20px;
    font-size: 42px;
    border-radius: 0;
    background: 0 0;
    opacity: 0;
    -webkit-transition: opacity .3s, margin .3s;
    -moz-transition: opacity .3s, margin .3s;
    -o-transition: opacity .3s, margin .3s;
    -ms-transition: opacity .3s, margin .3s;
    transition: opacity .3s, margin .3s
}

.ml40,
.owl-prev {
    margin-left: 40px
}

.mr40,
.owl-next {
    margin-right: 40px
}

.mr0,
.owl-theme:hover .owl-next {
    margin-right: 0
}

.owl-theme .owl-controls .owl-nav div.owl-next:hover,
.owl-theme .owl-controls .owl-nav div.owl-prev:hover,
[data-overlay-color="7"] .hero-slider .slide:before,
[data-overlay-color="7"]:before,
[data-overlay-dark="7"] .hero-slider .slide:before,
[data-overlay-dark="7"]:before,
[data-overlay-light="7"] .hero-slider .slide:before,
[data-overlay-light="7"]:before {
    opacity: .7
}

.owl-theme .owl-controls .owl-nav .owl-prev {
    left: 0;
    top: 50%;
    transform: translateY(-52%);
    -moz-transform: translateY(-52%);
    -webkit-transform: translateY(-52%)
}

.owl-theme .owl-controls .owl-nav .owl-next {
    right: 0;
    top: 50%;
    transform: translateY(-52%);
    -moz-transform: translateY(-52%);
    -webkit-transform: translateY(-52%)
}

.owl-carousel .owl-item {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab
}

.owl-carousel .owl-item:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing
}

[data-touch-drag=false] .owl-item,
[data-touch-drag=false] .owl-item:active {
    cursor: default;
    cursor: -moz-default;
    cursor: -webkit-default
}

.carousel .owl-dots {
    margin-top: -30px
}

.parallax,
.team-container {
    position: relative;
    overflow: hidden
}

.parallax .container,
[data-overlay-color] .container,
[data-overlay-dark] .container,
[data-overlay-light] .container {
    position: relative;
    z-index: 2
}

.parallax>.background-image {
    height: 100vh;
    top: -50vh;
    -webkit-transition: opacity .9s;
    -moz-transition: opacity .9s;
    transition: opacity .9s;
    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d
}

@media all and (max-width:1024px) {

    .parallax .slides li>.background-image,
    .parallax>.background-image {
        top: 0 !important;
        -webkit-transform: none !important;
        -moz-transform: none !important;
        transform: none !important;
        height: 100%
    }
}

.background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover !important;
    background-position: 50% 50% !important;
    z-index: 0;
    background-color: var(--main-bg-color);
    transition: opacity .3s;
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s
}

.hero-content-slider {
    text-align: center;
    padding: 0 20px;
    opacity: 0;
    -webkit-transition: opacity 1.3s;
    -moz-transition: opacity 1.3s;
    transition: opacity 1.3s
}

.form-leave-comment form,
.hero-content-slider a.btn,
.mt30 {
    margin-top: 30px
}

.hero-content-slider .owl-item {
    top: 50%
}

.hero-content-slider .owl-animated-in {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    -ms-animation-delay: 1s;
    animation-delay: 1s
}

.hero-content-slider .owl-item h1,
.hero-content-slider .owl-item h5,
.hero-content-slider .owl-item p {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.animated.bounceIn,
.animated.bounceOut,
.animated.flipOutX,
.animated.flipOutY {
    -webkit-animation-duration: .75s;
    animation-duration: .6s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.quote-slider i,
.testimonials i,
.testimonials.quote-icons i {
    -webkit-backface-visibility: hidden
}

.btn {
    font-size: 11px;
    font-weight: 700;
    color: #35383c;
    background-color: transparent;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 1px;
    padding: 8px 10px 7px;
    margin: 5px 10px;
    border: 2px solid rgba(35, 35, 35, .1);
    border-radius: 100px;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    -ms-transition: .3s;
    transition: .3s
}

.btn:hover {
    border: 2px solid rgba(35, 35, 35, .8)
}

.btn-sm {
    font-size: 9px;
    padding: 16px 34px
}

.btn-md {
    font-size: 11px;
    padding: 18px 42px
}

.btn-lg {
    font-size: 12px;
    padding: 18px 43px
}

.btn-dark {
    background-color: #191a1c;
    color: #fff;
    border-color: #191a1c
}

.btn-dark:focus,
.btn-dark:hover {
    background-color: #292b2f;
    color: #fff;
    border-color: #292b2f
}

.btn-light {
    background-color: #efefef;
    color: #35383c;
    border-color: #efefef
}

.btn-circle,
.progress-bars.standard .progress-bar,
.progress-bars.thick .progress-bar {
    border-radius: 100px
}

.btn-appear {
    text-align: center;
    position: relative;
    overflow: hidden;
    transition: .2s
}

.btn-appear span {
    display: inline-block;
    position: relative;
    padding: 0;
    transition: padding .2s
}

.btn-appear i {
    position: absolute;
    top: 50%;
    right: 0;
    opacity: 0;
    margin-top: -6px;
    -webkit-transition: opacity .2s, right .2s;
    -moz-transition: opacity .2s, right .2s;
    transition: opacity .2s, right .2s
}

.btn-appear:hover span {
    padding: 0 17px 0 0
}

.btn-appear:hover i {
    top: 50%;
    right: 0;
    opacity: 1;
    -webkit-transition: opacity .2s, right .2s;
    -moz-transition: opacity .2s, right .2s;
    transition: opacity .2s, right .2s
}

.btn-primary.focus,
.btn-primary:active:focus,
.btn-primary:focus,
.btn-primary:hover,
fieldset[disabled] .btn-primary.active,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary:focus {
    opacity: .8;
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    transition: opacity .3s
}

.feature-center {
    text-align: center;
    margin: 30px 0;
    padding: 0 30px
}

.feature-center.large h4,
.feature-left h4,
.feature-left-stack h4 {
    line-height: 1.3
}

.feature-center p {
    padding: 0 20px
}

.back-icon {
    position: absolute;
    opacity: .05;
    padding-left: 40px;
    font-size: 134px;
    left: 0
}

.feature-center.white i.back-icon {
    color: #f8f8f8
}

.feature-left {
    text-align: left;
    margin: 14px 0;
    padding: 0 20px
}

.feature-left p {
    padding-right: 15px
}

.feature-left i {
    display: table-cell;
    float: left;
    width: 44px
}

.feature-left i::before {
    margin: 0 auto;
    display: table
}

.feature-left>div {
    display: table-cell;
    padding-left: 24px
}

.feature-left.large>div,
.pl30 {
    padding-left: 30px
}

.blog-quote small,
.blog-quote-post small,
.feature-left h5 {
    line-height: 1.4
}

.feature-left-stack {
    text-align: left;
    margin: 30px 0;
    padding: 0 20px
}

.connected-icons,
.counter,
.price-box,
.project-nav a,
.quote-card,
.vossen-gallery {
    text-align: center
}

.feature-left-stack.boxed,
.portfolio-wide [data-gap="30"] {
    padding: 0 15px
}

.feature-left-stack.title>div h5 {
    display: inline-block;
    padding-left: 15px
}

.feature-left-stack.large.title>div h4 {
    display: inline-block;
    padding-left: 20px
}

.feature-box {
    background-color: rgba(0, 0, 0, .045);
    padding: 40px
}

.progress-bar {
    position: relative;
    border-radius: 12px;
    box-shadow: none
}

.progress-bar-tooltip {
    position: absolute;
    background-color: #343434;
    top: -30px;
    padding: 1px 6px;
    font-size: 11px;
    right: -10px;
    border-radius: 4px;
    color: #fff
}

.progress,
.project-nav a:hover {
    background-color: #f3f3f3
}

.progress-bar-tooltip:after {
    position: absolute;
    border-color: #343434 transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    bottom: -5px;
    content: "";
    left: 35%
}

.progress {
    overflow: visible;
    height: 7px;
    margin-bottom: 20px;
    border-radius: 0
}

.progress-bars.thick h5 {
    position: absolute;
    line-height: 40px;
    margin: 0 20px;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    z-index: 9999
}

.progress-bars.thick .progress {
    height: 40px;
    border-radius: 100px;
    margin-bottom: 30px
}

.progress-bars.thick .progress-bar-tooltip {
    position: absolute;
    padding: 0 6px;
    background-color: transparent;
    font-size: 11px;
    right: 14px;
    margin: 0;
    border-radius: 4px;
    color: #fff;
    top: 0;
    line-height: 40px
}

.progress-bars.standard .progress {
    height: 20px;
    border-radius: 100px
}

.progress-bars.standard p {
    line-height: 15px
}

.progress-bars.standard h5,
.progress-bars.thin h5 {
    margin: 10px 3px;
    font-size: 13px;
    font-weight: 400;
    z-index: 9999
}

.testimonials h4:after,
.testimonials h4:before {
    font-family: var(--font-family);
    font-size: 32px;
    vertical-align: -5px
}

.progress-bars.thin .progress {
    height: 10px;
    border-radius: 10px;
    margin-bottom: 25px
}

.transparent-bars .progress {
    background-color: rgba(255, 255, 255, .1)
}

.progress-svg {
    display: block;
    margin: 0 auto;
    overflow: hidden;
    -webkit-transform: rotate(-90deg) rotateX(180deg);
    transform: rotate(-90deg) rotateX(180deg);
    width: 180px;
    height: 180px
}

.progress-svg circle {
    stroke-dashoffset: 0;
    -webkit-transition: stroke-dashoffset .8s cubic-bezier(.42, 0, .58, 1);
    transition: stroke-dashoffset .8s cubic-bezier(.42, 0, .58, 1);
    stroke: #111;
    stroke-width: 10px
}

.progress-svg .bar {
    stroke: #f0f0f0;
    stroke-width: 12px
}

.team {
    margin: 40px 0;
    padding: 0 10px
}

.team-slider .team {
    margin: 20px 0;
    padding: 0 10px
}

.p0.team,
.team-caption ul,
.team-fullwidth .team {
    padding: 0;
    margin: 0
}

.team-container .team-image {
    -webkit-transition: transform .3s;
    -moz-transition: transform .3s;
    transition: transform .3s;
    -webkit-transform: perspective(600px);
    -moz-transform: perspective(600px);
    transform: perspective(600px)
}

.team-container:hover .team-image {
    -webkit-transform: scale(1.08);
    -moz-transform: scale(1.08);
    transform: scale(1.08)
}

.team-container .team-caption {
    width: 100%;
    position: absolute;
    z-index: 2;
    height: 100%;
    opacity: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .9);
    -webkit-transition: opacity .4s;
    transition: opacity .4s
}

.team-caption div {
    display: table;
    width: 100%;
    height: 100%
}

.team-caption div div {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 10px 0;
    opacity: 0;
    -webkit-transform: scale(.9);
    -moz-transform: scale(.9);
    -o-transform: scale(.9);
    -ms-transform: scale(.9);
    transform: scale(.9);
    -webkit-transition: opacity .2s ease-in-out 0.26s, -webkit-transform .2s ease-in-out 0.26s;
    -moz-transition: opacity .2s ease-in-out, -moz-transform .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out, -o-transform .2s ease-in-out;
    transition: opacity .2s ease-in-out 0.26s, -webkit-transform .2s ease-in-out 0.26s, -moz-transform .2s ease-in-out 0.26s, -o-transform .2s ease-in-out 0.26s, transform .2s ease-in-out 0.26s
}

.team-container:hover .team-caption div div {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: 0.26s;
    transition-delay: 0.26s
}

.team-caption ul li {
    display: inline-block;
    margin: 0 5px
}

.team-caption ul li a {
    color: #fff;
    font-size: 16px;
    margin: 0 3px;
    -webkit-transition: color .3s;
    -moz-transition: color .3s;
    transition: color .3s
}

.team-caption h4 {
    color: #fff;
    font: 400 18px/21px Hind, sans-serif;
    margin: 15px 0 0
}

.team-caption p {
    color: #aaa;
    font-size: 13px
}

.team>h6 {
    margin-top: 20px;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 5px;
    color: #555
}

.team>h5 {
    font-family: var(--font-family);
    ;
    margin-top: 5px;
    font-weight: 700;
    font-size: 18px
}

#iconTabs li a span,
.item-caption h4,
.nav-tabs>li>a,
.vossen-portfolio-filters li {
    font-family: var(--font-family);
}

.video-container {
    position: relative;
    padding-bottom: 56.2%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background-color: #191a1c;
    cursor: pointer
}

.video-container embed,
.video-container iframe,
.video-container object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    visibility: hidden
}

.video-container:before {
    content: '\f488';
    font-family: Ionicons;
    font-size: 34px;
    margin-top: 7px;
    margin-left: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    z-index: 2;
    cursor: pointer;
    visibility: visible;
    transform: translate3d(-50%, -50%, 0) scale(1);
    -moz-transform: translate3d(-50%, -50%, 0);
    -webkit-transform: translate3d(-50%, -50%, 0);
    transition: transform .2s, opacity .2s;
    -moz-transition: transform .2s, opacity .2s;
    -webkit-transition: transform .2s, opacity .2s
}

.video-container:hover:before {
    color: #fff;
    transform: translate3d(-50%, -50%, 0) scale(.8);
    -moz-transform: translate3d(-50%, -50%, 0) scale(.8);
    -webkit-transform: translate3d(-50%, -50%, 0) scale(.8)
}

.video-container:after {
    content: '';
    position: absolute;
    margin-top: 6px;
    height: 90px;
    width: 90px;
    border: none;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, .2);
    cursor: pointer;
    top: 50%;
    left: 50%;
    visibility: visible;
    transform: translate3d(-50%, -50%, 0) scale(1);
    -moz-transform: translate3d(-50%, -50%, 0);
    -webkit-transform: translate3d(-50%, -50%, 0);
    transition: background-color .2s, transform .2s, opacity 1.2s;
    -moz-transition: background-color .2s, transform .2s, opacity .2s;
    -webkit-transition: background-color .2s, transform .2s, opacity 1.2s
}

.video-container:hover:after {
    transform: translate3d(-50%, -50%, 0) scale(.9);
    -moz-transform: translate3d(-50%, -50%, 0) scale(.9);
    -webkit-transform: translate3d(-50%, -50%, 0) scale(.9)
}

.video-container.reveal:after,
.video-container.reveal:before {
    opacity: 0;
    visibility: hidden;
    transition: visibility .4s, opacity .4s;
    -moz-transition: visibility .4s, opacity .4s;
    -webkit-transition: visibility .4s, opacity .4s
}

#fun-facts {
    padding: 20px 10%
}

@media (max-width:1400px) {
    #fun-facts {
        padding: 20px 0
    }
}

.counter {
    margin: 40px 0
}

.counter h1 {
    font-size: 56px;
    line-height: 1;
    font-feature-settings: 'lnum';
    -moz-font-feature-settings: 'lnum=1';
    -ms-font-feature-settings: 'lnum';
    -webkit-font-feature-settings: 'lnum';
    -o-font-feature-settings: 'lnum'
}

.quote-slider>div {
    padding: 20px 0 10px
}

.quote-slider i {
    font-size: 26px;
    display: inline-block;
    vertical-align: top;
    transform-style: flat !important
}

.quote-slider i:last-child {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg)
}

.quote-slider .label {
    display: inline-block;
    margin-top: 20px;
    border-radius: 2px;
    padding: 6px 10px 5px;
    font-size: 12px;
    font-weight: 600
}

.quote-slider h2 {
    line-height: 1.2
}

.blog-grid,
.portfolio {
    position: relative;
    width: 100%;
    float: left;
    padding: 0
}

@media all and (max-width:767px) {
    .portfolio {
        padding: 0 9px
    }
}

.vossen-portfolio-filters {
    padding: 0;
    margin-bottom: 20px
}

.vossen-portfolio-filters li {
    display: inline-block;
    cursor: pointer;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 3;
    text-transform: uppercase;
    color: #888;
    background-color: #f5f5f5;
    padding: 7px 30px;
    margin: 0 1.1px;
    border-radius: 4px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.vossen-portfolio-filters li:first-child {
    border-radius: 100px 0 0 100px;
    padding-left: 34px
}

.vossen-portfolio-filters li:last-child {
    border-radius: 0 100px 100px 0;
    padding-right: 34px
}

.connected-icons li a i:after,
.phone-number h1:after {
    border-radius: 100px;
    opacity: .2;
    top: 0;
    background-color: #fff;
    content: ''
}

.vossen-portfolio-filters li.active {
    cursor: default;
    color: #35383c;
    opacity: 1
}

.vossen-portfolio>div {
    padding: 0 10px;
    opacity: 0;
    transition: opacity .5s, padding-top .5s;
    -moz-transition: opacity .5s, padding-top .5s;
    -webkit-transition: opacity .5s, padding-top .5s
}

.vossen-blog-grid>div>div,
.vossen-portfolio .portfolio-item {
    transition: transform .4s;
    -moz-transition: transform .4s;
    -webkit-transition: transform .4s;
    transform: translateY(20%);
    -moz-transform: translateY(20%);
    -webkit-transform: translateY(20%)
}

.vossen-blog-grid>div.reveal>div,
.vossen-portfolio .reveal .portfolio-item {
    transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0)
}

.portfolio-item {
    position: relative;
    box-shadow: rgba(100, 100, 111, .2) 0 7px 29px 0;
    z-index: 1;
    border-radius: 4px !important;
    overflow: hidden;
    margin-bottom: 19px
}

.portfolio-item:hover .item-caption {
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition-delay: .25s;
    -moz-transition-delay: .25s;
    transition-delay: .25s;
    opacity: 1
}

.item-caption {
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 2;
    color: #fff;
    top: 50%;
    -webkit-transition: opacity .5s, transform .3s;
    -moz-transition: opacity .5s, transform .3s;
    transition: opacity .5s, transform .3s;
    opacity: 0;
    -webkit-transform: perspective(600px);
    -moz-transform: perspective(600px);
    transform: perspective(600px)
}

.connected-icons li a i,
.phone-number {
    position: relative;
    display: inline-block
}

.item-caption h4 {
    font-size: 16px;
    font-weight: 700;
    margin: 13px auto 8px;
    color: #fff
}

.item-caption p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    margin: 8px auto;
    color: #ccc
}

.item-image {
    -webkit-transition: transform .4s;
    -moz-transition: transform .4s;
    transition: transform .4s;
    -webkit-transform: perspective(600px);
    -moz-transform: perspective(600px);
    transform: perspective(600px)
}

.portfolio-item:hover .item-image {
    -webkit-transform: scale(1.08) rotate(.1deg);
    -moz-transform: scale(1.08) rotate(.1deg);
    transform: scale(1.08) rotate(.1deg)
}

.portfolio-item .item-image:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: #191a1c;
    opacity: 0;
    transition: opacity .6s;
    -moz-transition: opacity .6s;
    -webkit-transition: opacity .6s
}

@media all and (max-width:990px) {

    .item-caption,
    .portfolio-item:hover .item-caption {
        position: absolute;
        width: 100%;
        height: auto;
        top: auto;
        bottom: 20px;
        -webkit-transition: opacity .5s, transform .3s;
        -moz-transition: opacity .5s, transform .3s;
        transition: opacity .5s, transform .3s;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }

    .portfolio-item .item-image:after,
    .portfolio-item:hover .item-image:after {
        background: linear-gradient(rgba(0, 0, 0, 0) 0, #191a1c 100%);
        opacity: 1
    }
}

.portfolio-wide [data-gap="10"] {
    padding: 0 5px
}

[data-gap="10"] .vossen-portfolio>div {
    padding: 0 5px;
    margin-bottom: 9px
}

#message-info,
.contact.box-style .col-sm-12,
.contact.box-style .col-sm-6,
.portfolio-wide [data-gap="15"],
form .col-sm-12,
form .col-sm-6 {
    padding: 0 7px
}

.panel-title.collapsed:before,
.panel-title:not(.collapsed):before {
    font-family: IonIcons;
    padding-top: 1px;
    float: right;
    vertical-align: middle
}

[data-gap="15"] .vossen-portfolio>div {
    padding: 0 7px;
    margin-bottom: 14px
}

[data-gap="15"] .vossen-portfolio-filters {
    margin-bottom: 14px
}

.portfolio-wide [data-gap="20"] {
    padding: 0 10px
}

[data-gap="20"] .vossen-portfolio>div {
    padding: 0 10px;
    margin-bottom: 19px
}

[data-gap="20"] .vossen-portfolio-filters {
    margin-bottom: 19px
}

.portfolio-wide [data-gap="25"] {
    padding: 0 12px
}

[data-gap="25"] .vossen-portfolio>div {
    padding: 0 12px;
    margin-bottom: 24px
}

[data-gap="25"] .vossen-portfolio-filters {
    margin-bottom: 25x
}

[data-gap="30"] .vossen-portfolio>div {
    padding: 0 15px;
    margin-bottom: 29px
}

[data-gap="30"] .vossen-portfolio-filters {
    margin-bottom: 29px
}

.portfolio-wide [data-gap="35"] {
    padding: 0 17px
}

[data-gap="35"] .vossen-portfolio>div {
    padding: 0 17px;
    margin-bottom: 34px
}

[data-gap="35"] .vossen-portfolio-filters {
    margin-bottom: 34px
}

#works-page .vossen-portfolio-filters {
    margin-top: 54px;
    margin-bottom: 50px
}

.vossen-gallery {
    margin-top: 20px
}


.project-sidebar>div {
    margin-top: 20px;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e8e8e8
}

.project-sidebar>div p:first-child {
    font-weight: 700;
    color: #191a1c;
    margin-right: 30px
}

.project-info p {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 5px;
    min-width: 100px
}

.blog-widget li:last-child,
.dropdown-submenu>ul>li:last-child a,
.nav-tabs,
.project-sidebar>div:last-child {
    border-bottom: none
}

.project-nav {
    height: 133px
}

.project-nav a {
    display: inline-block;
    background-color: #f9f9f9;
    width: 33.3%;
    float: left;
    padding: 55px 0;
    box-shadow: 0 20px 30px 10px rgba(0, 0, 0, .08);
    -webkit-transition: background-color .3s, color .3s;
    -moz-transition: background-color .3s, color .3s;
    transition: background-color .3s, color .3s
}

.project-nav a h5,
.project-nav a i {
    display: inline-block;
    margin: 0 6px;
    font-size: 16px;
    color: #191a1c;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    transition: .3s
}

.testimonials i {
    font-size: 46px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    display: inline-block;
    transform-style: flat !important
}

.testimonials h4 {
    font-size: 18px;
    font-weight: 400;
    line-height: 34px;
    margin-top: 20px;
    margin-bottom: 30px
}

.testimonials h5,
.testimonials h5 strong {
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 5px
}

.testimonials a {
    font-size: 13px;
    font-weight: 300;
    line-height: 2;
    display: inline-block
}

.testimonials p {
    font-size: 20px;
    font-weight: 300;
    color: #666;
    margin-top: 10px
}

.blog-post .post-date h3,
.blog-post .post-date h4,
.blog-post .post-date span,
.testimonials h4:after,
.testimonials h4:before,
.testimonials.white a {
    color: #eee
}

.testimonials h4:before {
    content: open-quote;
    margin-right: 5px
}

.testimonials h4:after {
    content: close-quote;
    margin-left: 5px
}

.testimonials.quote-icons i {
    font-size: 15px;
    display: inline-block;
    line-height: 1;
    vertical-align: top;
    margin-top: 3px;
    transform-style: flat !important
}

.testimonials.quote-icons i:first-child {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0)
}

.testimonials .occupation {
    font-size: 13px;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 10px
}

.quote-card {
    padding: 60px 40px;
    background-color: #fff;
    border-radius: 8px
}

.quote-card img {
    position: absolute;
    opacity: .07;
    top: -30px;
    left: 0
}

.quote-card p {
    font-size: 16px;
    margin-bottom: 30px
}

.quote-card p.occupation {
    font-size: 14px;
    margin: 0
}

.quote-card h5 {
    margin-top: 20px;
    margin-bottom: 0
}

.vossen-quote-2 {
    opacity: .15
}

.clients-slider img {
    width: initial !important;
    margin: auto
}

.connected-icons {
    padding: 0;
    margin-top: 40px
}

.connected-icons li {
    display: inline-block;
    padding: 10px 26px
}

.connected-icons li a h5,
.connected-icons li a p {
    margin: 0;
    line-height: 1.4
}

#twitter-feed-list .user a,
#twitter-feed-slider .user a,
.connected-icons li a p,
.price-features .ion-close-round {
    font-size: 13px
}

.sidebar-share a,
[data-overlay-light] .connected-icons li a p {
    color: #191a1c
}

.connected-icons li a i {
    width: 100px;
    height: 100px;
    line-height: 112px;
    font-size: 50px;
    color: #fff;
    margin: 0 auto 10px;
    z-index: 2
}

.connected-icons li a i:after {
    width: 100px;
    position: absolute;
    height: 100px;
    left: 0;
    z-index: -1;
    transition: opacity .3s, background-color .3s;
    -moz-transition: opacity .3s, background-color .3s;
    -webkit-transition: opacity .3s, background-color .3s
}

[data-overlay-color="6"] .hero-slider .slide:before,
[data-overlay-color="6"]:before,
[data-overlay-dark="6"] .hero-slider .slide:before,
[data-overlay-dark="6"]:before,
[data-overlay-light="6"] .hero-slider .slide:before,
[data-overlay-light="6"]:before,
[data-overlay-light] .connected-icons li a i:after {
    opacity: .6
}

.phone-number h1 {
    display: inline-block;
    margin: 10px 70px;
    line-height: 1.25
}

.phone-number h1:after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: -1;
    transition: opacity .3s, background-color .3s;
    -moz-transition: opacity .3s, background-color .3s;
    -webkit-transition: opacity .3s, background-color .3s
}

.price-table {
    margin: 40px 0
}

.price-box {
    max-width: 340px;
    margin: 0 auto;
    padding: 40px;
    background-color: #f2f2f2
}

@media all and (min-width:992px) {

    .hero-slider,
    .hero-slider .owl-height,
    .hero-slider .owl-item,
    .hero-slider .owl-stage,
    .hero-slider .owl-stage-outer,
    .hero-slider .owl-wrapper,
    .hero-slider .owl-wrapper-outer {
        height: 100% !important
    }

    .price-box h1 {
        font-size: 70px
    }
}

.bg-grey .price-box {
    border: 2px solid #e9e9e9
}

.bg-dark .price-box {
    border: 2px solid #444
}

.price-box sup {
    position: absolute;
    font-size: 22px;
    margin-left: -18px;
    top: 18px
}

.panel-title h4,
.panel-title.collapsed h4 {
    font-size: 15px;
    letter-spacing: 0;
    font-weight: 400;
    line-height: 0
}

.price-box span span {
    font-size: 26px
}

.price-features {
    min-height: 200px
}

.price-table-featured {
    padding: 0;
    margin: 20px 0
}

.pricing-1 .price-table-featured .price-box {
    border: transparent
}

.price-table-featured .price-features {
    min-height: 240px
}

.pricing-2 .price-box {
    background-color: transparent;
    border: 3px solid #eee
}

.panel-group {
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 0
}

.panel-group .panel {
    margin-bottom: 10px;
    border: 1px solid #f3f3f3;
    border-radius: 4px;
    box-shadow: none
}

.panel-default>.panel-heading {
    color: #666;
    background-color: transparent;
    padding: 0
}

.panel-title.collapsed h4 {
    color: #53575d
}

.panel-title h4 {
    color: #fff
}

.blog-tags li a,
.blog-tags li a:hover,
.panel-title,
.panel-title.collapsed,
.panel-title.collapsed:before,
.panel-title:not(.collapsed):before {
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    -ms-transition: .3s;
    transition: .3s
}

.panel-heading i {
    margin-right: 5px;
    font-size: 12px
}

.panel-title.collapsed:before {
    content: "\f46a";
    color: #666;
    font-size: 16px
}

.panel-title:not(.collapsed):before {
    content: "\f3d0";
    color: #fff;
    font-size: 13px
}

.panel-body {
    padding: 20px 30px
}

.panel-group .panel-heading+.panel-collapse .panel-body {
    border: none;
    background: 0 0
}

.panel-title {
    text-transform: none;
    border: none;
    border-radius: 4px 4px 0 0;
    width: 100%;
    text-align: left;
    font-size: 14px;
    padding: 20px 24px;
    color: #fff
}

.panel-title.collapsed {
    background-color: rgba(0, 0, 0, .045);
    color: #666;
    border-radius: 4px
}

#buttonTabs,
.post-comment {
    margin: 30px 0
}

#buttonTabs li a {
    min-width: 130px;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 2.8;
    margin: 0 2px;
    padding: 9px 38px;
    background-color: rgba(0, 0, 0, .055);
    color: #53575d;
    border-radius: 4px;
    border: none
}

.nav-tabs>li {
    float: left;
    margin-bottom: 10px;
    margin-right: 0
}

@media (max-width:768px) {
    .nav-tabs>li {
        display: inline-block;
        float: none;
        margin-bottom: 10px;
        margin-right: 0;
        width: 49%
    }
}

.nav-tabs>li>a {
    display: inline-block;
    -webkit-transition: color .3s, background-color .3s;
    -moz-transition: color .3s, background-color .3s;
    transition: color .3s, background-color .3s
}

.tab-pane p {
    line-height: 24px
}

.tab-pane {
    position: absolute;
    opacity: 0;
    top: 0;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: opacity .3s, top .3s, transform .3s;
    -moz-transition: opacity .3s, top .3s, transform .3s;
    transition: opacity .3s, top .3s, transform .3s
}

.tab-pane.active {
    position: relative;
    opacity: 1;
    top: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    transform: translateY(-20px);
    -webkit-transition: opacity .4s, top .4s, transform .3s;
    -moz-transition: opacity .4s, top .4s, transform .3s;
    transition: opacity .4s .25s, top .4s .25s, transform .3s .25s
}

#iconTabs li a,
#iconTabs li.active a {
    background-color: transparent;
    border: none
}

#iconTabs {
    padding: 0;
    margin-top: 10px;
    margin-bottom: 20px
}

#iconTabs li {
    min-width: 100px;
    line-height: 1
}

#iconTabs li a {
    min-width: 100px;
    line-height: 1.4;
    margin: 0 1px
}

#iconTabs li a span {
    display: block;
    color: #47494e;
    text-transform: none;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0;
    -webkit-transition: color .35s;
    -moz-transition: color .35s;
    transition: color .35s
}

#iconTabs li a:hover span {
    color: #191a1c;
    -webkit-transition: -webkit-color .35s;
    transition: color .35s
}

#iconTabs.white li a span {
    color: #bbb;
    -webkit-transition: -webkit-color .35s;
    transition: color .35s
}

#iconTabs.white li a:hover span {
    color: #fff;
    -webkit-transition: -webkit-color .35s;
    transition: color .35s
}

[data-overlay-color] .hero-slider .slide:before,
[data-overlay-color]:before,
[data-overlay-dark] .hero-slider .slide:before,
[data-overlay-dark]:before,
[data-overlay-light] .hero-slider .slide:before,
[data-overlay-light]:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1
}

[data-overlay-dark] .hero-slider .slide:before,
[data-overlay-dark]:before {
    background: #000
}

[data-overlay-color] .hero-slider .slide:before,
[data-overlay-color]:before {
    background: #425cbb
}

[data-overlay-color] p,
[data-overlay-dark] p {
    color: #bbb
}

[data-overlay-color="1"] .hero-slider .slide:before,
[data-overlay-color="1"]:before,
[data-overlay-dark="1"] .hero-slider .slide:before,
[data-overlay-dark="1"]:before,
[data-overlay-light="1"] .hero-slider .slide:before,
[data-overlay-light="1"]:before {
    opacity: .1
}

[data-overlay-color="2"] .hero-slider .slide:before,
[data-overlay-color="2"]:before,
[data-overlay-dark="2"] .hero-slider .slide:before,
[data-overlay-dark="2"]:before,
[data-overlay-light="2"] .hero-slider .slide:before,
[data-overlay-light="2"]:before {
    opacity: .2
}

[data-overlay-color="3"] .hero-slider .slide:before,
[data-overlay-color="3"]:before,
[data-overlay-dark="3"] .hero-slider .slide:before,
[data-overlay-dark="3"]:before,
[data-overlay-light="3"] .hero-slider .slide:before,
[data-overlay-light="3"]:before {
    opacity: .3
}

[data-overlay-color="4"] .hero-slider .slide:before,
[data-overlay-color="4"]:before,
[data-overlay-dark="4"] .hero-slider .slide:before,
[data-overlay-dark="4"]:before,
[data-overlay-light="4"] .hero-slider .slide:before,
[data-overlay-light="4"]:before {
    opacity: .4
}

.bg-grey,
[data-bg-grey] {
    background-color: #f7f7f7
}

.vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%)
}

@media all and (max-width:992px) {
    .hero-fullscreen {
        height: auto
    }

    .hero-fullscreen .container {
        padding-top: 0;
        top: 0;
        transform: none;
        -moz-transform: none;
        -webkit-transform: none
    }

    .hero-fullwidth .container {
        padding-top: 0
    }

    .btn-lg {
        font-size: 11px;
        padding: 18px 42px
    }

    .vossen-portfolio-filters li {
        margin: 3px 1.1px
    }

    .vossen-portfolio-filters li:first-child,
    .vossen-portfolio-filters li:last-child {
        border-radius: 4px
    }

    .vertical-align {
        top: 0;
        transform: none;
        -moz-transform: none;
        -webkit-transform: none
    }
}

.contact input,
select,
textarea {
    padding: 11px 4px;
    margin: 20px 0;
    max-width: 100%;
    border: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #555;
    width: 100%;
    border-bottom: 2px solid #ddd
}

.blog-grid-content time span,
.contact input.submit,
.contact.box-style input.submit,
input.submit {
    font-weight: 700;
    font-family: var(--font-family);
}

.uneditable-input:focus,
input[type=color]:focus,
input[type=date]:focus,
input[type=datetime-local]:focus,
input[type=datetime]:focus,
input[type=email]:focus,
input[type=month]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=text]:focus,
input[type=time]:focus,
input[type=url]:focus,
input[type=week]:focus,
textarea:focus {
    border-color: none;
    box-shadow: none;
    outline: 0;
    transition: .35s
}

::-webkit-input-placeholder {
    color: #999
}

.contact input,
.contact input:focus,
.contact.box-style textarea,
select:focus,
textarea:focus {
    -webkit-transition: border-color .35s;
    -moz-transition: border-color .35s;
    transition: border-color .35s
}

.contact input.submit {
    width: auto;
    font-size: 12px;
    color: #fff;
    padding: 10px 16px 9px;
    border: none
}

input[type=submit][disabled] {
    background: #888
}

#message-info {
    margin: 10px 0;
    text-align: left
}

.error_message {
    display: inline-block;
    top: -10px;
    margin-left: auto;
    margin-right: auto;
    line-height: 22px;
    padding: 3px 15px 0;
    color: #fff;
    -webkit-border-radius: 3px;
    background-color: #ff4747;
    background-position: 10px center;
    background-repeat: no-repeat
}

.contact.box-style input,
.contact.box-style textarea,
input,
textarea {
    padding: 14px 18px;
    margin: 0 0 14px;
    max-width: 100%;
    border: 0;
    font-weight: 300;
    font-size: 13px;
    line-height: 22px;
    color: #555;
    width: 100%;
    background-color: #f5f5f5;
    border: none;
    border-radius: 4px;
    outline: 0
}

.contact.box-style input.submit,
input.submit {
    width: auto;
    color: #fff;
    border: none !important;
    font-size: 11px;
    padding: 15px 43px;
    line-height: 22px;
    border-radius: 100px
}

.contact .loader {
    display: inline-block;
    position: absolute;
    top: 13px;
    margin-left: 14px
}

.loader div {
    margin: 0 auto;
    position: relative;
    text-indent: -9999em;
    border-top: 2px solid rgba(0, 0, 0, .2);
    border-right: 2px solid rgba(0, 0, 0, .2);
    border-bottom: 2px solid rgba(0, 0, 0, .2);
    border-left: 2px solid #111;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: 1.1s linear infinite load8;
    animation: 1.1s linear infinite load8
}

.blog-list li,
.blog-standard li {
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 30px
}

.loader div,
.loader div:after {
    border-radius: 50%;
    width: 26px;
    height: 26px
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

#vossen-map {
    width: 100%;
    height: 500px
}

.vossen-map-info {
    padding: 7px 10px 0 5px
}

.blog {
    padding-top: 69px
}

.blog-list,
.blog-sidebar,
.blog-standard,
.pt40 {
    padding-top: 40px
}

.blog-standard li {
    margin-bottom: 60px
}

.blog-carousel {
    margin: 120px 30px 140px
}

.blog-list li {
    margin-bottom: 30px
}

.vossen-blog-grid>div {
    opacity: 0;
    transition: opacity .5s, padding-top .5s;
    -moz-transition: opacity .5s, padding-top .5s;
    -webkit-transition: opacity .5s, padding-top .5s;
    margin-bottom: 30px;
    padding: 0 15px
}

.blog-grid-img {
    position: relative;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    overflow: hidden
}

.blog-grid-content {
    position: relative;
    background-color: #fff;
    padding: 30px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px
}

.blog-grid-content .post-header {
    margin-top: 20px;
    margin-bottom: 20px
}

.blog-grid-content time {
    width: 58px;
    height: 58px;
    box-sizing: border-box;
    border-radius: 50%;
    text-align: center;
    background: #1c1c1c;
    color: #fff;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.blog-grid-content time span {
    display: block;
    text-transform: uppercase;
    line-height: 1.3;
    letter-spacing: 1px;
    margin-right: -1px;
    text-align: center;
    font-size: 11px
}

.blog-sidebar .cbp-wrapper .cbp-item:last-child .blog-thumb-desc,
.demo-picks .lead,
.mb50 {
    margin-bottom: 50px
}

.sidebar {
    padding-left: 30px;
    padding-top: 40px;
    padding-bottom: 40px
}

.blog-widget {
    margin-top: 20px;
    margin-bottom: 40px
}

.blog-widget .search-button {
    margin-top: 0;
    line-height: 0
}

.blog-widget .form-control {
    border-radius: 0;
    font-weight: 300;
    font-size: 11px
}

.form-control:focus {
    border-color: #999
}

.blog-widget ul,
.pl5 {
    padding-left: 5px
}

.blog-widget li {
    padding: 5px 0;
    border-bottom: 1px solid #e8e8e8;
    font-weight: 300;
    font-size: 13px
}

.blog-widget li i {
    font-size: 11px;
    margin-right: 10px
}

.blog-widget h5 {
    font-family: var(--font-family);
    font-size: 14px
}

.pt15,
.tags-list {
    padding-top: 15px
}

.blog-tags li {
    border: none;
    display: inline-block
}

.blog-tags li a {
    margin: 0 5px 7px 0;
    padding: 7px 10px 5px;
    border: 1px solid #e9e9e9;
    color: #777;
    font-size: 11px;
    font-weight: 300;
    text-decoration: none
}

.blog-tags li a:hover {
    border: 1px solid #999;
    color: #111
}

.blog .sidebar-share {
    margin-top: -20px
}

.sidebar-share ul li {
    margin-right: 7px
}

.blog-pagination {
    margin: 40px 0 90px
}

.post-share,
.post-tags {
    margin-top: 40px;
    display: inline-block
}

.blog-pagination li a,
.blog-pagination li:first-child a,
.blog-pagination li:last-child a {
    background-color: transparent;
    border: none;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    position: relative;
    float: left;
    line-height: 32px;
    width: 30px;
    height: 30px;
    border-radius: 100px
}

.blog-pagination>.active>a,
.blog-pagination>.active>a:focus,
.blog-pagination>.active>a:hover,
.blog-pagination>.active>span,
.blog-pagination>.active>span:focus,
.blog-pagination>.active>span:hover {
    color: #fff;
    background-color: transparent;
    cursor: default
}

.blog-pagination li a i {
    font-size: 16px
}

.blog-pagination li a:hover {
    color: #bbb;
    background-color: transparent
}

.blog-pagination li:first-child {
    position: absolute;
    left: 0
}

.blog-pagination li:last-child {
    position: absolute;
    right: 0
}

.blog-post .post-date {
    position: absolute;
    max-width: 74px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 7px;
    background-color: transparent;
    box-shadow: none;
    z-index: 3
}

.post-header h3 {
    margin: 0 0 10px
}

.post-header h5 strong {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #4c51e0
}

.post-header h5 span {
    font-size: 11px;
    font-weight: 400;
    font-style: italic;
    text-transform: capitalize;
    color: #999
}

.post-tags a {
    font-size: 10px !important;
    letter-spacing: 1px;
    text-transform: uppercase;
    background: #f2f2f2;
    color: #777;
    padding: 6px 7px 7px 8px;
    margin-right: 6px;
    margin-bottom: 8px;
    display: inline-block;
    -o-transition: .3s;
    -ms-transition: .3s;
    -moz-transition: .3s;
    -webkit-transition: .3s;
    transition: .3s
}

.post-share {
    float: right
}

.post-share a {
    font-size: 13px;
    color: #aaa;
    margin-left: 14px;
    padding-right: 14px;
    display: inline-block;
    -o-transition: .25s;
    -ms-transition: .25s;
    -moz-transition: .25s;
    -webkit-transition: .25s;
    transition: .25s
}

.post-share i {
    font-size: 15px;
    margin-right: 4px;
    position: relative
}

.post-share i:before {
    top: 2px;
    right: 3px;
    position: absolute
}

.comment-number {
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 30px
}

.footer.style-1.dark ul,
.mb80,
.vos-comments {
    margin-bottom: 80px
}

.comment-content {
    padding: 20px 0;
    border-bottom: 1px solid #f3f3f3
}

.post-comment .avatar {
    margin-right: 22px;
    float: left
}

.post-comment .avatar img {
    width: 74px;
    height: 74px;
    border-radius: 100px
}

.post-comment .comment-header {
    line-height: 1;
    font-size: 13px;
    padding-right: 46px;
    margin-top: 3px;
    margin-bottom: 10px
}

.post-comment .author a {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 700;
    color: #191a1c
}

.post-comment .comment-header .time-ago a {
    font-size: 12px;
    color: #b1b1b1;
    margin-left: 6px
}

.post-comment .reply a {
    font-size: 10px;
    color: #b1b1b1;
    margin-right: 6px;
    float: right
}

.pl90,
.post-comment .reply-comment {
    padding-left: 90px
}

.post-comment .reply-comment .avatar img {
    width: 50px;
    height: 50px;
    border-radius: 100px
}

.form-leave-comment {
    width: 100%;
    float: left;
    margin-bottom: 100px
}

.footer.style-1 {
    background: #f5f5f5;
    text-align: center;
    padding: 100px 0 40px
}

.footer.style-1 img {
    margin-bottom: 15px;
    -webkit-transition: transform .2s;
    -moz-transition: transform .2s;
    -o-transition: transform .2s;
    -ms-transition: transform .2s;
    transition: transform .2s
}

.footer.style-1 img:hover {
    cursor: pointer;
    transform: scale(.9);
    -webkit-transform: scale(.9);
    -moz-transform: scale(.9);
    -o-transform: scale(.9);
    -ms-transform: scale(.9)
}

.footer.style-1 ul {
    list-style: none;
    float: none;
    margin: 0 auto 100px;
    padding-left: 0
}

.footer.style-1 li {
    font-size: 16px;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px
}

.footer.style-1 ul li a,
.footer.style-1>a {
    color: #191a1c;
    transition: color .3s;
    -moz-transition: color .3s;
    -webkit-transition: color .3s
}

.footer.style-1>p {
    color: #555;
    font-size: 12px
}

.footer.style-1.dark {
    background: #111
}

.footer.style-1.dark img,
.mb5 {
    margin-bottom: 5px
}

.footer.style-1.dark ul li a,
.footer.style-1.dark ul li a:hover,
.footer.style-1.dark>a {
    color: #ddd
}

.footer.style-1.dark>p {
    color: #ddd;
    font-size: 12px
}

.scroll-top {
    position: absolute;
    color: #fff;
    z-index: 100;
    line-height: 26px;
    bottom: 30px;
    right: 32px;
    cursor: pointer;
    border-radius: 2px;
    height: 30px;
    width: 30px;
    display: block;
    text-align: center;
    opacity: 1;
    transition: background-color .3s;
    -moz-transition: background-color .3s;
    -webkit-transition: background-color .3s;
    -o-transition: background-color .3s
}

.scroll-top:hover {
    background-color: #222
}

.scroll-top i {
    color: #fff;
    font-size: 10px;
    line-height: 30px
}

@media (min-width:992px) {
    .modal-lg {
        width: 900px
    }

    .row.vertical-align {
        display: flex;
        align-items: center;
        margin-right: -15px;
        margin-left: -15px;
        top: 0;
        transform: none;
        -webkit-transform: none
    }

    .megamenu-content .mg-col h5,
    .nav>li>a {
        letter-spacing: 1px
    }

    .megamenu .mega-col-title,
    .megamenu-content .mg-col h5,
    .nav>li>a {
        font-family: var(--font-family);
    }

    .megamenu h5,
    .nav>li>a {
        text-transform: uppercase
    }

    .theme-light nav.navbar {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
        border-radius: 0;
        border-bottom: 1px solid #F4F4F4;
    }

    .theme-dark nav.navbar {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
        border-radius: 0;
        border: none;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, .1)
    }

    nav .nav {
        height: 68px;
        -webkit-transition: height .3s;
        -moz-transition: height .3s;
        transition: height .3s
    }

    nav.nav-small .nav {
        height: 60px;
        -webkit-transition: height .3s;
        -moz-transition: height .3s;
        transition: height .3s
    }

    nav.nav-small>.container,
    nav>.container {
        padding-top: 0
    }

    .navbar-nav>li {
        display: table;
        height: 100%
    }

    .navbar-nav>li>a {
        color: #333;
        line-height: 2;
        display: table-cell !important;
        vertical-align: middle
    }

    .nav-small .navbar-brand img,
    .navbar-brand img {
        -webkit-transition: max-height .4s, opacity .3s;
        -moz-transition: max-height .4s, opacity .3s;
        -o-transition: max-height .4s, opacity .3s;
        -ms-transition: max-height .4s, opacity .3s;
        transition: max-height .4s, opacity .3s
    }

    .nav>li>a {
        display: inline-block;
        font-size: 12.4px;
        padding-bottom: 8px
    }

    /* .navbar-brand {
        height: auto;
        margin-left: 0 !important;
        position: absolute;
        z-index: 999
    } */

    .navbar-brand>img {
        position: absolute;
        padding-left: 36px
    }

    .container>.navbar-collapse,
    .megamenu.vos .megamenu-content {
        padding: 0
    }

    .navbar-collapse>.container,
    .navbar-header>.container {
        width: 100%;
        margin: 0;
        padding: 0
    }

    .menu-right {
        float: right
    }

    .menu-left {
        float: left;
        margin: 0
    }

    .menu-center {
        float: none;
        margin: 0 auto;
        text-align: center
    }

    .menu-center>li {
        display: inline-block;
        float: none
    }

    .label-items-in-cart {
        position: absolute;
        width: 14px;
        height: 14px;
        padding: 3px 0 0 5px;
        font-family: var(--font-family);
        font-weight: 900;
        font-size: 8px;
        color: #fff;
        border-radius: 4em;
        line-height: 1;
        top: 18px;
        right: 1px;
        -webkit-transition: top .3s;
        -moz-transition: top .3s;
        transition: top .3s
    }

    .nav li.nav-separator,
    nav.nav-small .nav li.nav-separator {
        border-left: 1px solid rgba(0, 0, 0, .1);
        transition: height .3s
    }

    .nav-small .label-items-in-cart {
        top: 13px
    }

    .nav-icon:last-child {
        margin-right: 30px
    }

    .nav li.nav-icon a {
        padding: 16px 8px 14px
    }

    .nav li.nav-icon a i {
        font-size: 16px;
        line-height: 0;
        padding: 0
    }

    .nav li.nav-icon .hidden-md,
    [data-menu-style=dark] .logo-dark {
        display: none
    }

    .nav li.nav-separator {
        line-height: 0;
        height: 68px;
        margin: 0 16px
    }

    nav.nav-small .nav li.nav-separator {
        height: 60px
    }

    .dropdown:hover>.dropdown-menu {
        display: block
    }

    .dropdown>ul.dropdown-menu {
        visibility: hidden;
        display: block;
        opacity: 0;
        -webkit-transition: opacity .3s 0.2s, visibility .3s 0.2s;
        -moz-transition: opacity .3s, visibility .3s;
        -o-transition: opacity .3s, visibility .3s;
        -ms-transition: opacity .3s, visibility .3s;
        transition: opacity .3s 0.2s, visibility .3s 0.2s
    }

    .dropdown:hover>ul.dropdown-menu {
        visibility: visible;
        opacity: 1;
        display: block
    }

    .dropdown.megamenu {
        position: static
    }

    nav .container {
        position: relative
    }

    .megamenu.vos .dropdown-menu,
    .megamenu>.dropdown-menu {
        left: auto
    }

    .megamenu .fullwidth {
        margin: 0 30px
    }

    .megamenu .megamenu-content {
        padding: 21px;
        background: #1c1c1c
    }

    .megamenu .megamenu-content a {
        padding: 2px 13px
    }

    .megamenu .megamenu-content a:hover {
        background-color: rgba(255, 255, 255, .04);
        padding-left: 17px
    }

    .megamenu-content .mg-col {
        padding: 0 15px;
        border-right: 1px dotted rgba(255, 255, 255, .12)
    }

    .megamenu-content .mg-col:last-child {
        border-right: 0px
    }

    .megamenu-content .mg-col h5 {
        font-size: 11px;
        font-weight: 700;
        color: #ccc;
        -webkit-transition: color .3s;
        transition: color .3s
    }

    .megamenu-content .mg-col:hover h5 {
        color: #fff;
        -webkit-transition: color .3s;
        transition: color .3s
    }

    .megamenu .dropdown-menu {
        left: 0;
        right: 0
    }

    .megamenu .mega-col-title {
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 700;
        line-height: 1.4
    }

    .megamenu .mega-col-title:hover {
        background-color: transparent !important
    }

    .megamenu h5 {
        color: #fff;
        font-size: 12px;
        padding-left: 13px;
        padding-bottom: 5px
    }

    .megamenu ul {
        list-style: none;
        padding-left: 0
    }

    .mg-col ul li {
        font-weight: 400
    }

    .icon-items li a i {
        vertical-align: middle;
        display: inline-block;
        font-size: 11px !important;
        padding-right: 14px !important;
        padding-left: 0 !important;
        margin-top: -2px !important
    }

    .megamenu.vos .mg-col {
        min-width: 176px;
        border-right: none;
        padding: 4px 5px
    }

    .megamenu.vos .megamenu-content a {
        display: block;
        padding: 10px 22px;
        line-height: 1.42857143;
        color: #b2b2b2;
        -webkit-transition: background-color .3s, color .3s, transform .3s;
        -moz-transition: background-color .3s, color .3s, transform .3s;
        transition: background-color .3s, color .3s, transform .3s
    }

    .megamenu.vos .megamenu-content a:hover {
        color: #fff;
        background-color: transparent;
        -webkit-transform: translateX(5px);
        -moz-transform: translateX(5px);
        transform: translateX(5px)
    }

    [data-fullwidth=true]>.container {
        width: 100%
    }

    [data-menu-style=light] .navbar-nav>.open>a:hover {
        color: #999
    }

    .theme-dark nav.navbar[data-menu-style=transparent] {
        background-color: transparent;
        border-bottom: 1px solid rgba(255, 255, 255, .1)
    }

    .theme-light nav.navbar[data-menu-style=transparent] {
        background-color: transparent;
        /* border-bottom: 1px solid rgba(255, 255, 255, .1) */
    }

    nav.navbar.nav-small[data-menu-style=transparent] {
        background-color: #fff;
        border-bottom: none
    }

    [data-menu-style=transparent] .navbar-nav>li>a {
        color: #fff;
        font-weight: 700;
        letter-spacing: 1px
    }

    .nav-small[data-menu-style=transparent] .navbar-nav>li>a {
        color: #191a1c;
        font-weight: 400
    }

    .nav-small[data-menu-style=transparent] .navbar-nav>li>a:hover {
        color: #191a1c
    }

    .nav-small[data-menu-style=transparent] .logo-light,
    [data-menu-style=transparent] .logo-dark {
        opacity: 0
    }

    .nav-small[data-menu-style=transparent] .logo-dark,
    [data-menu-style=transparent] .logo-light {
        opacity: 1
    }

    nav.navbar[data-menu-style=transparent] li.nav-separator {
        border-left: 1px solid rgba(255, 255, 255, .1)
    }

    nav.navbar.nav-small[data-menu-style=transparent] li.nav-separator {
        border-left: 1px solid rgba(0, 0, 0, .06)
    }

    nav.navbar[data-menu-style=dark] {
        background-color: #111;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, .3)
    }

    [data-menu-style=dark] .navbar-nav>li>a {
        color: #eee;
        font-weight: 700
    }

    nav.navbar[data-menu-style=dark] li.nav-separator {
        border-left: 1px solid rgba(255, 255, 255, .06)
    }

    nav.navbar.nav-up {
        transform: translateY(-69px)
    }

    nav.navbar.nav-down {
        transform: translateY(0)
    }
}

nav.navbar {
    background-color: #fff;
    border-radius: 0;
    margin-bottom: 0
}

nav.navbar,
nav.navbar.nav-small {
    -webkit-transition: background-color .3s, transform .3s;
    -moz-transition: background-color .3s, transform .3s;
    transition: background-color .3s, transform .3s
}

.navbar-brand img {
    max-height: 68px;
    max-width: none
}

.nav-small .navbar-brand img {
    max-height: 60px
}

.navbar-nav>li>a {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 1px;
    padding: 16px 11px 14px;
    -webkit-transition: color .3s, opacity .3s;
    -moz-transition: color .3s, opacity .3s;
    transition: color .3s, opacity .3s
}

.nav>li>a:hover,
.navbar-nav>li>a:hover {
    background-color: transparent;
    opacity: .8
}

.nav li a i {
    vertical-align: middle;
    font-size: 13px;
    padding-left: 7px;
    margin-top: -5px
}

.dropdown-menu {
    top: 100%;
    left: 0;
    float: left;
    min-width: 200px;
    padding: 7px 0 8px;
    margin: 0;
    font-size: 13px;
    line-height: 13px;
    background-color: #1c1c1c;
    border: none;
    border-radius: 0;
    box-shadow: none
}

.dropdown-menu>li>a {
    width: 100%;
    font-size: 13px;
    font-weight: 400;
    padding: 11px 22px;
    color: #b2b2b2;
    -webkit-transition: background-color .3s, color .3s, transform .3s;
    -moz-transition: background-color .3s, color .3s, transform .3s;
    transition: background-color .3s, color .3s, transform .3s
}

.dropdown-menu>li>a:hover {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    transform: translateX(5px)
}

.dropdown-menu>li>ul>li>a:hover {
    padding-left: 18px
}

.dropdown-menu>li>a:focus {
    background-color: transparent;
    color: #ccc
}

.dropdown-menu>li:last-child a {
    border-bottom: none;
    width: 100%
}

.dropdown-menu>li>a>i {
    vertical-align: middle;
    font-size: 7px;
    margin-top: 5.5px
}

.dropdown-submenu>ul>li>a {
    width: 100%;
    font-size: 12.5px;
    padding: 12px 15px;
    color: #b2b2b2;
    border-bottom: 1px dotted #222 !important
}

.dropdown-submenu>.dropdown-menu {
    top: 5px;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px
}

.dropdown-left .dropdown-menu {
    left: auto;
    right: 0
}

@media (max-width:991px) {

    .nav li.dropdown .icon-items a i,
    .nav li.dropdown a i {
        height: auto;
        padding-bottom: 9px;
        padding-top: 9px;
        text-align: center
    }

    nav.navbar {
        border: none;
        border-radius: 0
    }

    nav>.container {
        margin: 0;
        padding: 0;
        width: 100%
    }

    .theme-light .navbar-header {
        float: none;
        padding: 3px 0;
        /* border-bottom: 1px solid rgba(0, 0, 0, .06); */
        border-bottom: 1px solid #F4F4F4;
    }

    .theme-dark .navbar-header {
        float: none;
        padding: 3px 0;
        border-bottom: 1px solid rgba(0, 0, 0, .06);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, .1)
    }

    .navbar-brand {
        padding-left: 0
    }

    .navbar-collapse {
        background-color: #1c1c1c;
        border: none;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
        padding: 0
    }

    .navbar>.container .navbar-brand,
    .navbar>.container-fluid .navbar-brand {
        margin-left: 0
    }

    .megamenu-content .mg-col h5,
    .navbar-brand img.logo-light {
        display: none
    }

    .nav-small .navbar-brand img,
    .navbar-brand img {
        max-height: 100%
    }

    .navbar-toggle {
        display: block;
        height: 50px;
        margin: 0;
        padding: 15px 0 15px 15px;
        background: 0 0 !important;
        border: none
    }

    .navbar-toggle .icon-bar {
        background-color: #bbb;
        display: block;
        height: 3px;
        -webkit-transition: transform .3s;
        -moz-transition: transform .3s;
        transition: transform .3s;
        width: 19px
    }

    .navbar-toggle .icon-bar+.icon-bar {
        margin-top: 3px
    }

    .navbar-nav .open .dropdown-menu>li>a:hover,
    .navbar-toggle:focus,
    .navbar-toggle:hover {
        background-color: transparent
    }

    .navbar-toggle:hover {
        background: 0 0 !important
    }

    .navbar-toggle .top-bar {
        transform: rotate(45deg);
        transform-origin: 2% 60%
    }

    .navbar-toggle .middle-bar {
        opacity: 0
    }

    .navbar-toggle .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 8% 60%
    }

    .navbar-toggle.collapsed .bottom-bar,
    .navbar-toggle.collapsed .top-bar {
        transform: rotate(0)
    }

    .navbar-toggle.collapsed .middle-bar {
        opacity: 1
    }

    .navbar-collapse.collapse {
        display: none !important
    }

    .navbar-nav {
        float: none !important;
        margin: 7.5px -10px
    }

    .navbar-nav>li {
        float: none
    }

    .navbar-text {
        float: none;
        margin: 15px 0
    }

    .navbar-collapse.collapse.in {
        display: block !important
    }

    .collapsing {
        overflow: hidden !important
    }

    .container>.navbar-collapse,
    .container>.navbar-header {
        margin-left: 0;
        margin-right: 0
    }

    .navbar-nav>li>a,
    .navbar-nav>li>a:focus {
        color: #b2b2b2;
        padding: 16px 13px 14px
    }

    .nav>li>a {
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0
    }

    .navbar-nav>li>a {
        border-bottom: 1px dotted rgba(255, 255, 255, .07) !important;
        width: 100%
    }

    .dropdown-menu>li>a,
    .dropdown-submenu>ul>li:last-child a,
    .dropdown-submenu>ul>li>a {
        border-bottom: 1px dotted rgba(255, 255, 255, .07)
    }

    .navbar-nav>li>a:hover {
        color: #fff !important
    }

    .navbar-nav>li:last-child>a {
        border-bottom: 0 !important
    }

    .nav li.dropdown a i {
        display: block;
        float: right;
        line-height: 50px;
        margin-top: -6px;
        position: absolute;
        right: 0;
        top: 0;
        width: 50px
    }

    .nav li.dropdown .icon-items a i {
        float: left;
        left: 0;
        line-height: 1;
        margin-right: 15px;
        margin-top: 3px;
        padding-left: 0;
        position: relative;
        width: 10px
    }

    .navbar-nav .open .dropdown-menu {
        background-color: transparent;
        border: 0;
        box-shadow: none;
        float: none;
        margin-top: 0;
        position: static;
        -webkit-box-shadow: none;
        width: auto
    }

    .dropdown-menu,
    .dropdown-submenu .dropdown-menu {
        box-shadow: none !important;
        margin-left: 30px;
        -webkit-box-shadow: none !important
    }

    .dropdown-menu>li>a {
        padding: 16px 11px
    }

    .navbar-nav .open .dropdown-menu>li>a {
        color: #999;
        padding: 17px 11px 15px
    }

    .navbar-nav .open .dropdown-menu>li>a:focus,
    .navbar-nav>.open>a,
    .navbar-nav>.open>a:focus {
        color: #b2b2b2
    }

    .dropdown-menu>li>a,
    .dropdown-submenu>ul>li>a {
        font-size: 13px;
        width: 100%
    }

    .nav .open>a,
    .nav .open>a:focus,
    .nav .open>a:hover {
        background-color: transparent;
        border-color: rgba(255, 255, 255, .07)
    }

    .dropdown-menu>li:last-child a {
        border-bottom: 1px dotted rgba(255, 255, 255, .07);
        padding: 10px 11px
    }

    .megamenu .dropdown-menu {
        margin-left: 15px
    }

    .megamenu-content .mg-col ul {
        padding: 0
    }

    .dropdown a {
        display: inline-block !important;
        font-weight: 400;
        color: #a8a8a8
    }

    .dropdown-menu {
        max-height: 0;
        padding: 0;
        overflow: hidden;
        position: static;
        display: block;
        float: none
    }

    .dropdown>.dropdown-menu,
    .open>.dropdown-menu {
        -webkit-transition: .2s;
        -moz-transition: .2s;
        -ms-transition: .2s;
        -o-transition: .2s;
        transition: max-height .5s
    }

    .navbar-nav>.open>.dropdown-menu,
    .navbar-nav>.open>.dropdown-menu>.open>.dropdown-menu {
        max-height: 2000px;
        overflow: visible;
        position: static;
        float: none;
        margin-top: 0
    }

    .dropdown-menu li,
    .navbar-nav>.open>.dropdown-menu>.dropdown-menu>li {
        margin-bottom: 5px;
        margin-left: -2px;
        opacity: 0
    }

    .navbar-nav>.open>.dropdown-menu li {
        margin-bottom: 0;
        margin-left: 0;
        opacity: 1
    }

    .dropdown-menu li,
    .dropdown-submenu>.dropdown-menu li,
    .navbar-nav>.open>.dropdown-menu li,
    .navbar-nav>.open>.dropdown-menu>.dropdown-menu>li,
    .navbar-nav>.open>.dropdown-menu>.open li {
        -webkit-transition: .2s;
        -moz-transition: .2s;
        -ms-transition: .2s;
        -o-transition: .2s;
        transition: .2s
    }

    .nav li.nav-separator {
        display: inline-block;
        margin-left: 2px
    }

    .nav li.nav-icon {
        border-bottom: none;
        display: inline-block
    }

    .navbar-nav>li.nav-icon>a {
        border-bottom: none !important;
        padding: 16px 8px 14px !important
    }

    .nav li.nav-icon a i {
        font-size: 16px;
        padding: 0
    }

    .nav li.nav-icon.dropdown a {
        padding-bottom: 2px;
        padding-top: 18px
    }

    .nav li.nav-icon.dropdown a i {
        float: left;
        line-height: 0;
        margin: 0;
        position: relative;
        width: auto
    }
}

#countdown-timer,
.input-group {
    margin-left: auto;
    margin-right: auto
}

ol li,
ul li {
    color: #888
}

.subscription .alert-danger:after,
.subscription .alert-success:after {
    border-style: solid;
    border-width: 10px 8px 0;
    bottom: -10px;
    content: "";
    left: 45%;
    position: absolute
}

#countdown-timer {
    display: block;
    text-align: center
}

#countdown-timer div {
    display: inline-block;
    margin: 15px 20px;
    text-align: center;
    font-size: 14px
}

#countdown-timer h2,
#countdown-timer h6 {
    font-family: var(--font-family);
    font-weight: 700;
    line-height: 1;
    margin-bottom: 0
}

#countdown-timer h6 {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 5px
}

#twitter-feed ul li:before {
    font-family: Ionicons;
    content: "\f243";
    position: absolute;
    left: 17px;
    margin-top: -4px;
    color: #fff
}

#twitter-feed ul li {
    padding-left: 26px;
    padding-top: 0;
    margin-bottom: 4px
}

#twitter-feed-slider ul li {
    padding: 20px 0
}

#twitter-feed-list ul li {
    padding: 5px 0
}

#twitter-feed-slider .user a:before {
    font-family: Ionicons;
    font-size: 50px;
    content: "\f243";
    color: #111
}

#twitter-feed-list ul li:first-child .user a:before {
    font-family: Ionicons;
    font-size: 38px;
    content: "\f243";
    color: #111
}

#twitter-feed-list ul li:first-child .user {
    margin: 20px 0
}

#twitter-feed-list .interact a:before,
#twitter-feed-list .interact a:hover:before,
#twitter-feed-list .user a:before,
#twitter-feed-list .user a:hover:before,
#twitter-feed-slider .interact a:before,
#twitter-feed-slider .interact a:hover:before,
#twitter-feed-slider .user a:before,
#twitter-feed-slider .user a:hover:before {
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    transition-duration: .3s
}

#twitter-feed-list .tweet,
#twitter-feed-slider .tweet {
    font-size: 18px;
    margin: 10px 0
}

#twitter-feed-list .timePosted,
#twitter-feed-slider .timePosted {
    font-family: var(--font-family);
    font-weight: 400;
    text-transform: uppercase;
    font-size: 9px;
    letter-spacing: 1px;
    padding-top: 10px
}

#twitter-feed-slider .timePosted a {
    color: #bdbdbd
}

#twitter-feed-slider .interact a {
    margin: 0 5px;
    font-size: 14px
}

#twitter-feed-list .interact a:first-child:before,
#twitter-feed-list .interact a:nth-child(2):before,
#twitter-feed-list .interact a:nth-child(3):before,
#twitter-feed-slider .interact a:first-child:before,
#twitter-feed-slider .interact a:nth-child(2):before,
#twitter-feed-slider .interact a:nth-child(3):before {
    display: inline-block;
    font-family: Ionicons;
    color: #444;
    margin: 0 10px
}

#twitter-feed-list .interact a:first-child:before,
#twitter-feed-slider .interact a:first-child:before {
    content: "\f21e"
}

#twitter-feed-list .interact a:nth-child(2):before,
#twitter-feed-slider .interact a:nth-child(2):before {
    content: "\f201"
}

#twitter-feed-list .interact a:nth-child(3):before,
#twitter-feed-slider .interact a:nth-child(3):before {
    content: "\f141"
}

.icons-pre {
    margin: auto;
    width: 300px;
    text-align: center
}

.icons-pre pre {
    border: none;
    border-radius: 0;
    color: #888;
    background-color: #fff
}

.ionicons-logo {
    font-weight: 200;
    font-size: 40px
}

.etline-logo {
    font-weight: 200;
    font-size: 36px
}

.show-grid [class^=col-] {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgba(0, 0, 0, .05);
    border: 1px solid rgba(0, 0, 0, .09);
    margin-top: 7px;
    margin-bottom: 7px
}

.lh2 {
    line-height: 3
}

#grid,
.pb0 {
    padding-bottom: 0
}

.subscribe-top {
    display: table-cell;
    width: 500px
}

.top-subscribe-input {
    display: table-cell;
    margin: 0;
    background: #fff;
    padding: 18px 30px;
    border-radius: 0;
    color: #444;
    font-size: 13px;
    width: 100%;
    border: none;
    height: auto
}

.subscription .form-validation {
    position: absolute;
    padding: 8px 13px;
    border-radius: 3px;
    top: 63px;
    z-index: 99
}

#footer-1 .subscription .form-validation.alert-success {
    top: -10px;
    width: 63%;
    text-align: center
}

.subscription .alert-danger {
    font-size: 13px;
    color: #ccc;
    background-color: #c0392b;
    border-color: #c0392b
}

.subscription .alert-danger:after {
    border-color: #c0392b transparent
}

.subscription .alert-success {
    font-size: 13px;
    color: #fff;
    background-color: #82b541;
    border-color: #82b541
}

.subscription .alert-success:after {
    border-color: #82b541 transparent
}

.subscription input {
    background-color: #f5f5f5;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px
}

.subscription input::-webkit-input-placeholder {
    font-size: 12px;
    color: #777;
    width: 220px;
    font-family: var(--font-family);
    font-weight: 300;
    letter-spacing: 0
}

.sign-btn .btn {
    margin-top: 0;
    margin-left: 0 !important
}

.sign-btn .btn-primary {
    padding: 0 30px;
    border-radius: 0 100px 100px 0;
    line-height: 4.6;
    top: 2px;
    box-shadow: none;
    letter-spacing: 1px;
    font-size: 12px;
    border: none !important
}

.subscribe-info {
    font-size: 11px;
    color: #999
}

ul {
    padding: 0;
    display: block
}

ol li {
    line-height: 32px;
    font-weight: 300
}

.list-icons i,
.mr10 {
    margin-right: 10px
}

.pt0 {
    padding-top: 0
}

.btn-demo span,
.pt10 {
    padding-top: 10px
}

.pt20 {
    padding-top: 20px
}

.pt30 {
    padding-top: 30px
}

.pt50 {
    padding-top: 50px
}

.pt60 {
    padding-top: 60px
}

.pt70 {
    padding-top: 70px
}

.pt80 {
    padding-top: 80px
}

.pt90 {
    padding-top: 90px
}

.pt100 {
    padding-top: 100px
}

.pt110 {
    padding-top: 110px
}

.pt120 {
    padding-top: 120px
}

.pt130 {
    padding-top: 130px
}

.pt140 {
    padding-top: 140px
}

.pb10 {
    padding-bottom: 10px
}

.pb15 {
    padding-bottom: 15px
}

.pb20 {
    padding-bottom: 20px
}

.pb30 {
    padding-bottom: 30px
}

.pb40 {
    padding-bottom: 40px
}

.pb50 {
    padding-bottom: 50px
}

.pb60 {
    padding-bottom: 60px
}

.pb70 {
    padding-bottom: 70px
}

.pb80 {
    padding-bottom: 80px
}

.pb90 {
    padding-bottom: 90px
}

.pb100 {
    padding-bottom: 100px
}

.pb110 {
    padding-bottom: 110px
}

.pb120 {
    padding-bottom: 120px
}

.pb130 {
    padding-bottom: 130px
}

.pb140 {
    padding-bottom: 140px
}

.pl0 {
    padding-left: 0
}

.pl3 {
    padding-left: 3px
}

.pl20 {
    padding-left: 20px
}

.pl50 {
    padding-left: 50px
}

.pl60 {
    padding-left: 60px
}

.pl70 {
    padding-left: 70px
}

.pl80 {
    padding-left: 80px
}

.pl100 {
    padding-left: 100px
}

.pl110 {
    padding-left: 110px
}

.pl120 {
    padding-left: 120px
}

.pl130 {
    padding-left: 130px
}

.pl140 {
    padding-left: 140px
}

.pr0 {
    padding-right: 0
}

.pr5 {
    padding-right: 5px
}

.pr20 {
    padding-right: 20px
}

.pr30 {
    padding-right: 30px
}

.pr40 {
    padding-right: 40px
}

.pr50 {
    padding-right: 50px
}

.pr60 {
    padding-right: 60px
}

.pr70 {
    padding-right: 70px
}

.pr80 {
    padding-right: 80px
}

.pr90 {
    padding-right: 90px
}

.pr100 {
    padding-right: 100px
}

.pr110 {
    padding-right: 110px
}

.pr120 {
    padding-right: 120px
}

.pr130 {
    padding-right: 130px
}

.pr140 {
    padding-right: 140px
}

.p10 {
    padding: 10px
}

.p20 {
    padding: 20px
}

.p30 {
    padding: 30px
}

.p40 {
    padding: 40px
}

.p50 {
    padding: 50px
}

.p60 {
    padding: 60px
}

.p70 {
    padding: 70px
}

.p80 {
    padding: 80px
}

.p90 {
    padding: 90px
}

.p100 {
    padding: 100px
}

.p110 {
    padding: 110px
}

.p120 {
    padding: 120px
}

.mr-auto {
    margin: auto;
    float: none
}

.mt20 {
    margin-top: 20px
}

.mt40 {
    margin-top: 40px
}

.mt50 {
    margin-top: 50px
}

.mt60 {
    margin-top: 60px
}

.mt70 {
    margin-top: 70px
}

.mt80 {
    margin-top: 80px
}

.mt90 {
    margin-top: 90px
}

.mt100 {
    margin-top: 100px
}

.mt110 {
    margin-top: 110px
}

.mt120 {
    margin-top: 120px
}

.mt130 {
    margin-top: 130px
}

.mt140 {
    margin-top: 140px
}

.mb30 {
    margin-bottom: 30px
}

.mb40 {
    margin-bottom: 40px
}

.mb60 {
    margin-bottom: 60px
}

.mb70 {
    margin-bottom: 70px
}

.mb90 {
    margin-bottom: 90px
}

.mb100 {
    margin-bottom: 100px
}

.mb110 {
    margin-bottom: 110px
}

.mb120 {
    margin-bottom: 120px
}

.mb130 {
    margin-bottom: 130px
}

.mb140 {
    margin-bottom: 140px
}

.ml20 {
    margin-left: 20px
}

.ml30 {
    margin-left: 30px
}

.ml50 {
    margin-left: 50px
}

.ml60 {
    margin-left: 60px
}

.ml70 {
    margin-left: 70px
}

.ml80 {
    margin-left: 80px
}

.ml90 {
    margin-left: 90px
}

.ml100 {
    margin-left: 100px
}

.ml110 {
    margin-left: 110px
}

.ml120 {
    margin-left: 120px
}

.ml130 {
    margin-left: 130px
}

.ml140 {
    margin-left: 140px
}

.mr20 {
    margin-right: 20px
}

.mr30 {
    margin-right: 30px
}

.mr50 {
    margin-right: 50px
}

.mr60 {
    margin-right: 60px
}

.mr70 {
    margin-right: 70px
}

.mr80 {
    margin-right: 80px
}

.mr90 {
    margin-right: 90px
}

.mr100 {
    margin-right: 100px
}

.mr110 {
    margin-right: 110px
}

.mr120 {
    margin-right: 120px
}

.mr130 {
    margin-right: 130px
}

.mr140 {
    margin-right: 140px
}

#wunderkind-demo[data-overlay-dark]:before {
    background: #19191b
}

#wunderkind-demo .container {
    padding: 0 70px
}

#wunderkind-demo .container .row {
    padding: 60px 0
}

.hero-logo {
    max-width: 560px;
    width: 60%;
    margin: 40px auto 0
}

@media (max-width:992px) {
    .feature-left-stack {
        text-align: center
    }

    .blog {
        padding-top: 0
    }

    #wunderkind-demo .container .row {
        padding: 0
    }

    .hero-logo {
        margin-top: 0
    }
}

.demo-picks>div {
    display: inline-block;
    -webkit-transition: transform .3s, opacity .3s;
    -moz-transition: transform .3s, opacity .3s;
    transition: transform .3s, opacity .3s;
    -webkit-transform: perspective(600px);
    -moz-transform: perspective(600px);
    transform: perspective(600px);
    cursor: pointer;
    z-index: 9
}

.demo-picks>div img {
    max-width: 100%;
    margin: 0 auto
}

.demo-picks {
    z-index: 9999
}

.demo-picks a {
    height: 100%;
    width: 100%
}

.demo-picks a:hover {
    cursor: pointer;
    z-index: 9999
}

.demo-picks>div:hover {
    transform: translateY(-14px);
    opacity: .85;
    cursor: pointer;
    z-index: 9999
}

.btn-demo {
    position: fixed;
    bottom: 30px;
    right: 40px;
    z-index: 9;
    box-shadow: 20px 20px 40px 0 rgba(0, 0, 0, .4)
}

.btn-demo span {
    position: absolute;
    top: -14px;
    right: -14px;
    background-color: rgba(255, 255, 255, .7);
    border-radius: 100px;
    width: 35px;
    height: 35px;
    line-height: 15px;
    color: #111
}

.col-lg-15,
.col-md-15,
.col-sm-15,
.col-xs-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px
}

.col-xs-15 {
    width: 20%;
    float: left
}

@media (min-width:768px) {
    .col-sm-15 {
        width: 20%;
        float: left
    }
}

@media (min-width:992px) {
    .col-md-15 {
        width: 20%;
        float: left
    }
}

.fixing-pixel,
.image-about {
    object-fit: cover;
    object-position: 50% 50%
}


.text-white {
    color: var(--main-white-color)
}



.carousel .thumb {
    border: none !important
}

.carousel .thumb.selected,
.carousel .thumb:hover {
    border: 2px solid #64ffda !important
}

.text-highlight-detail {
    font-size: 15px
}

.text-title {
    color: var(--main-title-color)
}

.footer-links a,
.text-desc,
.text-error-desc,
.text-footer-about {
    color: var(--main-desc-color)
}



code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace
}

.font-29 {
    font-size: 29px;
    font-weight: 700;
    margin-bottom: 20px
}

.res-cdg {
    font-size: 15px !important
}

.rest-skc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 270px;
    margin: 20px
}

.rest-skl,
.site-footer hr.small {
    margin: 20px 0
}

.rest-skl {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0
}

.centered-cd {
    display: flex;
    margin-top: 70px;
    justify-content: center;
    align-items: center;
    height: 100%
}

.logo-size-navbar {
    width: 75px;
    margin-top: 14px
}

.hovered-rotate {
    -webkit-transition: .4s ease-out;
    -moz-transition: .4s ease-out;
    transition: .4s ease-out
}

.hovered-rotate:hover,
.re-layout:hover .hovered-rotate {
    -webkit-transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
    transform: rotateZ(360deg)
}

.text-real-white {
    color: #fff;
    font-weight: 400
}

.centered-cs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%
}

.App {
    text-align: center
}

.repo-content__data,
.repo-content__data_1list,
.repo-content__title,
.title_information_grey {
    text-align: left
}

.App-logo {
    height: 40vmin;
    pointer-events: none
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #fff
}

.centered-about,
.centered-image-about {
    margin: 60px 0;
    justify-content: center;
    display: flex
}

.App-link {
    color: #61dafb
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0)
    }

    to {
        transform: rotate(360deg)
    }
}

.text-white-real,
[data-overlay-color] #mute-toggle,
[data-overlay-color] #play-toggle,
[data-overlay-dark] #mute-toggle,
[data-overlay-dark] #play-toggle {
    color: #fff
}

.text-white {
    color: #34eea0 !important
}

.centered-about {
    flex-direction: column;
    height: 100% !important
}

.image-about {
    border-radius: 10px;
    width: 300px;
    height: 300px;
    box-shadow: rgba(100, 100, 111, .2) 0 7px 29px 0
}

::-moz-selection {
    color: #ccd6f6;
    background: #233554
}

::selection {
    color: #ccd6f6;
    background: #233554
}

.centered-image-about {
    align-items: center;
    height: 100%
}

.bg-card-ability {
    background: #171618;
    padding: 20px 50px;
    border-radius: 10px
}

.repo-item::after,
.repo-item:first-of-type::after {
    background-color: var(--main-bg-color);
    position: absolute;
    width: 2px;
    content: ''
}

.repo-item__circle,
.repo-item__circle_1list {
    z-index: 1;
    align-self: center;
    border-radius: 50%
}

.bg-card-ability h4 {
    color: #fff;
    font-weight: 700;
    font-size: 29px;
    margin-top: 20px;
    margin-bottom: 20px
}

.line-height-desc {
    line-height: 1.5 !important
}

.title-framework {
    font-size: 20px;
    font-weight: 700;
    margin-top: 30px
}

.centered-framework {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%
}

.sub-title-main {
    font-weight: 700 !important
}

.container-main-tech-stack {
    padding: 10px
}

.cursor-normal {
    cursor: default !important
}

.logo-light {
    margin-top: 22px
}

.logo-dark {
    margin-top: 19px
}

.desc-connect {
    padding-top: 20px
}

.mt-cs {
    padding-top: 200px
}

.mb-wd {
    margin-bottom: 20px
}

.mt-testimonial {
    margin-bottom: 120px
}

.amc {
    margin-top: 100px !important
}

.opacity-open,
.vossen-portfolio>div {
    opacity: 1
}

.carousel .thumb {
    border-radius: 6px
}

.repo-list {
    flex-direction: column;
    list-style-type: none;
    padding: 0
}

.repo-item,
.repo-item-solo,
.repo-item_1list {
    position: relative;
    display: flex;
    width: 80%;
    padding: 10px
}

.modal-body,
.modal-footer,
.modal-header {
    padding: 2px 16px
}

.repo-item::after {
    top: 0;
    left: 19px;
    height: 100%
}

.repo-item:first-of-type::after {
    top: 50%;
    left: 17px;
    height: 50%
}

.repo-item:last-of-type::after {
    position: absolute;
    content: '';
    top: 0;
    left: 17px;
    width: 2px;
    height: 50%;
    background-color: var(--main-bg-color)
}

.repo-item_1list::after,
.repo-item_1list:first-of-type::after,
.repo-item_1list:last-of-type::after {
    left: 15.5px;
    width: 4px;
    background-color: var(--main-bg-color)
}

.repo-item__circle {
    height: 15px;
    width: 15px;
    background-color: var(--main-bg-color)
}

.repo-content,
.repo-content_1list {
    margin-left: 10px
}

.repo-content__title {
    white-space: nowrap;
    font-weight: 700
}

.container-career {
    margin-left: 200px;
    overflow: hidden
}

.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, .4);
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: .4s;
    animation-name: fadeIn;
    animation-duration: .4s
}

.modal-content {
    bottom: 0;
    background-color: #fefefe;
    width: 20%;
    margin: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: .4s;
    animation-name: slideIn;
    animation-duration: .4s
}

.close {
    color: #fff;
    float: right;
    font-size: 28px;
    font-weight: 700
}

.close:focus,
.close:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer
}

.modal-header {
    background-color: #202020;
    color: #fff
}

.modal-footer {
    background-color: #252525;
    color: #fff
}

@-webkit-keyframes slideIn {
    from {
        bottom: -300px;
        opacity: 0
    }

    to {
        bottom: 0;
        opacity: 1
    }
}

@keyframes slideIn {
    from {
        bottom: -300px;
        opacity: 0
    }

    to {
        bottom: 0;
        opacity: 1
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.phone-number-header {
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: 700
}

.repo-list_1list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    list-style-type: none;
    padding: 0
}

.repo-item_1list::after {
    position: absolute;
    content: '';
    top: 0;
    height: 100%
}

.repo-item_1list:first-of-type::after {
    position: absolute;
    content: '';
    top: 50%;
    height: 50%
}

.repo-item_1list:last-of-type::after {
    position: absolute;
    content: '';
    top: 0;
    height: 50%
}

.repo-item__circle_1list {
    height: 20px;
    width: 20px;
    background-color: var(--main-bg-color)
}

.repo-content__title_1list {
    white-space: nowrap;
    text-align: left
}

.title_information_grey {
    color: #828282
}

.input-file {
    width: 100% !important;
    height: .1px;
    padding: 5px 20px !important;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1
}

.input-file+.js-labelFile {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 10px;
    cursor: pointer
}

.input-file+.js-labelFile .icon:before {
    content: '\f093'
}

.input-file+.js-labelFile.has-file .icon:before {
    content: '\f00c';
    color: #5aac7b
}

.btn-tertiary {
    color: #555;
    padding: 0;
    line-height: 40px;
    width: 100%;
    margin: auto auto 15px;
    display: block;
    border: 2px solid #555
}

.btn-tertiary:focus,
.btn-tertiary:hover {
    color: #888;
    border-color: #888
}

.iconAbout {
    width: 50px;
    height: 50px
}

.team-image img {
    width: 100%
}

.border {
    border-bottom: 7px solid #828282;
    margin-top: 50px;
    border-radius: 20px
}

[data-overlay-color='8']:before,
[data-overlay-dark='8']:before,
[data-overlay-light='8']:before {
    opacity: 1 !important
}

.filter-item {
    margin-right: .5rem
}

.footer.style-1 {
    padding: 40px 0 !important
}

.costum-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%
}

.res-foot {
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 5px
}

.desc-portfolio {
    font-weight: 400
}

.font-bold,
.site-footer h6 {
    font-weight: 700
}

.image-tech-footer,
.mbb-2 {
    margin-bottom: 10px
}

.linked-skills:hover .title-framework {
    color: #34eea0;
    transition: color .5s
}

.re-layout {
    width: 100px !important
}

.site-footer {
    padding: 45px 0 20px;
    font-size: 15px;
    line-height: 24px;
    color: #737373;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .5)
}

.site-footer hr {
    border-top-color: #bbb;
    opacity: .5
}

.footer-links li {
    margin-bottom: 5px;
    display: block
}

.icon-footer {
    margin-right: 5px;
    padding-top: 4px
}

.site-footer a {
    color: #737373
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover,
.site-footer a:hover {
    color: #fff;
    text-decoration: none
}

.footer-links {
    padding-left: 0;
    list-style: none
}

.text-desc-main-align {
    text-align: left !important
}

.site-footer .social-icons {
    text-align: right
}

.site-footer .social-icons a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-left: 6px;
    margin-right: 0;
    border-radius: 100%;
    background-color: #33353d
}

.copyright-text {
    margin: 0
}

.footer-container .social-icons {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.footer-container .social-icons li {
    display: inline-block;
    margin-bottom: 4px
}

.footer-container .social-icons a {
    background-color: #eceeef;
    color: #818a91;
    font-size: 16px;
    display: inline-block;
    line-height: 44px;
    width: 44px;
    height: 44px;
    text-align: center;
    margin-right: 8px;
    border-radius: 100%;
    -webkit-transition: .2s linear;
    -o-transition: .2s linear;
    transition: .2s linear
}

.logo {
    color: #eb6857
}

.footer-container .social-icons a:active,
.footer-container .social-icons a:focus,
.footer-container .social-icons a:hover {
    color: #fff;
    background-color: #29aafe
}

.footer-container .social-icons a.facebook:hover {
    background-color: #3b5998
}

.footer-container .social-icons a.twitter:hover {
    background-color: #00aced
}

.footer-container .social-icons a.linkedin:hover {
    background-color: #007bb6
}

.footer-container .social-icons a.dribbble:hover {
    background-color: #ea4c89
}


.image-tech-footer img {
    margin: 10px
}


.digitalocean {
    margin-bottom: 5px;
    width: 100px
}

.title-about {
    font-weight: 700;
    color: #fff
}

.flex-order-mobile {
    display: flex
}

.icon-container-about {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%
}

.desc-connect {
    font-size: 12px
}

.connected-icons {
    width: auto;
    margin-top: 0
}

.li-connect-left {
    width: 120px;
    padding-left: 0 !important;
    padding-right: 60px !important
}



.nopadding {
    padding: 0 !important
}


.btn-about-space {
    margin-right: 15px
}

.button-main-about {
    margin-top: 40px
}

.social-bottom-icon {
    z-index: 10000;
    position: fixed;
    bottom: 0;
    margin-left: 40px;
    margin-bottom: 15px;
    width: 100%
}

.social-bottom-icon a {
    padding: 8px;
    margin-bottom: 20px;
    line-height: 10px;
    opacity: .3;
    left: 36px;
    font-size: 20px
}

.theme-dark .social-bottom-icon a:hover {
    color: var(--main-highlight-color);
    opacity: 1
}

.theme-light .social-bottom-icon a:hover {
    color: var(--light-main-highlight-color);
    opacity: 1
}

[data-overlay-light] #mute-toggle,
[data-overlay-light] #play-toggle {
    color: #111
}

.card-body {
    padding: 30px
}

.container-center-error {
    display: flex;
    background: #121010;
    width: auto;
    border-radius: 10px;
    flex-grow: 1;
    margin: 0 200px;
    padding: 50px !important;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%
}

.text-desc-info,
.text-project-info {
    color: var(--main-desc-color);
    text-align: left !important
}

.text-error {
    padding-bottom: 0;
    margin-bottom: 0;
    color: var(--error-title-color)
}

.fixing-pixel {
    width: 100%;
    height: 550px
}

.image-main-portfolio {
    width: 100%;
    height: 262px
}

.wh-100 {
    width: 100%;
    height: 100%;
}

.mt-50s {
    margin-top: 50px;
}

@media only screen and (max-width:1000px) {
    .title-about {
        text-align: center !important;
        margin-bottom: 0
    }

    .container-center-error {
        margin: 0 50px;
        padding: 50px !important
    }

    .logo-size-navbar {
        width: 65px;
        margin-top: 18px
    }

    .footer-space-about {
        padding-right: 10px
    }

    .desc-about {
        text-align: center
    }

    .centered-about {
        padding: 0 70px;
        margin-top: 0;
        margin-bottom: 0
    }

    .container-order-about-mobile {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        height: 100%
    }

    .centered-image-about {
        margin-top: 0;
        margin-bottom: 20px
    }

    .icon-container-about {
        overflow-x: scroll
    }

    .connected-icon {
        width: 100%;
        margin-top: 10px;
        padding: 0 !important
    }

    .desc-connect {
        font-size: 10px
    }

    .main-about-container-res {
        padding: 50px 0
    }
}

@media only screen and (max-width:990px) {
    .logo-size-navbar {
        width: 25px;
        margin-top: 12px
    }
}

@media screen and (max-width:800px) {

    .centered-cd,
    .rest-skc {
        display: flex;
        height: 100%
    }

    .centered-cd {
        margin-top: 70px;
        flex-direction: column !important;
        justify-content: center;
        align-items: center
    }

    .rest-skc {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 20px
    }
}

@media (prefers-reduced-motion:no-preference) {
    .App-logo {
        animation: 20s linear infinite App-logo-spin
    }
}

@media only screen and (max-width:768px) {
    .container-career {
        padding-left: 30px;
        margin-left: 0
    }
}

@media (max-width:992px) {

    .border,
    .video-responsive-yt {
        display: none
    }

    .myName,
    h1 {
        font-size: 30px
    }

    .desc-name {
        font-size: 14px;
        padding: 0 15px
    }

    .my-msg-about {
        font-size: 12px;
        line-height: 2;
        text-align: left !important
    }

    .repo-item_1list {
        width: 100%
    }

    h4 strong {
        color: #000
    }

    .res-ability {
        padding-bottom: 0;
        padding-top: 5px
    }

    .res-quote {
        font-size: 15px !important;
        padding: 0 10px
    }

    .res-foot {
        font-size: 10px
    }

    .res-kick {
        font-size: 20px
    }

    .res-lead-kick {
        font-size: 18px
    }
}

@media (max-width:991px) {
    .site-footer [class^=col-] {
        margin-bottom: 30px
    }
}

@media (max-width:767px) {
    .site-footer {
        padding-bottom: 0
    }

    .site-footer .copyright-text,
    .site-footer .social-icons {
        text-align: center
    }
}

@media only screen and (max-width:430px) {
    .hidden-mobile-fix {
        display: none !important
    }
}

@media only screen and (max-width:770px) {

    .button-main-about,
    .hello-for-responsive,
    .main-title-res {
        text-align: center
    }

    .text-desc {
        text-align: center !important;
        padding: 0 60px;
        line-height: 2 !important
    }

    .hero-fullscreen,
    .mt-cs,
    .mt-res-unique {
        padding-top: 50px
    }

    .image-about {
        margin-top: 60px
    }

    .container-center-error {
        margin: 0;
        padding: 20px !important
    }

    .image-main-portfolio {
        width: 100%;
        height: 100%
    }
}


.checkbox-sc {
    opacity: 0;
    /* position: absolute; */
}

.css-fen_ak4c {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.label {
    background-color: #111;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    height: 26px;
    width: 50px;
    transform: scale(1.5);
}

.label .ball {
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    height: 22px;
    width: 22px;
    transform: translateX(0px);
    transition: transform 0.2s linear;
}

.checkbox-sc:checked+.label .ball {
    transform: translateX(24px);
}

.css-nfe_wsj3-f {
    /* margin-top: 5px; */
    width: 15px;
}

.css-ge_cja {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.css-ng_jsg4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-bottom: 20px;
    padding-right: 80px;
    padding-top: 5px;
}

.css-nfe_wjr1 {
    width: 200px;
    margin-top: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.css-nfe_wjr2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.css-fw_cjw2 {
    padding: 20px;
}

.css-fw_cjw2 li a {
    margin-bottom: 20px;
    padding: 0px 13px;
}

.css-nfe_ajc {
    margin-left: 30px;
}

.cssfe_Ge8d-v {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.footer-space-about {
    padding-right: 100px;
    width: 60%;
}

.css-ehT_g8a {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
}

.css-eVFG_j4ga-c {
    width: 32%;
    margin: 0px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1200px) {
    .css-eVFG_j4ga-c {
        width: 45%;
    }
}

@media only screen and (max-width: 589px) {
    .css-eVFG_j4ga-c {
        width: 100%;
        margin-bottom: 5px;
    }
}

.css-eVFG_j4ga {
    width: 33.33%;
}


@media only screen and (max-width: 1200px) {
    .css-eVFG_j4ga {
        width: 45%;
    }
}

@media only screen and (max-width: 589px) {
    .css-eVFG_j4ga {
        width: 100%;
        margin-bottom: 5px;
    }
}

@media only screen and (max-width: 992px) {
    .cssfe_Ge8d-v {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .footer-space-about {
        width: 100%;
        padding-right: 0px;
    }
}

@media only screen and (max-width: 766px) {
    .cssfe_Ge8d-v {
        margin-left: 30px;
    }

    .css-nf_gi4ga {
        margin-top: 0px !important;
    }
}

@media only screen and (max-width: 335px) {
    .button-main-about {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .btn-about-space {
        margin: 0px;
        margin-bottom: 10px;
        width: 100%;
    }

    .font-29{
        font-size: 25px !important;
    }

    p {
        font-size: 12px !important;
    }

    .text-desc {
        font-size: 12px !important;
    }

    .testimonials {
        margin-top: 0px;
    }
}

.carousel .thumbs-wrapper {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.css-jge_cvjw {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.css-fgE_c {
    width: 60%;
    margin-right: 50px;
}

.css-nf_gi4ga {
    margin-top: 230px !important;
}

@media only screen and (max-width: 994px) {
    .css-nf_gi4ga {
        margin-top: 0px !important;
    }
}
@media only screen and (max-width: 770px) {
    .css-fgE_c {
        width: 100%;
        margin-right: 0px;
    }

    .css-jge_cvjw {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
     }
}
